import axios from "./sac-axios"
import _ from "lodash"

export const UserService = {
    getUserProfile: async () => (await axios.get("profile")).data,

    getDataSharingConsents: _.memoize(async () => (await axios.get("profile/data-sharing")).data),

    resendEmailVerification: (async () => (await axios.post("profile/resend-email-verification")).data),

    getOtherIdentities: _.memoize(async (categories) => (await axios.get("profile/identities" + (categories ? "?category=" + categories.join("&category") : ""))).data),

    unlinkIdentity: (async (identity) => (await axios.post("profile/identities/unlink", identity)).data),

    approveClaim: (async (identity) => (await axios.post("profile/identities/approveClaim", identity)).data),

    rejectClaim: (async (identity) => (await axios.post("profile/identities/rejectClaim", identity)).data),

    requestOrgAccess: (async () => (await axios.post("profile/request-org-access")).data)
};

export const OrganisationService = {
    getOrganisations: (
        async (page, size, sort, asc, filters) =>
            (await axios.get("/organisation?" + toPageableParams(page, size, sort, asc, filters))).data),

    getMyOrganisations: (
        async (page, size, sort, asc, filters) =>
            (await axios.get("/organisation/own?" + toPageableParams(page, size, sort, asc, filters))).data),

    getOrganisation: (async (orgId) => (await axios.get("/organisation/" + orgId)).data),

    checkForDuplicates: (async (orgDetails) => (await axios.post("/organisation/duplicateCheck", orgDetails)).data),

    createOrganisationForMe: (async (organisation) => (await axios.post("/organisation", organisation)).data),
    createStandaloneOrganisation: (async (organisation) => (await axios.post("/organisation/standalone", organisation)).data),
    createOrgAndInvite: (async (parentOrgId, request) => (await axios.post("/organisation/" + parentOrgId + "/createAndInvite", request)).data),

    updateOrganisation: (async (organisation) => (await axios.put("/organisation/" + organisation.id, organisation)).data),

    getOrgFunctions: async () => (await axios.get("/organisation/functions")).data,

    getAvailableUserCapabilities: async (orgId) => (await axios.get("/organisation/" + orgId + "/capabilities")).data,

    createOrganisationRole: (async (orgId, role) => (await axios.post("/organisation/" + orgId + "/role", role)).data),

    updateOrganisationRole: (async (orgId, role) => (await axios.put("/organisation/" + orgId + "/role/" + role.id, role)).data),

    deleteOrganisationRole: (async (orgId, roleId) => (await axios.delete("/organisation/" + orgId + "/role/" + roleId)).data)
};

export const ApplicationService = {
    getApplicationsForOrg: (async (orgId) => (await axios.get("/organisation/" + orgId + "/app")).data),

    checkForDuplicates: (async (orgId, name, appId) => (await axios.get("/organisation/" + orgId + "/app/dupeCheck?name=" + name + (appId ? "&appId=" + appId : ""))).data),

    getApplication: (async (orgId, appId) => (await axios.get("/organisation/" + orgId + "/app/" + appId)).data),

    createApplication: (async (orgId, app) => (await axios.post("/organisation/" + orgId + "/app", app)).data),

    updateApplication: (async (orgId, appId, app) => (await axios.put("/organisation/" + orgId + "/app/" + appId, app)).data),

    deleteApplication: (async (orgId, appId) => (await axios.delete("/organisation/" + orgId + "/app/" + appId)).data)
};

export const OAuthClientService = {
    getClientsForApplication: (async (orgId, appId) => (await axios.get("/organisation/" + orgId + "/app/" + appId + "/client")).data),

    getAvailableOAuthProviders: (async (orgId) => (await axios.get("/organisation/" + orgId + "/availableOAuthProviders")).data),

    createClient: (async (orgId, appId, client) => (await axios.post("/organisation/" + orgId + "/app/" + appId + "/client", client)).data),

    updateClient: (async (orgId, appId, client) => (await axios.put("/organisation/" + orgId + "/app/" + appId + "/client/" + client.id, client)).data),

    getOAuthClient: (async (orgId, appId, clientId) => (await axios.get("/organisation/" + orgId + "/app/" + appId + "/client/" + clientId)).data),

    viewOAuthClientCred: (async (orgId, appId, clientId) => (await axios.get("/organisation/" + orgId + "/app/" + appId + "/client/" + clientId + "/cred")).data),

    regenOAuthClientCred: (async (orgId, appId, clientId) => (await axios.post("/organisation/" + orgId + "/app/" + appId + "/client/" + clientId + "/cred")).data),

    deleteOAuthClient: (async (orgId, appId, clientId) => (await axios.delete("/organisation/" + orgId + "/app/" + appId + "/client/" + clientId)).data)
};

export const WebhookService = {
    getWebhooksForApplication: (async (orgId, appId) => (await axios.get("/organisation/" + orgId + "/app/" + appId + "/webhook")).data),

    getEvents: (async (orgId) => (await axios.get("/organisation/" + orgId + "/app/webhook/events")).data),

    deleteWebhook: (async (orgId, appId, identifier) => (await axios.delete("/organisation/" + orgId + "/app/" + appId + "/webhook/" + identifier)).data),

    createOrUpdateWebhook: (async (orgId, appId, webhook) => (await axios.post("/organisation/" + orgId + "/app/" + appId + "/webhook", webhook)).data)
};

export const OAuthAdminService = {
    getRealms: (async () => (await axios.get("/admin/oauth")).data),

    getRealm: (async (id) => (await axios.get("/admin/oauth/" + id)).data),

    createRealm: (async (realm) => (await axios.post("/admin/oauth", realm)).data),

    deleteRealm: (async (id) => (await axios.delete("/admin/oauth/" + id)).data)
};

export const AffiliationsService = {
    getAffiliations: (async (orgId, page, size, sort, asc, filters) =>
        (await axios.get("/organisation/" + orgId + "/affiliations?" + toPageableParams(page, size, sort, asc, filters))).data),

    getAllAffiliatedOrgIds: (async (orgId) => (await axios.get("/organisation/" + orgId + "/affiliationIds")).data),

    findAffiliationCandidates: (async (orgId, name) => (await axios.get("/organisation/" + orgId + "/affiliations/candidates?name=" + name)).data),

    getAffiliation: (async (orgId, affiliationId) => (await axios.get("/organisation/" + orgId + "/affiliations/" + affiliationId)).data),

    accept: (async (orgId, affiliationId) => (await axios.post("/organisation/" + orgId + "/affiliations/" + affiliationId + "/accept")).data),

    decline: (async (orgId, affiliationId) => (await axios.post("/organisation/" + orgId + "/affiliations/" + affiliationId + "/decline")).data),

    revoke: (async (orgId, affiliationId) => (await axios.post("/organisation/" + orgId + "/affiliations/" + affiliationId + "/revoke")).data),

    withdraw: (async (orgId, affiliationId) => (await axios.post("/organisation/" + orgId + "/affiliations/" + affiliationId + "/withdraw")).data),

    getAvailableTypes: (async (orgId) => (await axios.get("/organisation/" + orgId + "/affiliations/types")).data),

    request: (async (orgId, affiliation) => (await axios.post("/organisation/" + orgId + "/affiliations", affiliation)).data),

    getPendingAffiliations: (async (orgId) => (await axios.get("/organisation/" + orgId + "/affiliations/pending")).data),

    getSuggestedAffiliations: (async (orgId) => (await axios.get("/organisation/" + orgId + "/affiliations/suggested")).data)
};

export const ApplicationGroupService = {
    getGroupsForApplications: (async (orgId, appId) => (await axios.get("/organisation/" + orgId + "/groups/app/" + appId)).data)
};

export const GroupInvitationService = {
    checkForDuplicates: _.memoize(async (orgId, email) => (await axios.get(`/organisation/${orgId}/invitation/find?email=${email}`)).data
      , (...args) => createMemoizeCache(args)),

    listMembers: (async (orgId, appId) => (await axios.get("/organisation/" + orgId + "/app/" + appId + "/members")).data),

    sendInvitation: (async (memberDto, orgId, groupId, appId) => (await axios.post("/organisation/" + orgId + "/group/" + groupId + "/app/" + appId + "/member", memberDto)).data),

    removeMembership: (async (orgId, groupId, appId, memberId) => (await axios.delete("/organisation/" + orgId + "/group/" + groupId + "/app/" + appId + "/member/" + memberId)).data)
};

export const RoleInvitationService = {
    checkForDuplicates: _.memoize(async (orgId, email) => (await axios.get(`/organisation/${orgId}/invitation/find?email=${email}`)).data
        , (...args) => createMemoizeCache(args)),

    sendInvitation: (async (invitation, orgId) => (await axios.post("/organisation/" + orgId + "/invitation", invitation)).data),

    cancelInvitation: (async (orgId, invitationId) => (await axios.delete("/organisation/" + orgId + "/invitation/" + invitationId)).data),

    acceptInvitation: (async (token) => (await axios.post("/organisation/invitation/" + token + "/accept")).data)
};

export const OrganisationPersonService = {
    getOrganisationPerson: (async (orgId, personId) => (await axios.get(`/organisation/${orgId}/person/${personId}`)).data),
    updateOrganisationPerson: (async (orgId, personId, person) => (await axios.put("/organisation/" + orgId + "/person/" + personId, person)).data)
};

export const SportService = {
    getSports: _.memoize(async () => (await axios.get("/sport")).data),

    createSport: (async (sport) => (await axios.post("/sport", sport)).data),

    updateSport: (async (id, sport) => (await axios.put("/sport/" + id, sport)).data),

    deleteSport: (async (sport) => (await axios.delete("/sport/" + sport.id)).data)
};

export const EnumService = {
    getCountries: _.memoize(async () => (await axios.get("/enumeration/countries")).data)
};

export const AuthService = {
    createRealm: (async (realm) => (await axios.post("/auth/realm")).data)
};


export const IdentityService = {
    getDocuments: ( async () => (await axios.get("/identityverification/document")).data),
    getDocument: (async (documentId) => (await axios.get(`/identityverification/document/${documentId}`)).data),
    validateDocument: ( async (documentType, document) => (await axios.post(`identityverification/document/${documentType}`, document)).data),
    getIdentity: (async (documentId) => (await axios.get(`/identityverification/identity`)).data)
};

const createMemoizeCache = (args) => {
    return args.map(a => _.isObject(a) ? _.toPairs(a).join() : a).join();
};

const toPageableParams = (page, size, sort, asc, filters) => {
    return "page=" + page
        + "&size=" + size
        + (sort ? "&sort=" + sort + (asc ? "" : ",desc") : "")
        + (filters && !_.eq(filters, {})
            ? "&" + _.map(_.toPairs(filters), a => a.join("=")).join("&")
            : "");
};