import React, {useContext} from "react"
import PropTypes from "prop-types"
import DataTable from "@sportaus-digital/data-table";
import Spinner from "@sportaus-digital/spinner";
import "../organisation.scss"
import ConfirmBtn from "@sportaus-digital/confirm-btn";
import {formatLocalISODate} from "../../../../../utils/date-utils";
import {GroupInvitationService} from "../../../../../service/service";
import {PageAlertActionsContext} from "../../../../../utils/page-alerts";
import {Actions, Protected} from "../../../../../security/authorisation";

const GroupsPersons = ({orgId, appId, peopleAndInvitations, removedHandler}) => {
    const setPageAlert = useContext(PageAlertActionsContext);

    const cancelInvitationHandler = (row) => {
        GroupInvitationService.removeMembership(orgId, row.groupId, appId, row.id)
          .then(() => {removedHandler(row.id);})
          .catch(() => {setPageAlert({severity: "error", message: "An unexpected error occurred while cancelling the invitation"});});
    };

    const headers = [
        {
            title: "Name", key: "email", width: "35", filterable: true, sortable: true,
            render: (name, row) => {
                if (row.lastUsedDate) {
                    return <>{row.email}</>;
                } else {
                    return <>{row.email}<span className="invitation-indicator"> (Invited)</span></>;
                }
            }
        },
        {
            title: "Group",
            width: "20",
            filterable: false,
            sortable: false,
            render: (colData, row) => (row.groupName)
        },
        {
            title: "Last Active",
            width: "15",
            filterable: false,
            sortable: false,
            render: (colData, row) => (row.lastUsedDate ? formatLocalISODate(row.lastUsedDate) : "-")
        },
        {
            title: "Invited By",
            width: "15",
            filterable: false,
            sortable: false,
            render: (colData, row) => (row.invitedBy)
        },
        {
            title: "Action",
            width: "15",
            render: (colData, row) => (
                <Protected orgId={orgId}
                           appId={appId}
                           action={[Actions.GROUP_MANAGEMENT_ORG, Actions.GROUP_MANAGEMENT_APP]}>
                    <ConfirmBtn onClick={() => cancelInvitationHandler(row)} as="tertiary"
                                message={"Are you sure you wish to remove '" + row.email + "' from the '" + row.groupName + "' group?"}>
                        Cancel
                    </ConfirmBtn></Protected>)
        }
    ];

    return <div>
        <DataTable headers={headers}
                   spinner={<Spinner hideText={true} inline={true}/>}
                   data={peopleAndInvitations || []}
                   defaultSort="name"
                   pageSize={20}/>
    </div>
};

GroupsPersons.propTypes = {
    orgId: PropTypes.number.isRequired,
    peopleAndInvitations: PropTypes.array.isRequired
};

export default GroupsPersons;