import React from "react";
import { useField } from "formik";

const MedicareCardColour = (props) => {
  const {name, onStateChange} = props;
  const helpers = useField(props)[2];

  const handleChange = (event) => {
    const { value } = event.target;
    helpers.setValue(value);
    if (onStateChange) {
      onStateChange(value);
    }
  };

  return (
    <div className="form-group">
      <strong>Card Color</strong>
      <div className="form-check">
        <div className="form-row">
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name={name}
              id="cardColourGreen"
              value="G"
              onChange={handleChange}
              defaultChecked
              />
            <label
              className="form-check-label"
              htmlFor="cardColourGreen"
              >
              Green
            </label>
          </div>
          <div className="form-check ml-2">
            <input
              className="form-check-input"
              type="radio"
              name={name}
              id="cardColourYellow"
              value="Y"
              onChange={handleChange}
              />
            <label
              className="form-check-label"
              htmlFor="cardColourYellow"
              >
              Yellow
            </label>
          </div>
          <div className="form-check ml-2">
            <input
              className="form-check-input"
              type="radio"
              name={name}
              id="cardColourBlue"
              value="B"
              onChange={handleChange}
              />
            <label
              className="form-check-label"
              htmlFor="cardColourBlue"
              >
              Blue
            </label>
          </div>
          </div>
      </div>
    </div>
  );
};

export default MedicareCardColour;
