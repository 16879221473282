import moment from "moment-timezone";


export const formatUTCISODateTime = (utc) => {
    return utc ? moment.utc(utc).local().format('DD/MM/YYYY hh:mm a z') : '';
};

export const formatUTCISODate = (utc) => {
    return utc ? moment.utc(utc).local().format('DD/MM/YYYY') : '';
};

export const formatLocalISODate = (date) => {
    return date ? moment(date).format('DD/MM/YYYY') : '';
};