import React from "react";
import { ErrorMessage, Field } from "formik";

const DateOfBirth = (props) => {
  const {errors, touched, required} = props;
  
  return (
    <div className="form-group">
        <label>
            <strong>Date of Birth {required && "*"}</strong>
        </label>
        <div className="form-row justify-content-start">
            <div className="col-xs-4">
            <Field
                name="dobDD"
                type="text"
                as="input"
                placeholder="DD"
                maxLength="2"
                className={
                "form-control" +
                (errors.dobDD && touched.dobDD ? " is-invalid" : "")
                }
            />
            <ErrorMessage
                name="dobDD"
                component="div"
                className="invalid-feedback"
            />
            </div>
            <div className="col-xs-4">
            <Field
                name="dobMM"
                type="text"
                as="input"
                placeholder="MM"
                maxLength="2"
                className={
                "form-control" +
                (errors.dobMM && touched.dobMM ? " is-invalid" : "")
                }
            />
            <ErrorMessage
                name="dobMM"
                component="div"
                className="invalid-feedback"
            />
            </div>
            <div className="col-xs-4">
            <Field
                name="dobYYYY"
                type="text"
                as="input"
                placeholder="YYYY"
                maxLength="4"
                className={
                "form-control" +
                (errors.dobYYYY && touched.dobYYYY ? " is-invalid" : "")
                }
            />
            <ErrorMessage
                name="dobYYYY"
                component="div"
                className="invalid-feedback"
            />
            </div>
        </div>
    </div>
  );
};

export default DateOfBirth;
