import axios from "axios";
import keycloak from "../security/keycloak";

const instance = axios.create({
    baseURL: window.envConfig.portalApiBaseUrl
});

instance.interceptors.request.use(
    config => {
        if (keycloak && keycloak.token) {
            config.headers['Authorization'] = 'Bearer ' + keycloak.token;
        }
        config.headers['Content-Type'] = 'application/json';
        return config;
    },
    error => {
        Promise.reject(error)
    });

export default instance;