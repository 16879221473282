import React, {useCallback, useEffect, useState} from "react"
import {Actions, Protected, ProtectedButton, withAuth} from "../../../../../security/authorisation";
import SDSpanel from "../../../../../components/panel/panel";
import {useHistory} from "react-router";
import {FormBuilder} from "../../../../../components/form/form-builder";
import useErrorHandler from "../../../../../hooks/error-handler";
import {OAuthClientService} from "../../../../../service/service";
import SDSform, {FieldTypes} from "../../../../../components/form/form";
import {SDSsubPageTitle} from "../../../../../components/page-title/page-title";
import ConfirmBtn from "@sportaus-digital/confirm-btn";
import SDSfieldList, {SDSfieldListItem} from "../../../../../components/field-list/field-list";
import {formatUTCISODateTime} from "../../../../../utils/date-utils";
import SDSicon from "../../../../../components/icons/icons";
import {sortBy} from "lodash";
import {Types} from "../../../../../components/form/field";
import {
    AUTH_CODE_FLOW_PUBLIC,
    CLIENT_CREDENTIALS_GRANT,
    OAuthResourceType
} from "../../../../../constants/oauth-resource-types";
import {useEnum} from "../../../../../utils/enums";
import * as Terms from "../../../../../constants/terms"

const UpdateOAuthClient = ({orgId, appId, oauthClientId, oauthClient}) => {
    const [mode, setMode] = useState("view");
    const [client, setClient] = useState({});
    const [clientId, setClientId] = useState({});
    const [loading, setLoading] = useState(true);
    const {errorHandler} = useErrorHandler();
    const router = useHistory();
    const backRoute = `../../${appId}`;
    const getType = useEnum(OAuthResourceType);

    useEffect(() => {
        if (oauthClient) {
            setClient(oauthClient);
            setClientId(oauthClient.id);
            setLoading(false);
        } else {
            setClientId(oauthClientId);
            OAuthClientService.getOAuthClient(orgId, appId, oauthClientId)
                .then(c => {
                    setClient(c);
                    setLoading(false);
                })
                .catch((e) => errorHandler(e, "retrieving the resource details"));
        }
    }, [orgId, appId, oauthClient, oauthClientId, setClient, errorHandler, setLoading]);

    const config = new FormBuilder()
        .field("orgId", "", FieldTypes.HIDDEN).value(orgId)
        .field("id", "", FieldTypes.HIDDEN).value(clientId)

        .field("description", "Description", Types.TEXTAREA)
        .required()
        .value(client.description)
        .max(500)

        .field("baseUrl")
        .value(client.baseUrl)
        .max(1000)
        .required()
        .hidden(() => client.resourceType === CLIENT_CREDENTIALS_GRANT)
        .hint(`What is the base URL for your client? E.g. the base URL for ${Terms.PRODUCT_NAME} Self Service is ` + window.envConfig.baseUrl)

        .field("validRedirects", "Redirect URLs", FieldTypes.MULTI_TEXT)
        .value(client.validRedirects)
        .required("Please provide at least one valid redirect URL")
        .url("Please provide one or more valid URLs")
        .max(1000)
        .hidden(() => client.resourceType === CLIENT_CREDENTIALS_GRANT)
        .hint("This will be used to limit the allowed redirect URLs that can be passed to the login process. As a minimum, this should include the Base URL.")

        .field("allowedOrigins", "Allowed Origins", FieldTypes.MULTI_TEXT)
        .value(client.allowedOrigins)
        .required("Please provide at least one allowed origin")
        .url("Please provide one or more valid URLs")
        .max(1000)
        .hidden(() => client.resourceType !== AUTH_CODE_FLOW_PUBLIC)
        .hint("This will be used to limit the allowed origins for HTTP requests to the public client")

        .build();

    const submitHandler = (formData, actions) => {
        OAuthClientService.updateClient(orgId, appId, formData)
            .then((c) => {
                setClient(c);
                actions.setSubmitting(false);
                setMode("view");
            })
            .catch((e) => {
                actions.setSubmitting(false);
                errorHandler(e, "updating your resource")
            });
    };

    const deleteHandler = () => {
        OAuthClientService.deleteOAuthClient(orgId, appId, clientId)
            .then(() => router.push(backRoute))
            .catch((e) => errorHandler(e, "deleting your resource"));
    };

    const changeMode = () => {
        setMode(mode === "view" ? "edit" : "view")
    };

    const removeAction = <>
        <Protected action={Actions.ORG_APPLICATIONS_DELETE_CLIENT} orgId={orgId}>
            <ConfirmBtn as={"secondary"}
                           disabled={loading}
                           onClick={() => deleteHandler()}>
                Remove resource
            </ConfirmBtn>
        </Protected>
    </>;

    const editAction = mode === "view" ? <div className="text-right m-4">
        <ProtectedButton action={Actions.ORG_APPLICATIONS_UPDATE_CLIENT}
                         orgId={orgId}
                         as={"secondary"}
                         disabled={loading}
                         onClick={changeMode}>
            Update resource
        </ProtectedButton>
    </div> : null;

    return <div className="row request-oauth-client">
        <div className="col-sm-12">
            <SDSsubPageTitle backButtonRoute={backRoute} byLine={client.name}>
                Update a linked client
            </SDSsubPageTitle>
        </div>
        <div className="col-sm-12">
            <SDSpanel title="Resource details" action={editAction}>
                {
                    mode === "view"
                    && !loading
                    && <><SDSfieldList>
                        <SDSfieldListItem label="Type"
                                          value={getType(client.resourceType)}
                                          labelWidth={3}
                                          valueWidth={9}/>
                        <SDSfieldListItem label="Resource"
                                          value={client.clientId}
                                          labelWidth={3}
                                          valueWidth={9}/>
                        <SDSfieldListItem label="Realm"
                                          value={client.realm}
                                          labelWidth={3}
                                          valueWidth={9}/>
                        {client.resourceType !== AUTH_CODE_FLOW_PUBLIC && <SDSfieldListItem label="Secret"
                                                                                            value={<Secret
                                                                                                client={client}
                                                                                                orgId={orgId}
                                                                                                appId={appId}/>}
                                                                                            labelWidth={3}
                                                                                            valueWidth={9}/>}
                        {client.resourceType !== CLIENT_CREDENTIALS_GRANT && client.baseUrl &&
                        <SDSfieldListItem label="Base URL"
                                          value={client.baseUrl}
                                          labelWidth={3}
                                          valueWidth={9}/>}
                        {client.resourceType === AUTH_CODE_FLOW_PUBLIC && <SDSfieldListItem label="Allowed origins"
                                                                                            labelWidth={3}
                                                                                            valueWidth={9}>
                            <ol className="undecorated-list">
                                {sortBy(client.allowedOrigins).map(a => <li key={a}>{a}</li>)}
                            </ol>
                        </SDSfieldListItem>}
                        {client.resourceType !== CLIENT_CREDENTIALS_GRANT && <SDSfieldListItem label="Valid redirects"
                                                                                               labelWidth={3}
                                                                                               valueWidth={9}>
                            <ol className="undecorated-list">
                                {sortBy(client.validRedirects).map(a => <li key={a}>{a}</li>)}
                            </ol>
                        </SDSfieldListItem>}
                        <SDSfieldListItem label="Sport"
                                          value={client.provider.sportName}
                                          labelWidth={3}
                                          valueWidth={9}/>
                        <SDSfieldListItem label="Description"
                                          value={client.description}
                                          labelWidth={3}
                                          valueWidth={9}/>
                        <SDSfieldListItem label="Created"
                                          value={formatUTCISODateTime(client.createdDate)}
                                          labelWidth={3}
                                          valueWidth={9}/>
                        <SDSfieldListItem label="Updated"
                                          value={formatUTCISODateTime(client.lastUpdatedDate)}
                                          labelWidth={3}
                                          valueWidth={9}/>
                    </SDSfieldList>

                        {client.resourceType === CLIENT_CREDENTIALS_GRANT
                        && <>
                            <h3 className="mt-5">API security attributes</h3>
                            <p>Many of the {Terms.PRODUCT_NAME} APIs require the following additional security attributes to
                                be provided with each request</p>
                
                            <SDSfieldList>
                                <SDSfieldListItem label="permissionsContextId"
                                                  value={client.provider.orgExtId}
                                                  labelWidth={3}
                                                  valueWidth={9}/>
                                <SDSfieldListItem label="sportsIx   d"
                                                  value={client.provider.sportExtId}
                                                  labelWidth={3}
                                                  valueWidth={9}/>
                            </SDSfieldList>
                        </>}
                    </>
                }
                {
                    mode === "edit"
                    && !loading
                    && <SDSform config={config}
                                progressIndicator={<span className="fa fa-spinner fa-spin ml-1 mr-1"/>}
                                disableSubmit={loading}
                                submitHandler={submitHandler}
                                cancelHandler={() => setMode("view")}
                                nonFormActions={removeAction}/>
                }
            </SDSpanel>
        </div>
    </div>;
};

const Secret = ({client, appId, orgId}) => {
    const [mode, setMode] = useState("hide");
    const [cred, setCred] = useState("**********************");
    const {errorHandler} = useErrorHandler();

    const viewCred = useCallback(() => {
        if (mode === "hide") {
            OAuthClientService.viewOAuthClientCred(orgId, appId, client.id)
                .then((c) => {
                    setCred(c);
                    setMode("show");
                })
        } else {
            setCred("**********************");
            setMode("hide");
        }
    }, [mode, setMode, setCred, client, orgId, appId]);

    const regenCredentialHandler = () => {
        OAuthClientService.regenOAuthClientCred(orgId, appId, client.id)
            .then(setCred)
            .catch((e) => errorHandler(e, "regenerating your client's secret"));
    };

    return <div>
        <div className="d-inline-block mr-5 mb-2">{cred}</div>

        {mode === "show" && <Protected action={Actions.ORG_APPLICATIONS_REGEN_CLIENT_CRED} orgId={orgId}>
            <ConfirmBtn as={"secondary"}
                           className="ml-5"
                           message="Are you sure you wish to generate a new private credential for this client? This will take effect immediately, so any existing services using the current credentials will stop working."
                           onClick={() => regenCredentialHandler()}>
                Generate a new secret
            </ConfirmBtn>
        </Protected>}

        <ProtectedButton action={Actions.ORG_APPLICATIONS_REGEN_CLIENT_CRED}
                         orgId={orgId}
                         className="my-2 mx-0 p-0 d-block"
                         icon={<SDSicon group="fa" name={mode === "show" ? "eye-slash" : "eye"}/>}
                         as="tertiary"
                         onClick={viewCred}>
            {mode === "hide" ? "View" : "Hide"} secret
        </ProtectedButton>
    </div>;
};

UpdateOAuthClient.propTypes = {};

export default withAuth(UpdateOAuthClient);