import DataTable from "@sportaus-digital/data-table";
import Spinner from "@sportaus-digital/spinner";
import React, { useEffect, useState } from "react";
import { SDSlandingPageTitle } from "../../../components/page-title/page-title";
import { FEDERATED_ID } from "../../../constants/identity-categories";
import * as Terms from "../../../constants/terms";
import useErrorHandler from "../../../hooks/error-handler";
import { Actions, Protected, withAuth } from "../../../security/authorisation";
import { UserService } from "../../../service/service";

const LinkedAccounts = () => {
    const [accounts, setAccounts] = useState([]);
    const { errorHandler } = useErrorHandler();

    useEffect(() => {
        UserService.getOtherIdentities([FEDERATED_ID])
            .then(data => {
                return setAccounts(data);
            })
            .catch((e) => errorHandler(e, "retrieving your identities"))
    }, [setAccounts, errorHandler]);

    /* Linking account feature has not been released to Prod and with KC25, it will unlikely function
        with the /account route disabled for the KC25 release. 
    
        const idps = [
        {
            id: "google",
            idpAlias: "Google",
        },
        {
            id: "sportausconnectidp",
            idpAlias: "Sports Aus Connect",
        },
        {
            id: "apple",
            idpAlias: "Apple",
        },
        {
            id: "microsoft",
            idpAlias: "Microsoft",
        },
        {
            id: "aaf",
            idpAlias: "Australian Access Federation",
        },
        {
            id: "mygovid",
            idpAlias: "Digital Identity",
        },
    ];


    const linkedIdps = accounts.map(account => account.provider.toLowerCase());
    const idpsToDisplay = idps.filter(idp => !linkedIdps.includes(idp.id));

    const handleButtonClick = (id) => {
        const url = window.envConfig.keycloakAuthUrl;
        const clientid = window.envConfig.keycloakClientId;
        const realm = window.envConfig.keycloakRealm;
        const redirectUri = window.envConfig.baseUrl + "/home/account/linkedaccounts";

        let targetUrl = `${url}/realms/${realm}/link-user-api/${clientid}/linkidentity/${id}?redirectUri=${redirectUri}`;
        window.open(targetUrl, '_self')
    };*/
    return <div>
        <div className="row">
            <div className="col-sm-12">
                <SDSlandingPageTitle byLine={`Linked accounts used with ${Terms.PRODUCT_NAME}`}>
                    Linked accounts
                </SDSlandingPageTitle>
            </div>
        </div>
        <div className="row panel-container">
            <Protected action={Actions.USER_PROFILE_LIST_IDENTITIES}>
                <div className="col-xs-12 col-sm-12">
                    <DataTable headers={[
                        { title: "Account Provider", key: "provider", width: "12" },
                        { title: "Email", key: "email", width: "25" },
                        { title: "Status", key: "status", width: "10" },

                    ]}
                        data={accounts}
                        spinner={<Spinner hideText={true} inline={true} />}
                        emptyMessage={<><p>It looks like you’re {Terms.PRODUCT_NAME} isn't linked to a public
                            identity like google or microsoft.</p></>}
                        defaultSort="name"
                        pageSize={20} />
                </div>
            </Protected>
        </div>
        {/* Linking account feature has not been released to Prod and with KC25, it will unlikely function
        with the /account route disabled for the KC25 release. 
        
        <div className="row panel-container">
            <Protected action={Actions.USER_PROFILE_LIST_IDENTITIES}>
                <div className="col-xs-12 col-sm-12">
                    <DataTable headers={[
                        { title: "Account Provider", key: "idpAlias", width: "80" },
                        {
                            title: "Action", key: "", width: "20", render: (data, row) => {
                                return <>
                                    <Button as={"tertiary"} onClick={() => handleButtonClick(row.id)}
                                    >Link Account</Button>
                                </>

                            }
                        },

                    ]}
                        data={idpsToDisplay}
                        spinner={<Spinner hideText={true} inline={true} />}
                        defaultSort="name"
                        pageSize={20} />
                </div>
            </Protected>
        </div> */}

    </div >;
};

LinkedAccounts.propTypes = {};

export default withAuth(LinkedAccounts);