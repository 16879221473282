import React, {useEffect, useState} from "react"
import {UserService} from "../../service/service";
import useErrorHandler from "../../hooks/error-handler";
import SDSpanel from "../../components/panel/panel";
import {Actions, Protected, ProtectedButton} from "../../security/authorisation";
import {FEDERATED_ID} from "../../constants/identity-categories";
import "./identity-card.scss";
import * as Terms from "../../constants/terms"

const FederatedIdentities = () => {
    const [ids, setIds] = useState([]);
    const {errorHandler} = useErrorHandler();

    useEffect(() => {
        UserService.getOtherIdentities([FEDERATED_ID])
            .then(data => setIds(data))
            .catch((e) => errorHandler(e, "retrieving your identities"))
    }, [setIds, errorHandler]);

    return ids && ids.length > 0 ? <Protected action={Actions.USER_PROFILE_LIST_IDENTITIES}>
        <div className="col-xs-12 col-sm-6">
            <SDSpanel title="Linked accounts" action={
                    <ProtectedButton
                        action={Actions.USER_PROFILE_LIST_IDENTITIES}
                        className="sds-btn--block"
                        route={`/home/account/linkedaccounts`}>
                        View linked identities
                    </ProtectedButton>
                }>
                    Linked accounts used with {Terms.PRODUCT_NAME}
            </SDSpanel>
        </div>
    </Protected> : null;
};

export default FederatedIdentities;