import React, {useEffect, useState} from "react"
import {useRouteMatch} from "react-router";
import useErrorHandler from "../../../../../hooks/error-handler";
import {ApplicationService} from "../../../../../service/service";
import {Actions, ProtectedButton} from "../../../../../security/authorisation";
import SDSpanel from "../../../../../components/panel/panel";
import Card from "@sportaus-digital/card";
import SDSfieldList, {SDSfieldListItem} from "../../../../../components/field-list/field-list";
import {formatUTCISODateTime} from "../../../../../utils/date-utils";

const OrgApplications = ({organisation}) => {
    const {url} = useRouteMatch();
    const [apps, setApps] = useState([]);
    const [loading, setLoading] = useState(true);
    const {errorHandler} = useErrorHandler();

    useEffect(() => {
        ApplicationService.getApplicationsForOrg(organisation.id)
            .then(a => setApps(a))
            .catch((e) => errorHandler(e, "retrieving your applications"))
            .finally(() => setLoading(false))
    }, [organisation, setApps, errorHandler, setLoading]);

    return <SDSpanel title="Linked applications"
                     action={<ProtectedButton action={Actions.ORG_APPLICATIONS_CREATE_APP}
                                              orgId={organisation.id}
                                              className="sds-btn--block"
                                              route={`${url}/app/create`}
                                              as="tertiary">Register a new application</ProtectedButton>}>
        <div>
            Manage application keys and resources.

            <div className="row panel-container mt-4">
                {!loading && apps && apps.map(a => <div className="col-lg-4 col-md-6 col-sm-12" key={a.id}>
                    <Card title={a.name} className="oauth-card" actions={[<ProtectedButton action={[Actions.ORG_APPLICATIONS_UPDATE_APP, Actions.GROUP_MANAGEMENT_ORG, Actions.GROUP_MANAGEMENT_APP]}
                                                                                              orgId={organisation.id}
                                                                                              appId={a.id}
                                                                                              route={{
                                                                                                  pathname: `${url}/app/${a.id}`,
                                                                                                  state: {app: a}
                                                                                              }}
                                                                                              as="primary">
                        Manage application
                    </ProtectedButton>]}>
                        <SDSfieldList>
                            <SDSfieldListItem label="Created"
                                              value={formatUTCISODateTime(a.createdDate)}
                                              labelWidth={3}
                                              valueWidth={9}/>
                            <SDSfieldListItem label="Updated"
                                              value={formatUTCISODateTime(a.lastUpdatedDate)}
                                              labelWidth={3}
                                              valueWidth={9}/>
                        </SDSfieldList>
                    </Card>
                </div>)}
            </div>
        </div>
    </SDSpanel>;
};

OrgApplications.propTypes = {};

export default OrgApplications;