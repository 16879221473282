// These types must be kept in sync with the WebhookSecurityOption enum in the backend
export const BASIC_AUTH_API_KEY = "BASIC_AUTH_API_KEY";
export const BEARER_API_KEY = "BEARER_API_KEY";

export const WebhookSecurityOption = [
    {
        value: BASIC_AUTH_API_KEY,
        label: "API key - Basic auth",
        desc: "Basic Auth authentication using the API Key as the username",
        longDesc: "The API key you provide will be used as the username in a Basic Authentication auth header, with no password (e.g. Authorization: [api key])."
    },
    {
        value: BEARER_API_KEY,
        label: "API key - Bearer",
        shortDesc: "Bearer authentication using the API Key as the token",
        longDesc: "The API key you provide will be used as the token in a Bearer auth header (e.g. Authorization: Bearer [api key])."
    }
];