import React, {FC, useCallback, useState} from "react";
import {isObject, uniqBy} from "lodash";
import CreatableSelect from "react-select/creatable";
import {Option, StandardProps, classes, isDisabled} from "./_common";
import "./input-fields.scss";

/**
 * A text field that allows multiple, unique values to be entered at once.
 */
export const MultiValueTextField: FC<MultiValueTextFieldProps> = ({
                                                                      id,
                                                                      name,
                                                                      value,
                                                                      placeholder,
                                                                      disabled,
                                                                      onChange,
                                                                      onBlur,
                                                                      inline,
                                                                      inputClasses,
                                                                      ...rest
                                                                  }: MultiValueTextFieldProps) => {
    const toVal = useCallback((v) => (isObject(v) ? v : {label: v, value: v}), []);
    const [state, setState] = useState(value instanceof Array ? (value as Array<Option>).map(toVal) : isObject(value) ? [value] : value ? toVal(value) : []);
    const [inputValue, setInputValue] = useState("");

    const changeHandler = (value: any) => {
        setState(value || []);
        if (onChange) {
            onChange(value || []);
        }
    };

    const updateValue = useCallback(() => {
        if (!inputValue) {
            return;
        }
        setState((currentState: any) => {
            const newState = uniqBy([...(currentState || []), toVal(inputValue)], "value");
            if (onChange) {
                onChange(newState);
            }
            return newState;
        });
        setInputValue("");

    }, [setState, inputValue, setInputValue, onChange, toVal]);

    const keyDownHandler = useCallback((event) => {
        if (!inputValue) {
            return;
        }
        if (event.key === "Enter" || event.key === "Tab") {
            updateValue();
            event.preventDefault();
        }
    }, [inputValue, updateValue]);

    const blurHandler = useCallback(() => {
        updateValue();
        if (onBlur) {
            onBlur();
        }
    }, [updateValue, onBlur]);

    return <CreatableSelect {...rest}
                            components={{DropdownIndicator: null}}
                            inputValue={inputValue}
                            isClearable
                            isMulti
                            menuIsOpen={false}
                            onChange={changeHandler}
                            onInputChange={setInputValue}
                            onKeyDown={keyDownHandler}
                            classNamePrefix="sds-multitextinput"
                            className={classes("sds-multitextinput", inputClasses)}
                            value={state}
                            placeholder={placeholder}
                            isDisabled={isDisabled(disabled)}
                            onBlur={blurHandler}/>;
};

export type MultiValueTextFieldProps = {
    /**
     * Placeholder text to show when there is no value
     */
    placeholder?: string,
    value?: string | Array<String> | Option | Array<Option>
} & StandardProps;