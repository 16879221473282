import React from "react"
import PropTypes from "prop-types"
import SDSfieldList, {SDSfieldListItem} from "../../../../components/field-list/field-list";
import {OrganisationType, useEnum} from "../../../../utils/enums";
import {EnumService} from "../../../../service/service";
import {useAuth} from "../../../../security/authorisation";

const OrganisationDetails = ({organisation}) => {
    const getType = useEnum(OrganisationType);
    const getCountry = useEnum(EnumService.getCountries);
    const {isAdmin} = useAuth();

    return <div className="row">
        <div className="col-sm-12 col-md-6">
            <h2>Details</h2>
            <SDSfieldList>
                {isAdmin &&
                <SDSfieldListItem label="Organisation ID:"
                                  value={organisation.externalId}/>
                }
                <SDSfieldListItem label="Organisation name:"
                                  value={organisation.name}/>
                <SDSfieldListItem label="Organisation type:"
                                  value={getType(organisation.type)}/>
                <SDSfieldListItem label="ABN:"
                                  value={organisation.abn}/>
                <SDSfieldListItem label="ACN:"
                                  value={organisation.acn}/>
                {organisation.primaryLocation && <>
                    <SDSfieldListItem label="Address 1:"
                                      value={organisation.primaryLocation.addressLine1}/>
                    <SDSfieldListItem label="Address 2:"
                                      value={organisation.primaryLocation.addressLine2}/>
                    <SDSfieldListItem label="Location"
                                      value={`${organisation.primaryLocation.suburb}, ${organisation.primaryLocation.state}, ${organisation.primaryLocation.postcode}, ${getCountry(organisation.primaryLocation.country)}`}/>
                    {isAdmin &&
                    <SDSfieldListItem label="latitude" value={organisation.primaryLocation.latitude} />
                    }
                    {isAdmin &&
                    <SDSfieldListItem label="longitude" value={organisation.primaryLocation.longitude} />
                    }

                </>
                }
                <SDSfieldListItem label="Email:"
                                  value={organisation.email}/>
                <SDSfieldListItem label="Website URL:"
                                  value={organisation.url}/>
                <SDSfieldListItem label="Phone number:"
                                  value={organisation.phone}/>
            </SDSfieldList>
        </div>
        <div className="col-sm-12 col-md-6">
            <h2>Organisation Function(s)</h2>
            <SDSfieldList>
                {organisation.functions
                && organisation.functions
                    .filter(f => !f.systemAssigned || isAdmin)
                    .map(f =>
                        <SDSfieldListItem key={f.code}
                                          label={f.name + (isAdmin && f.systemAssigned? " (system assigned)" : "")}
                                          value={(f.sports || []).map(s => s.name).join(", ")}
                                          hideIfBlank={false}/>
                    )}
            </SDSfieldList>
        </div>
    </div>
};

OrganisationDetails.propTypes = {
    organisation: PropTypes.object.isRequired
};

export default OrganisationDetails;