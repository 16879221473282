import React, { useContext, useRef } from "react"
import PropTypes from "prop-types"
import "./sidebar.scss"
import {endsWith} from "lodash"
import {Link} from "react-router-dom";

const SidebarContext = React.createContext({
    menuItemClick: null
});

const SidebarSubmenuContext = React.createContext({
    link: null
});

const SDSsidebar = ({children}) => {
    const smallNavEl = useRef(null);
    return (
        <>
            <div className="sds-sidebar sticky-top" id="sidebar-small">
                <nav className="sds-sidebar__nav">
                    <ul className="list-unstyled">
                        <li className="sds-menu-item" id="menu-burger">
                            <button className="sds-menu-item__button sds-menu-item__button--active" onClick={e => {
                                e.preventDefault();
                                smallNavEl.current.className = "sds-sidebar__nav sidebar-small-nav-show"
                            }}>
                                <span className="sds-menu-item__icon">
                                    <i className="fa fa-bars"></i>
                                </span>
                                <span className="sds-menu-item__text"></span>
                            </button>
                        </li>
                    </ul>
                </nav>
                <div className="sds-sidebar__nav" id="sidebar-small-nav" ref={smallNavEl}>
                    <ul className="list-unstyled">
                        <li className="sds-menu-item">
                            <button className="sds-menu-item__button sds-menu-item__button--active text-align-right" onClick={e => {
                                e.preventDefault();
                                smallNavEl.current.className = "sds-sidebar__nav";
                            }}>
                                <span className="sds-menu-item__text">Close</span>
                                <span className="sds-menu-item__icon">
                                    <i className="fa fa-times"></i>
                                </span>
                            </button>
                        </li>
                        <SidebarContext.Provider value={{menuItemClick: () => {
                            if (smallNavEl.current) {
                                smallNavEl.current.className = "sds-sidebar__nav";
                            }
                        }}}>
                            {children}
                        </SidebarContext.Provider>
                    </ul>
                </div>
            </div>
            <div className="sds-sidebar sticky-top" id="sidebar-full">
                <nav className="sds-sidebar__nav">
                    <ul className="list-unstyled">
                        <SidebarContext.Provider value={{menuItemClick: null}}>
                            {children}
                        </SidebarContext.Provider>
                    </ul>
                </nav>
            </div>
        </>
    );
};

export default SDSsidebar;

const SDSsubMenu = ({children}) => {
    let context = useContext(SidebarSubmenuContext);
    let current = window.location.href.includes(context.link);
    return (
        <>
        {
            current ?
            <ul className="list-unstyled sds-sub-menu">
                {children}
            </ul> : <></>
        }
        </>
    )
}

const SDSmenuItem = props => {
    let active = endsWith(window.location.href, props.link);
    let className = `sds-menu-item__button ${active ? 'sds-menu-item__button--active': ''}`;
    let sidebarContext = useContext(SidebarContext);

    return (
        <li className="sds-menu-item">
            {props.isExternal ? (
                <a href={props.link} className={className}>
                    <span className="sds-menu-item__icon">{props.icon}</span>
                    <span className="sds-menu-item__text">{props.label}</span>
                </a>
            ) : (
                <Link to={props.link} className={className} onClick={sidebarContext.menuItemClick}>
                    <span className="sds-menu-item__icon">{props.icon}</span>
                    <span className="sds-menu-item__text">{props.label}</span>
                </Link>
            )}
            <SidebarSubmenuContext.Provider value={{link: props.link}}>
                {props.children}
            </SidebarSubmenuContext.Provider>
        </li>
    );
};

SDSmenuItem.propTypes = {
    link: PropTypes.string.isRequired,

    isExternal: PropTypes.bool,

    icon: PropTypes.element,

    label: PropTypes.string.isRequired
};

export { SDSmenuItem, SDSsubMenu };