import React from "react"
import PropTypes from "prop-types"
import "./address.scss"

export const StandardAddress = ({address}) => {
    return (address && Object.keys(address).length > 0 &&
        <div className="sac-address">
            <div>{address.addressLine1}</div>
            {address.addressLine2 && <div>{address.addressLine2}</div>}
            <div>{address.suburb} {address.state} {address.postcode}</div>
            {address.country !== "AU" && address.country !== "Australia" && <div>{address.countryName || address.country}</div>}
        </div>);
};

export const InlineAddress = ({address}) => {
    return (address && Object.keys(address).length > 0 ? <div className="sac-address--inline">
        {address.addressLine1}{address.addressLine2 ? " " + address.addressLine2 + ", " : ", "}{address.suburb} {address.state} {address.postcode}
    </div> : null);
};

export const Address = ({address, as}) => {
    return as === "standard"
        ? <StandardAddress address={address}/>
        : <InlineAddress address={address}/>;
};

Address.propTypes = {
    ...StandardAddress.propTypes,
    as: PropTypes.oneOf(["standard", "inline"])
};

Address.defaultProps = {
    as: "standard"
};

InlineAddress.propTypes = {...StandardAddress.propTypes};

StandardAddress.propTypes = {
    address: PropTypes.shape({
        addressLine1: PropTypes.string,
        addressLine2: PropTypes.string,
        suburb: PropTypes.string,
        state: PropTypes.string,
        postcode: PropTypes.string,
        country: PropTypes.string,
        countryName: PropTypes.string
    })
};
