import {useEffect, useState} from "react";
import {OrganisationService, SportService} from "../../service/service";
import * as yup from "yup";
import {SportFunctionLabelMapper} from "../../utils/sport-function-label-mapper";

const useOrgFunctionsAndSports = () => {
    const [allFunctions, setAllFunctions] = useState([]);
    const [allSports, setAllSports] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        OrganisationService.getOrgFunctions().then(f => {
            setAllFunctions(f.map(ff => ({
                code: ff.code,
                label: ff.name,
                name: ff.name,
                value: ff.code,
                sportSpecific: ff.sportSpecific
            })));
        }).finally(() => setLoading(false));
    }, [setAllFunctions]);

    useEffect(() => {
        SportService.getSports().then(data => {
            setAllSports(data.map(s => ({label: s.name, value: s.externalId})));
        });
    }, [setAllSports]);

    const sportValidationSchema = yup.array()
        .of(
            yup.object().shape({
                function: yup.string(),
                sports: yup.array().of(yup.string()).required((a, b, c) => {
                    return "Please select which sport(s) this organisation represents"
                }),
                label: yup.string()
            }).nullable()
        ).nullable();

    const functionChangeHandler = (selectedFunctions, arrayHelpers) => {
        selectedFunctions = selectedFunctions || [];
        // Create an empty structure to link any new sports
        selectedFunctions.forEach(f => {
            if (f.sportSpecific && !arrayHelpers.form.values.sports.find(s => s.function === f.code)) {
                arrayHelpers.push({
                    function: f.code,
                    sports: [],
                    label: SportFunctionLabelMapper(f.code, f.name)
                });
            }
        });

        // Remove sports no longer linked
        arrayHelpers.form.values.sports.forEach(s => {
            if (!selectedFunctions.find(f => f.code === s.function)) {
                arrayHelpers.remove(s);
            }
        });
    };

    return {
        allSports: allSports,
        allFunctions: allFunctions,
        loading: loading,
        sportValidationSchema: sportValidationSchema,
        functionChangeHandler: functionChangeHandler
    };
};

export default useOrgFunctionsAndSports;