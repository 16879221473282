import React, {useContext} from "react"
import SDSicon from "../components/icons/icons";
import DropdownMenu, {DropdownMenuDivider, DropdownMenuItem} from "@sportaus-digital/dropdown-menu";
import keycloak from "./keycloak";
import {AuthActionsContext, AuthContext} from "./auth-context";
import {trim} from "lodash";

const UserMenu = () => {
    const userProfile = useContext(AuthContext);
    const setUserProfile = useContext(AuthActionsContext);

    let name = trim((userProfile.firstName || "") + " " + (userProfile.lastName || ""));

    return <DropdownMenu id="usermenu"
                            label={name || userProfile.username}
                            iconPosition="right"
                            icon={<SDSicon group="fa" name="fa-user-circle fa-2x" noMargin/>}>
        <DropdownMenuItem route="/home" label="Home" icon={<SDSicon group="fa" name="home"/>}/>
        <DropdownMenuDivider/>
        <DropdownMenuItem onSelect={() => {
            keycloak.logout({redirectUri: window.envConfig.baseUrl}).then(() => {
                setUserProfile({});
            })
        }
        } label="Sign out" icon={<SDSicon group="fas" name="sign-out-alt"/>}/>
    </DropdownMenu>;
};

UserMenu.propTypes = {};

export default UserMenu;