import React from "react"
import PropTypes from "prop-types"
import "./field-list.scss"

const SDSfieldList = ({children}) => {
    return <dl className="sds-field-list row">
        {children}
    </dl>;
};

export const SDSfieldListItem = ({label, value, labelWidth, valueWidth, hideIfBlank, children, labelClassName, valueClassName}) => {
    return (value || children || !hideIfBlank) && <>
        <dt className={`sds-field-list__label col-xs-12 col-sm-4 col-md-${labelWidth} ${labelClassName}`}>{label}</dt>
        <dd className={`sds-field-list__value col-xs-12 col-sm-8 col-md-${valueWidth} ${valueClassName}`}>{value || children}</dd>
    </>;
};

SDSfieldList.propTypes = {};

SDSfieldListItem.propTypes = {
    label: PropTypes.string.isRequired,
    labelWidth: PropTypes.number.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    valueWidth: PropTypes.number,
    hideIfBlank: PropTypes.bool,
    valueClassName: PropTypes.string,
    labelClassName: PropTypes.string
};

SDSfieldListItem.defaultProps = {
    labelWidth: 4,
    valueWidth: 8,
    hideIfBlank: true
};

export default SDSfieldList;