import _ from "lodash";
import {Types} from "./field";
import {FieldBuilder} from "./field-builder";
import {FieldSetBuilder} from "./fieldset-builder";

/**
 * A builder class to create a configuration object suitable to be passed to the <SDSform> component.
 *
 * This builder provides a fluent API for quickly constructing forms based on sensible defaults.
 *
 * @example
 * new FormBuilder().field("field1").field("field", "A label", FieldTypes.SELECT).required().options(callbackFn).build();
 *
 */
class FormBuilder {
    /**
     * @constructor
     */
    constructor() {
        this.fieldBuilders = {};
    }

    /**
     * Create a new field with the minimum required configuration. The id MUST be provided.
     *
     * If no label is provided, the id will be formatted to sentence-case and used instead.
     *
     * If no type is provided, FieldTypes.TEXT will be used as the default.
     *
     * @param id {string} A unique identifier (within the scope of this form). Required.
     * @param label {string} A label to be displayed. Defaults to the id formatted into sentence case.
     * @param type {FieldTypes} The input field type. Defaults to TEXT.
     * @returns {FieldBuilder}
     */
    field(id, label, type) {
        let field = {
            name: id,
            label: label || _.capitalize(_.startCase(id)),
            type: type || Types.TEXT
        };
        let builder = new FieldBuilder(field, this);
        if (this.currentFieldSet) {
            this.currentFieldSet = builder;
        } else {
            this.fieldBuilders[id] = builder;
        }
        return builder;
    }

    /**
     * Create a new field set within the form. All fields added to the form after this will be nested in the field set.
     *
     * To close the field set, call endFieldSet().
     *
     * Field sets cannot be nested.
     *
     * @param title The title display in the <legend> of the field set
     * @param description Descriptive text to be displayed at the start of the fieldset
     * @param titleLevel The heading level for the title
     * @returns {FieldSetBuilder}
     */
    startFieldSet(title, description, titleLevel) {
        let builder = new FieldSetBuilder(this, title, description, titleLevel);
        this.fieldBuilders["fieldSet:" + title] = builder;
        this.currentFieldSet = builder;
        return builder;
    }

    /**
     * Close off the current field set and return the form builder. This MUST be called to end a field set.
     *
     * @returns {FormBuilder}
     */
    endFieldSet() {
        this.currentFieldSet = undefined;
        return this;
    }

    /**
     * Adds an array of FieldBuilders to the FormBuilder. These fields will be appended to any existing fields
     * in the FormBuilder in the order in which they appear in the array.
     *
     * @param fieldArray {[FieldBuilder]} Array of field builders to append to the form
     */
    withFields(fieldArray) {
        let self = this;
        fieldArray.forEach(builder => {
            self.fieldBuilders[builder.getName()] = builder;
        });
        return this;
    }

    /**
     * Finalize and build the form configuration object. The output of this function should be passed without modification to the <SDSform config={}> component.
     * @returns {{}}
     */
    build() {
        let form = {};
        _.forOwn(this.fieldBuilders, (builder, id) => {
            return form[id] = builder.builderType === "FieldSetBuilder" ? builder.build() : builder._buildField();
        });
        return form;
    }
}

export {FormBuilder}
