import {useCallback, useContext, useState} from "react"
import {PageAlertActionsContext} from "../utils/page-alerts";

const useErrorHandler = () => {
    const setPageAlert = useContext(PageAlertActionsContext);
    const [hasError, setHasError] = useState(false);

    const errorHandler = useCallback((error, action) => {
        setHasError(true);
        let severity = "error";
        let message = "An unexpected error occurred while " + action;

        if (error && error.response && (error.response.status === 401 || error.response.status === 403)) {
            message = "You do not have permission to perform this action";
        } else if (error && error.response && error.response.status === 404) {
            message = "Page not found";
        }

        setPageAlert({
            severity: severity,
            message: message
        });
    }, [setHasError, setPageAlert]);

    return {
        errorHandler: errorHandler,
        hasError: hasError
    };
};

export default useErrorHandler;