import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {SDSsubPageTitle} from "../../../../../components/page-title/page-title";
import SDSpanel from "../../../../../components/panel/panel";
import {AffiliationsService} from "../../../../../service/service";
import "../organisation.scss";
import {Protected, useAuth, withAuth} from "../../../../../security/authorisation";
import {InlineAddress} from "../../../../../containers/address/address";
import Button from "@sportaus-digital/buttons";
import ConfirmBtn from "@sportaus-digital/confirm-btn";
import {useHistory} from "react-router";
import {Checkbox} from "@sportaus-digital/form-fields";
import useErrorHandler from "../../../../../hooks/error-handler";
import {
    MANAGES_NATIONAL, MANAGES_REGIONAL, MANAGES_STATE,
    PROVIDES_SOFTWARE_FOR,
    REPORTS_TO_NATIONAL, REPORTS_TO_REGIONAL, REPORTS_TO_STATE,
    USES_SOFTWARE_PROVIDED_BY
} from "../../../../../constants/affilitation-types";
import Alert from "@sportaus-digital/alert";

const ViewAffiliation = ({orgId, affiliationId}) => {
    const {Actions} = useAuth();
    const backRoute = `../connections`;
    const {errorHandler} = useErrorHandler();
    const [loading, setLoading] = useState(true);
    const [processing, setProcessing] = useState(false);
    const [affiliation, setAffiliation] = useState({});
    const router = useHistory();

    useEffect(() => {
        if (orgId) {
            AffiliationsService.getAffiliation(orgId, affiliationId)
                .then((aff) => setAffiliation(aff))
                .catch((e) => errorHandler(e, "retrieving the connection details"))
                .finally(() => setLoading(false));
        }
    }, [orgId, affiliationId, setLoading, errorHandler, setAffiliation]);

    const byLineText = affiliation.status === "AWAITING_RESPONSE"
        ? affiliation.to.name + " would like to connect with " + affiliation.from.name
        : "Details of your connection with an organisation";

    const callService = (service, conn, actionLabel) => {
        setProcessing(true);
        service(orgId, conn.id)
            .then(() => router.push(backRoute))
            .catch((e) => errorHandler(e,  actionLabel + " the connection."))
            .finally(() => setProcessing(false));
    };
    const acceptConnection = (conn) => {
        callService(AffiliationsService.accept, conn, "accepting");
    };
    const declineConnection = (conn) => {
        callService(AffiliationsService.decline, conn, "declining");
    };
    const revokeConnection = (conn) => {
        callService(AffiliationsService.revoke, conn, "revoking");
    };
    const withdrawConnection = (conn) => {
        callService(AffiliationsService.withdraw, conn, "withdrawing");
    };

    const confirmMessage = affiliation.id && <ConfirmMessage connections={[affiliation.typeLabel]}
                                                             fromOrgName={affiliation.from.name}
                                                             toOrgName={affiliation.to.name}/>;
    let controls;
    let actions;
    switch (affiliation.status) {
        case "AWAITING_RESPONSE":
            controls = <>
                <ConfirmBtn onClick={() => declineConnection(affiliation)}
                               disabled={processing}
                               title="Decline this connection?"
                               cancelLabel="No"
                               size="lg"
                               okLabel="Yes"
                               message={confirmMessage}
                               as="tertiary">
                    Decline invite
                </ConfirmBtn>
                <Button onClick={() => acceptConnection(affiliation)}
                           disabled={processing}>
                    Accept invite
                </Button>
            </>;
            actions = [Actions.ORG_AFFILIATIONS_ACCEPT, Actions.ORG_AFFILIATIONS_DECLINE];
            break;
        case "PENDING_APPROVAL":
            controls = <>
                <Button route={backRoute} as="tertiary">Cancel</Button>
                <ConfirmBtn onClick={() => withdrawConnection(affiliation)}
                               title="Remove this connection?"
                               cancelLabel="No"
                               okLabel="Yes"
                               size="lg"
                               message={confirmMessage}
                               disabled={processing}>
                    Withdraw request
                </ConfirmBtn>
            </>;
            actions = [Actions.ORG_AFFILIATIONS_WITHDRAW];
            break;
        case "ACCEPTED":
            controls = <>
                <Button route={backRoute} as="tertiary">Cancel</Button>
                <ConfirmBtn onClick={() => revokeConnection(affiliation)}
                               title="Remove this connection?"
                               cancelLabel="No"
                               okLabel="Yes"
                               size="lg"
                               message={confirmMessage}
                               disabled={processing}>
                    Revoke connection
                </ConfirmBtn>
            </>;
            actions = [Actions.ORG_AFFILIATIONS_REVOKE];
            break;
        default:
            actions = [];
            controls = null;
    }

    return <div className="row affiliation-details px-2 d-md-flex flex-row flex-wrap align-content-stretch justify-content-between align-items-stretch">
        <div className="col-sm-12">
            <SDSsubPageTitle byLine={byLineText} backButtonRoute={backRoute}>
                {affiliation.toName}
            </SDSsubPageTitle>
        </div>
        {affiliation.id &&
        <div className="col-sm-12 align-self-stretch mb-4">
            <SDSpanel title={affiliation.toName}
                      titleLevel={2}
                      loading={loading}>
                <div>Address: <InlineAddress address={affiliation.to.primaryLocation}/></div>
                <div>Sport: {affiliation.to.functions.filter(f => f.sports).flatMap(f => f.sports).map(s => s.name).join(", ") || "N/A"}</div>
                {affiliation.to.abn && <div>ABN: {affiliation.to.abn}</div>}

                <h3>How {affiliation.from.name} is connected</h3>
                <Checkbox disabled={true}
                             name="connectionType"
                             id="connectionType"
                             label={affiliation.typeLabel + (affiliation.sport ? " (for " + affiliation.sport.name + ")" : "")}
                             defaultChecked={true}/>
                <AdditionalContext affiliation={affiliation}/>

                <div className="buttonGroup">
                    {controls ? <Protected action={actions} orgId={orgId}>{controls}</Protected> : null}
                </div>
            </SDSpanel>
        </div>}
    </div>;
};

ViewAffiliation.propTypes = {
    orgId: PropTypes.number.isRequired,
    affiliationId: PropTypes.number.isRequired
};

export default withAuth(ViewAffiliation);

const ConfirmMessage = ({fromOrgName, toOrgName, connections}) => {
    return <div className="affiliation-change-confirmation">
        <p>You are about to remove the following {fromOrgName} connection(s) with {toOrgName}</p>
        <ul className="affiliation-confirmation__connectionList">
            {connections.map(c => <li key={c}>{c}</li>)}
        </ul>
        <p>Would you like to proceed?</p>
    </div>
};

const AdditionalContext = ({affiliation}) => {
    let message;

    switch (affiliation.type) {
        case PROVIDES_SOFTWARE_FOR:
            message = `This connection grants ${affiliation.from.name} the authority to participate in ${affiliation.to.name}'s authentication and data-sharing networks.`;
            break;
        case USES_SOFTWARE_PROVIDED_BY:
            message = `This connection grants ${affiliation.to.name} the authority to participate in ${affiliation.from.name}'s authentication and data-sharing networks.`;
            break;
        case REPORTS_TO_NATIONAL:
        case REPORTS_TO_STATE:
        case REPORTS_TO_REGIONAL:
            message = `This connection grants ${affiliation.from.name} the authority to participate in ${affiliation.to.name}'s authentication network.`;
            break;
        case MANAGES_NATIONAL:
        case MANAGES_STATE:
        case MANAGES_REGIONAL:
            message = `This connection grants ${affiliation.to.name} the authority to participate in ${affiliation.from.name}'s authentication network.`;
            break;
        default:
            message = null;
    }

    return message ? <Alert severity="info" className="mt-5" message={message}/> : null;
};