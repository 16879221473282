import React, {FC} from "react";
import {classes, isDisabled, StandardProps, Option} from "./_common";
import "./input-fields.scss";
import makeAnimated from "react-select/animated";
import {components} from "react-select";
import AsyncSelect from "react-select/async";

export const Autocomplete: FC<AutocompleteProps> = ({
                                                        id,
                                                        name,
                                                        autoCompleteSearch,
                                                        NoOptionsComponent,
                                                        OptionComponent,
                                                        callOnOpen,
                                                        initialOptions,
                                                        disabled,
                                                        multiple,
                                                        value,
                                                        onChange,
                                                        onBlur,
                                                        inline,
                                                        inputClasses,
                                                        placeholder,
                                                        ...rest
                                                    }: AutocompleteProps) => {
    const animatedComponents = makeAnimated();

    const NoOptionsMessage = (props: any) => {
        const noOptions = props.selectProps.inputValue && NoOptionsComponent;
        return <div className={`sds-autocomplete__emptyWrapper${noOptions ? "--with-component" : ""}`}>
            {
                props.selectProps.inputValue
                    ? NoOptionsComponent ? NoOptionsComponent :
                    <div className="sds-autocomplete__defaultNoMatch">No match was found</div>
                    : <div className="sds-autocomplete__typeToSearch">Type to start searching</div>
            }
        </div>
    };

    const DropdownIndicator = (props: any) => {
        return <components.DropdownIndicator {...props}>
            <span className="fa fa-search"/>
        </components.DropdownIndicator>
    };

    const Option = (props: any) => {
        return <components.Option {...props}>
            {OptionComponent ? <OptionComponent {...props}/> : props.children}
        </components.Option>
    };

    return <AsyncSelect {...rest}
                        components={{...animatedComponents, NoOptionsMessage, DropdownIndicator, Option}}
                        cacheOptions
                        isClearable={true}
                        loadOptions={autoCompleteSearch}
                        defaultOptions={callOnOpen ? true : initialOptions}
                        isDisabled={isDisabled(disabled)}
                        name={name || id}
                        isMulti={multiple}
                        minimumInput={3}
                        closeMenuOnSelect={!multiple}
                        classNamePrefix="sds-autocomplete"
                        className={classes("sds-autocomplete", inputClasses)}
                        value={value}
                        onBlur={onBlur}
                        placeholder={placeholder}
                        onChange={onChange}/>
};

export type AutocompleteProps = {
    value?: string | Array<string>,
    /**
     * True to allow multiple selection
     */
    multiple?: boolean,
    /**
     * Callback function to retrieve the options. The function will receive a single parameter containing the value
     * the user has types, and must return a Promise which, when resolved, returns an array of objects.
     *
     * The objects in the array must have the structure {label: "", value: ""} UNLESS the renderComponent prop has
     * been provided.
     */
    autoCompleteSearch?: (val: string) => Promise<Array<Option>>,
    /**
     * Whether to invoke the callback function as soon as the user enters the field and the drop-down opens.
     */
    callOnOpen?: boolean,
    /**
     * Initial set of options to show before the autoCompleteSearch function is called
     */
    initialOptions?: Array<Option>,
    /**
     * Placeholder text to show when there is no value
     */
    placeholder?: string,
    /**
     * Component to be displayed when there is no matching option for the search term.
     * If not provided, a default message will be displayed.
     */
    NoOptionsComponent?: FC,
    /**
     * Optional custom component used to render each option in the dropdown.
     */
    OptionComponent?: FC
} & StandardProps;