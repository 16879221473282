import Keycloak from "keycloak-js";

let conf = window.envConfig || {};
const kcConfig = {
    url: conf.keycloakAuthUrl || "",
    realm: conf.keycloakRealm || "unknown",
    clientId: conf.keycloakClientId || "unknown",
    onLoad: "check-sso"
};

const keycloak = new Keycloak(kcConfig);

export default keycloak;