import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import {ApplicationService, OrganisationService} from "../../../../../service/service";
import {SDSsubPageTitle} from "../../../../../components/page-title/page-title";
import RoleForm from "../../../../../containers/role-form/role-form";
import {useHistory} from "react-router";
import SDSpanel from "../../../../../components/panel/panel";
import DataTable from "@sportaus-digital/data-table";
import Spinner from "@sportaus-digital/spinner";
import useErrorHandler from "../../../../../hooks/error-handler";

const OrganisationRoleEdit = ({orgId, roleId}) => {
    const [selectedRole, setSelectedRole] = useState({});
    const [capabilities, setCapabilities] = useState([]);
    const [apps, setApps] = useState([]);
    const {errorHandler} = useErrorHandler();
    const router = useHistory();
    const backRoute = '../roles';

    useEffect(() => {
        OrganisationService.getOrganisation(orgId)
          .then(org => {setSelectedRole(org.allRoles.find(role => role.id === roleId))})
          .catch((e) => errorHandler(e, "retrieving the organisation"));

        OrganisationService.getAvailableUserCapabilities(orgId)
          .then((data) => {setCapabilities(data)})
          .catch((e) => errorHandler(e, "retrieving the organisation capabilities"));

        ApplicationService.getApplicationsForOrg(orgId)
          .then(a => setApps(a))
          .catch((e) => errorHandler(e, "retrieving your applications"));
    }, [orgId, roleId, setApps, setSelectedRole, errorHandler, setCapabilities]);

    const deleteHandler = () => {
        OrganisationService.deleteOrganisationRole(orgId, roleId).then(() => {
            router.push(backRoute);
        }).catch((e) => errorHandler(e, "deleting the role"));
    };

    const submitHandler = (role, actions) => {
        role.capabilities = role.capabilities.map(c => ({code: c}));
        // Applications were nested under a capability, but we simplify things and apply them to the whole role
        let allApplications = [];
        role.applications.forEach(a => {
            if (a.applications) {
                a.applications.forEach(a2 => allApplications.push(a2))
            }
        });
        role.applications = allApplications;

        OrganisationService.updateOrganisationRole(orgId, role).then(() => {
            actions.setSubmitting(false);
            actions.resetForm();
            router.push(backRoute);
        }).catch(() => {
            actions.setStatus({status: "error"});
            actions.setSubmitting(false);
        });
    };

    const headers = [
        {
            title: "Capability",
            key: "name",
            sortable: true
        },
        {
            title: "Description",
            key: "description"
        }
    ];

    return <div
        className="row px-2 d-md-flex flex-row flex-wrap align-content-stretch justify-content-between align-items-stretch">
        <div className="col-sm-12">
            <SDSsubPageTitle byLine={"Update the details of a custom role"} backButtonRoute={backRoute}>Update a custom
                role</SDSsubPageTitle>
        </div>
        <div className="col-sm-6 align-self-stretch mb-4">
            <SDSpanel titleLevel={2} title="Details">
                <RoleForm orgId={orgId}
                          currentRole={selectedRole}
                          orgApps={apps}
                          submitHandler={submitHandler}
                          cancelRoute={backRoute}
                          deleteHandler={deleteHandler}/>
            </SDSpanel>
        </div>
        <div className="col-sm-6 align-self-stretch mb-4">
            <SDSpanel titleLevel={2} title="Capabilities">
                <DataTable headers={headers}
                           spinner={<Spinner hideText={true} inline={true}/>}
                           data={capabilities}
                           pageable={true}
                           defaultSort="capability"/>
            </SDSpanel>
        </div>
    </div>;
};

OrganisationRoleEdit.propTypes = {
    orgId: PropTypes.number.isRequired,
    roleId: PropTypes.number.isRequired
};

export default OrganisationRoleEdit;