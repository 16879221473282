import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import {Actions, ProtectedButton} from "../../../../../security/authorisation";
import SDSpanel from "../../../../../components/panel/panel";
import {useRouteMatch} from "react-router";
import {AffiliationsService} from "../../../../../service/service";
import "../organisation.scss";
import Button from "@sportaus-digital/buttons";

const OrgAffiliationsSummary = ({organisation}) => {
    const {url} = useRouteMatch();
    const [pending, setPending] = useState([]);
    const [suggested, setSuggested] = useState([]);

    useEffect(() => {
        AffiliationsService.getPendingAffiliations(organisation.id).then((data) => {
            setPending(data);
        });
        AffiliationsService.getSuggestedAffiliations(organisation.id).then((data) => {
            setSuggested(data);
        });
    }, [organisation.id, setPending, setSuggested]);

    return <SDSpanel title="Connections"
                     action={<ProtectedButton action={Actions.ORG_AFFILIATIONS_LIST}
                                              orgId={organisation.id}
                                              className="sds-btn--block"
                                              route={`${url}/connections`}
                                              as="tertiary">View your connected organisations</ProtectedButton>}>
        <div className="affiliations-summary">
            Manage {organisation.name}'s connections with other organisations.

            {pending && pending.length > 0 && <>
                <h3>Pending requests</h3>
                <ul>
                    {pending.map(p => <li key={p.id}>
                        <span className="text">{p.toName}</span>
                        <Button as="tertiary" route={`${url}/connections/${p.id}`}>View request</Button>
                    </li>)}
                </ul>
            </>}
            {suggested && suggested.length > 0 && <>
                <h3>Suggested connections</h3>
                <ul>
                    {suggested.map(s => <li key={s.org.id}>
                        <span className="text">{s.org.name}</span>
                        <Button as="tertiary" route={{
                            pathname: `${url}/connections/add`,
                            state: {
                                toOrg: s
                            }
                        }}>Send request</Button>
                    </li>)}
                </ul>
            </>}
        </div>
    </SDSpanel>;
};

OrgAffiliationsSummary.propTypes = {
    organisation: PropTypes.object
};

export default OrgAffiliationsSummary;