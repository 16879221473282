import React, {FC, MouseEvent} from "react";
import "./pill.scss";

/**
 * Simple Pill component
 */
export const Pill: FC<PillProps> = React.memo(({
                                                   text,
                                                   onClick
                                               }: PillProps) => {
    const clickHandler = (e: MouseEvent) => {
        e.preventDefault();
        onClick ? onClick(e) : null;
    };

    return onClick
        ? <a href="#" onClick={clickHandler} className="pill">{text}</a>
        : <span className="pill">{text}</span>;
});

export interface PillProps {
    /**
     * Text to display in the Pill.
     */
    text: string,
    /**
     * Function to invoke when the pill is clicked. When provided, the pill will be rendered as a link;
     * when not provided, the pill will be rendered as an inline-block span.
     */
    onClick?: (e: MouseEvent) => void
}

export default Pill;