import React, {FC, ReactElement, ReactNode} from "react"
import "./dropdown-menu.scss";
import {Link} from "react-router-dom";
import VisuallyHidden from "@reach/visually-hidden";
import {Dropdown} from "react-bootstrap";

/**
 * A basic drop down menu component, currently based on the Bootstrap Dropdown.
 *
 * NOTE: This component will only work correctly if you have Bootstrap's CSS in your application.
 * This component DOES NOT load the bootstrap CSS.
 */
export const DropdownMenu: FC<DropdownMenuProps> = ({
                                                        id,
                                                        as = "tertiary",
                                                        label,
                                                        icon,
                                                        iconPosition = "right",
                                                        expandIcon,
                                                        srLabel,
                                                        children
                                                    }: DropdownMenuProps) => {
    return <div className={`sds-dropdown-menu sds-dropdown-menu--${as}`}>
        <Dropdown alignRight>
            <Dropdown.Toggle id={`${id}-toggle`} aria-controls={`${id}-menu`} variant={as}
                             className="sds-dropdown-menu__button">
                <div className="sds-dropdown-menu__buttonContent">
                    {iconPosition === "left" && icon ? <span className="sds-dropdown-menu__icon">{icon}</span> : null}
                    <span className="sds-dropdown-menu__label">{label}</span>
                    {srLabel && <VisuallyHidden>{srLabel}</VisuallyHidden>}
                    {iconPosition === "right" && icon ? <span className="sds-dropdown-menu__icon">{icon}</span> : null}
                    {expandIcon ? <span aria-hidden>{expandIcon}</span> : null}
                </div>
            </Dropdown.Toggle>
            <Dropdown.Menu alignRight
                           id={`${id}-menu`}
                           flip
                           role="menu"
                           aria-labelledby={`${id}-toggle`}
                           className="sds-dropdown-menu__dropdown">
                {children}
            </Dropdown.Menu>
        </Dropdown>
    </div>;
};

export interface DropdownMenuProps {
    /**
     * Unique identifier for the dropdown
     */
    id: string,
    /**
     * The label of the toggle control
     */
    label?: string,
    /**
     * A label to be rendered only for Screen Readers or other assistive tech - this is not visible.
     *
     * E.g. you would use this if you are only showing an icon instead of a label for the toggle control.
     */
    srLabel?: string,
    /**
     * An icon to display with, or instead of, the toggle control's label
     */
    icon?: ReactElement,
    /**
     * The position of the icon if both label and icon are provided (left or right of the label text)
     */
    iconPosition?: "left" | "right",
    /**
     * The style level for the toggle control
     */
    as?: "primary" | "secondary" | "tertiary",
    /**
     * An icon to display to the right of the toggle control to indicate that it is a dropdown.
     */
    expandIcon?: ReactElement,
    /**
     * The content of the modal dialog
     */
    children: ReactNode
}

/**
 * The individual items in the drop down menu
 */
export const DropdownMenuItem: FC<DropdownMenuItemProps> = ({
                                                                label,
                                                                route,
                                                                href,
                                                                onSelect,
                                                                icon
                                                            }: DropdownMenuItemProps) => {
    let item;
    let labelElem = <span className="sds-dropdown-menuItem__label">
        {icon && <span className="sds-dropdown-menuItem__icon">{icon}</span>}
        <span className="sds-dropdown-menuItem__icon">{label}</span>
    </span>;

    if (route) {
        item = <Dropdown.Item as={Link}
                              to={route}
                              onSelect={onSelect}
                              role="menuitem"
                              className="sds-dropdown-menuItem">{labelElem}</Dropdown.Item>;
    } else if (href) {
        item = <Dropdown.Item as="a"
                              href={href}
                              onSelect={onSelect}
                              role="menuitem"
                              className="sds-dropdown-menuItem">{labelElem}</Dropdown.Item>;
    } else {
        item = <Dropdown.Item onSelect={onSelect}
                              role="menuitem"
                              className="sds-dropdown-menuItem">{labelElem}</Dropdown.Item>;
    }
    return item;
};

export interface DropdownMenuItemProps {
    /**
     * Action to perform when the menu item is clicked
     */
    onSelect?: () => void,
    /**
     * The menu item's label
     */
    label: string,
    /**
     * An optional React Router route for the menu item
     * (this will result in the link being rendered as a Router Link element)
     */
    route?: string,
    /**
     * The url for the menu item (this will result in the link being rendered as a standard html anchor)
     */
    href?: string,
    /**
     * An optional icon to display to the left of the link
     */
    icon?: ReactElement
}

/**
 * A divider element (horizontal line) to separate menu items
 */
export const DropdownMenuDivider: FC = () => {
    return <div className="sds-dropdown-menu__divider"/>;
};

export default DropdownMenu;