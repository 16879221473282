import React from "react"
import PropTypes from "prop-types"
import SDSpanel from "../../components/panel/panel";
import {uniq, partition, capitalize} from "lodash";
import Accordion from "@sportaus-digital/accordion";
import {formatUTCISODateTime} from "../../utils/date-utils";
import "./sac-consent.scss"
import {ConsentResponse, useEnum} from "../../utils/enums";

const SACconsent = ({consent, clusterDescriptions, purposeDescriptions}) => {
    const [current, previous] = partition(consent.history, {status: "CURRENT"});

    return <SDSpanel className="sds-consent">
        <Consent audience={consent.audienceText}
                 purpose={purposeDescriptions[current[0].purpose]}
                 historicOnly={!current || current.length === 0}
                 response={current[0] ? current[0].response : undefined}
                 status={current[0] ? current[0].status : undefined}
                 timestamp={current[0] ? current[0].timestamp : undefined}
                 data={current[0] ? current[0].data.flatMap(d => clusterDescriptions[d]) : []}/>

        {previous && previous.length > 0 &&
        <Accordion expandLink="Show previous versions"
                   collapseLink="Hide previous versions">
            {previous.map(p => <Consent key={p.timestamp}
                                        timestamp={p.timestamp}
                                        status={p.status}
                                        purpose={purposeDescriptions[p.purpose] || p.purpose}
                                        response={p.response}
                                        data={p.data.flatMap(d => clusterDescriptions[d] || [d])}/>)}
        </Accordion>
        }
    </SDSpanel>;
};

const Consent = ({audience, purpose, data, timestamp, status, response, historicOnly}) => {
    const getResponse = useEnum(ConsentResponse);

    return <div className="sds-consent__consent">
        <div className="sds-consent__header">
            {
                audience && <h3 className="sds-consent__heading">Sharing with {audience}</h3>
            }
            <div className="sds-consent__timestamp">
                {
                    getResponse(response) + " on " + formatUTCISODateTime(timestamp)
                }
                {
                    status && status !== 'CURRENT'
                    && <span className="sds-consent__status">({capitalize(status)})</span>
                }
            </div>
        </div>
        {
            historicOnly
                ? <p>You are not currently sharing data with this audience.</p>
                : <>
                    <h4 className="sds-consent__heading">What {status === 'CURRENT' ? 'am' : 'was'} I sharing?</h4>
                    <ul className="sds-consent__data-list">
                        {
                            uniq(data.map(dd => <li key={dd}>{dd}</li>))
                        }
                    </ul>

                    {
                        purpose &&
                        (<>
                            <h4 className="sds-consent__heading">Why {status === 'CURRENT' ? 'do' : 'did'} they need
                                this?</h4>
                            <ul className="sds-consent__purpose-list">
                                <li>{purpose}</li>
                            </ul>
                        </>)
                    }
                </>
        }
    </div>
};

SACconsent.propTypes = {
    consent: PropTypes.object.isRequired
};

export default SACconsent;