import React from "react";
import Button from "@sportaus-digital/buttons";

const DocumentSubmitButtons = (props) => {
  const {backRoute, isSubmitting} = props;

  /* Please ensure the value is defaulted in initialValues, otherwise the validation will not work */

  return (
    <div className="sds-form__standardActions">
        <div className="float-right">
            <Button
            as="tertiary"
            type="button"
            route={backRoute}
            className={`sds-form__cancel${backRoute ? "--route" : ""}`}
            >
            Cancel
            </Button>

            <Button
            type="submit"
            className="sds-form__submit"
            disabled={isSubmitting}
            >
            {isSubmitting && (
                <span className="spinner-border spinner-border-sm mr-1"></span>
            )}
            Submit
            </Button>
        </div>
    </div>
  );
};

export default DocumentSubmitButtons;
