import Button from "@sportaus-digital/buttons";
import React, { useCallback, useContext, useState } from "react";
import { useRouteMatch } from "react-router";
import { Link } from "react-router-dom";
import { SDSlandingPageTitle } from "../../../components/page-title/page-title";
import SDSpanel from "../../../components/panel/panel";
import * as Terms from "../../../constants/terms";
import FederatedIdentities from "../../../containers/identity/federated-identities";
import SportingIdentities from "../../../containers/identity/sporting-identities";
import useErrorHandler from "../../../hooks/error-handler";
import { Actions, Protected, ProtectedButton, useAuth, withAuth } from "../../../security/authorisation";
import { UserService } from "../../../service/service";
import { PageAlertActionsContext } from "../../../utils/page-alerts";

const HomeDashboard = () => {
  const { path } = useRouteMatch();
  const { userProfile, isAdmin, hasPermission, updateUserProfile } = useAuth();
  const { errorHandler } = useErrorHandler();
  const setPageAlert = useContext(PageAlertActionsContext);
  const [showMore, setShowMore] = useState(false);

  const requestOrgAccess = useCallback(() => {
    UserService.requestOrgAccess()
      .then(() => {
        updateUserProfile().then(() => {
          setPageAlert({
            severity: "success",
            message: "You have now been given access to Organisation Management."
          });
        });
      })
      .catch((e) => errorHandler(e, "processing your request"));
  }, [updateUserProfile, setPageAlert, errorHandler]);

  let orgLink = isAdmin ? `${path}/admin/organisations` : `${path}/organisations`;
  const orgsAction = <ProtectedButton
    action={isAdmin ? Actions.ORG_LIST_ALL : Actions.ORG_LIST_OWN}
    className="sds-btn--block"
    route={orgLink}>
    Manage your organisations
  </ProtectedButton>;

  const sportsAction = <ProtectedButton
    action={Actions.SPORT_MANAGE}
    className="sds-btn--block"
    route={`${path}/admin/sports`}>
    View sports
  </ProtectedButton>;

  return (
    <div>
      <div className="row">
        <div className="col-sm-12">
          <SDSlandingPageTitle
            icon={<span className="fas fa-user-circle fa-4x" />}
            byLine={`Manage your personal details, identity verification, linked accounts and security settings of your ${Terms.PRODUCT_NAME} account`}
          >
            Welcome, {userProfile.firstName} {userProfile.lastName}
          </SDSlandingPageTitle>
        </div>
      </div>
      <div className="row panel-container">
        <div className="col-xs-12 col-sm-6">
          <SDSpanel
            title="Account details"
            action={
              <Link
                to={`${path}/account/userdetails`}
                className="au-btn sds-button sds-btn--block sds-button--tertiary"
              >
                View details
              </Link>
            }
          >
            Manage your {Terms.PRODUCT_NAME} account details
          </SDSpanel>
        </div>

        <Protected action={Actions.DOCUMENT_VERIFICATION_READ}>
          <div className="col-xs-12 col-sm-6">
            {console.log(path)}
            <SDSpanel
              title="Identity Verification"
              action={
                <Link
                  to={`${path}/account/documents`}
                  className="au-btn sds-button sds-btn--block sds-button--tertiary"
                >
                  View details
                </Link>
              }
            >
              Verify your identity information.
            </SDSpanel>
          </div>
        </Protected>

        <Protected action={Actions.USER_PROFILE_LIST_IDENTITIES}>
          <SportingIdentities />
        </Protected>

        <Protected action={Actions.USER_PROFILE_LIST_IDENTITIES}>
          <FederatedIdentities />
        </Protected>

        <Protected
          action={isAdmin ? Actions.ORG_LIST_ALL : Actions.ORG_LIST_OWN}
        >
          <div className="col-xs-12 col-sm-6">
            <SDSpanel title="Organisations" action={orgsAction}>
              Manage organisations, organisation administrators and
              applications
            </SDSpanel>
          </div>
        </Protected>

        <Protected action={Actions.API_KEY_ADMIN}>
          <div className="col-xs-12 col-sm-6">
            <SDSpanel
              title="Realms"
              action={
                <Link
                  to={`${path}/admin/realms`}
                  className="au-btn sds-button sds-btn--block sds-button--tertiary"
                >
                  View details
                </Link>
              }
            >
              Manage KeyCloak realms
            </SDSpanel>
          </div>
        </Protected>
        <Protected action={Actions.SPORT_MANAGE}>
          <div className="col-xs-12 col-sm-6">
            <SDSpanel title="Sports" action={sportsAction}>
              Manage the list of sports recognised by Sport Australia
            </SDSpanel>
          </div>
        </Protected>

        <Protected action={Actions.USER_PROFILE_READ_CONSENTS}>
          <div className="col-xs-12 col-sm-6">
            <SDSpanel
              title="Privacy"
              action={
                <ProtectedButton
                  action={Actions.USER_PROFILE_READ_CONSENTS}
                  className="sds-btn--block"
                  route={`${path}/privacy`}
                >
                  View privacy settings
                </ProtectedButton>
              }
            >
              Manage your {Terms.PRODUCT_NAME} data sharing arrangements
            </SDSpanel>
          </div>
        </Protected>
        {!isAdmin &&
          hasPermission(Actions.REQUEST_ORG_ACCESS) &&
          !hasPermission(Actions.ORG_LIST_OWN) && (
            <>
              {!showMore && (
                <div className="mt-3 ml-2">
                  <Button as="secondary" onClick={() => setShowMore(true)}>
                    More options...
                  </Button>
                </div>
              )}
              {
                <div
                  className={`col-xs-12 col-sm-6 ${showMore ? "fade-in" : "hide"
                    }`}
                >
                  <SDSpanel title="Organisations">
                    Do you run a sporting organisation like a Club or a
                    Sporting Software Vendor, and would you like to manage
                    your organisation in {Terms.PRODUCT_NAME}?
                    <Button
                      as="tertiary"
                      className="mt-3"
                      onClick={requestOrgAccess}
                    >
                      Yes, please give me access to Organisation Management
                    </Button>
                  </SDSpanel>
                </div>
              }
            </>
          )}
      </div>
    </div>
  );
};

HomeDashboard.propTypes = {};

export default withAuth(HomeDashboard);