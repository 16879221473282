import {isArray, isObject, isUndefined} from "lodash";
import {Types} from "./field";
import * as yup from "yup";

// The default URL validation in Yup requires the http/s prefix. This is a more relaxed regex that does not.
// These are all valid: http://google.com, https://www.google.com, google.com, localhost:3000
const URL_REGEX = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/|www\.)?[a-z0-9]+([-.][a-z0-9]+)*((:[0-9]{1,5})|(\.[a-z]{2,5}))(\/.*)?$/i;

/**
 * A builder class for configuring an individual Field. Not intended to be used directly.
 */
class FieldBuilder {
    /**
     * Constructs a new Field configuration object. This should not be invoked directly: it is intended to be
     * used solely by the FormBuilder class.
     *
     * @param field {object} The initial field state - must include a name, label and type
     * @param formBuilder {FormBuilder} The parent FormBuilder instance
     */
    constructor(field, formBuilder) {
        this.config = field;
        // Must have a defined default value or Formik/Yup/React will throw
        // "A component is changing an uncontrolled input of type text to be controlled"
        this.config.value = "";
        this.formBuilder = formBuilder;

        const initialYup = () => {
            return field.type === Types.NUMBER
                ? yup.number().typeError(field.label + " must be a number")
                : field.type === Types.MULTI_TEXT
                    ? yup.array()
                    : yup.string();
        };

        this.validator = {
            yup: initialYup()
        };
    }

    getName() {
        return this.config.name;
    }

    /**
     * Set the initial value of the field. The value cannot be undefined: if not provided,
     * then the FieldBuilder will default it to an empty string (or an empty array for multivalued selects)
     *
     * @param {*[]} value The initial value of the field
     * @returns {FieldBuilder}
     */
    value(value) {
        // Must have a defined default value or Formik/Yup/React will throw
        // "A component is changing an uncontrolled input of type text to be controlled"
        if (!isObject(value)) {
            this.config.value = isUndefined(value) || value == null ? "" : value;
            this.config.value = this.config.multiple && !isArray(this.config.value) ? [this.config.value] : this.config.value;
        } else {
            this.config.value = value;
        }
        return this;
    }

    /**
     * Hint text to be displayed below the input field
     *
     * @param hint {string} Hint text describing the use or purpose of the field
     * @returns {FieldBuilder}
     */
    hint(hint) {
        this.config.hint = hint;
        return this;
    }

    /**
     * Placeholder text to be displayed within the input field
     *
     * @param placeholder {string} Placeholder text
     * @returns {FieldBuilder}
     */
    placeholder(placeholder) {
        this.config.placeholder = placeholder;
        return this;
    }

    /**
     * Only relevant for types SELECT, CHECKBOX_GROUP and RADIO_GROUP.
     *
     * Sets the available options for the input field. This can be either an array of objects,
     * or a function that returns a Promise which, when resolved, returns an array of objects.
     *
     * The objects must have the structure {label: "", value: ?} for select fields, and {label: "", checked: bool}
     * for checkbox and radio groups.
     *
     * When providing a function, an optional mapper function can also be provided to convert the
     * returned object structure into the necessary structure.
     *
     * @param options {array|function} Object array or callback function
     * @param mapper {function} Optional mapping function to convert the callback result to an array
     * @returns {FieldBuilder}
     */
    options(options, mapper) {
        if (this.config.type === Types.SELECT
            || this.config.type === Types.CHECKBOX_GROUP
            || this.config.type === Types.RADIO_GROUP) {
            this.config.options = options;
            this.config.mapper = mapper;
        }
        return this;
    }

    /**
     * Only relevant for AUTOCOMPLETE fields.
     *
     * Callback function to retrieve the options for an AUTOCOMPLETE field. Ignored for all other field types.
     *
     * The function must return a promise which, when resolved, returns an array of objects. These objects must
     * be of the structure {label: "", value: ""}, UNLESS a custom renderComponent has been provided.
     *
     * The callback function will be passed a single parameter containing the text the user has entered.
     *
     * @param callback {function} Callback function used to populate the drop-down as the user types.
     * @returns {FieldBuilder}
     */
    autoCompleteSearch(callback) {
        if (this.config.type === Types.AUTOCOMPLETE) {
            this.config.autoCompleteSearch = callback;
        }
        return this;
    }

    /**
     * Only relevant for AUTOCOMPLETE fields.
     *
     * Initial set of options to show before the autoCompleteSearch function is called
     *
     * @param initialOptions {array} Default options
     * @returns {FieldBuilder}
     */
    initialOptions(initialOptions) {
        if (this.config.type === Types.AUTOCOMPLETE) {
            this.config.initialOptions = initialOptions;
        }
        return this;
    }

    /**
     * Only relevant for SELECT fields.
     *
     * Automatically select the first option if there is only 1 option
     *
     * @param autoSelectSingleOption {boolean} True to automatically select a single option
     * @returns {FieldBuilder}
     */
    autoSelectSingleOption(autoSelectSingleOption) {
        if (this.config.type === Types.SELECT) {
            this.config.autoSelectSingleOption = autoSelectSingleOption;
        }
        return this;
    }

    /**
     * Only relevant for AUTOCOMPLETE fields.
     *
     * Component to be displayed when there is no matching option for the search term.
     * If not provided, a default message will be displayed.
     *
     * @param noOptionsComponent {object}
     * @returns {FieldBuilder}
     */
    noOptionsComponent(noOptionsComponent) {
        if (this.config.type === Types.AUTOCOMPLETE) {
            this.config.NoOptionsComponent = noOptionsComponent;
        }
        return this;
    }

    /**
     * Only relevant for types SELECT and AUTOCOMPLETE.
     *
     * Optional custom component used to render each option in the dropdown.
     *
     * @param optionComponent {object}
     * @returns {FieldBuilder}
     */
    optionComponent(optionComponent) {
        if (this.config.type === Types.AUTOCOMPLETE || this.config.type === Types.SELECT) {
            this.config.OptionComponent = optionComponent;
        }
        return this;
    }

    /**
     * Only relevant for SELECT fields.
     *
     * Adds an empty option to the options list. If no parameter is provided, a default label will be used.
     * The value attribute must be defined.
     *
     * @param option {Object} optional object with the structure {label: "", value: ""} to be used as the 'empty' option
     * @returns {FieldBuilder}
     */
    emptyOption(option) {
        if (this.config.type === Types.SELECT) {
            this.config.emptyOption = option || {label: "--- select one ---", value: ""};
        }
        return this;
    }

    /**
     * Only relevant for SELECT fields.
     *
     * Converts the Select input field to a multi-select field.
     *
     * @returns {FieldBuilder}
     */
    multiple() {
        if (this.config.type === Types.SELECT) {
            this.config.multiple = true;
            if (!isArray(this.config.value)) {
                this.config.value = [this.config.value];
            }
        }
        return this;
    }

    /**
     * Changes the default display attribute from block to inline-block.
     *
     * @returns {FieldBuilder}
     */
    inline() {
        this.config.inline = true;
        return this;
    }

    /**
     * Adds a 'required' validation rule to the field.
     *
     * @param {string=} msg Optional error message to override the default message
     * @returns {FieldBuilder}
     */
    required(msg) {
        this.validator.required = true;
        this.validator.requiredMsg = msg;
        this.config.required = true; // used for styling only: the other values are used for validation
        return this;
    }

    /**
     * Adds a minimum value validation rule to the field.
     * For NUMBER fields, this is the minimum INCLUSIVE value.
     * For TEXT fields, this is the minimum string length.
     *
     * @param min {number} Minimum inclusive value or length (depending on the field type)
     * @param msg {string} Optional error message to override the default message
     * @returns {FieldBuilder}
     */
    min(min, msg) {
        this.validator.min = min;
        this.validator.minMsg = msg;
        return this;
    }

    /**
     * Adds a maximum value validation rule to the field.
     * For NUMBER fields, this is the maximum INCLUSIVE value.
     * For TEXT fields, this is the maximum string length.
     *
     * @param max {number} Maximum inclusive value or length (depending on the field type)
     * @param msg {string} Optional error message to override the default message
     * @returns {FieldBuilder}
     */
    max(max, msg) {
        this.validator.max = max;
        this.validator.maxMsg = msg;
        return this;
    }

    /**
     * Adds an email format validation rule to the field (i.e. the provided value
     * must have a valid format for an email address). This does NOT validate that the email
     * address is real.
     *
     * @param msg {string} Optional error message to override the default message
     * @returns {FieldBuilder}
     */
    email(msg) {
        this.validator.email = true;
        this.validator.emailMsg = msg;
        return this;
    }

    /**
     * Adds a url format validation rule to the field (i.e. the provided value
     * must have a valid format for a url). This does NOT validate that the url
     * is real.
     *
     * @param msg {string} Optional error message to override the default message
     * @returns {FieldBuilder}
     */
    url(msg) {
        this.validator.url = true;
        this.validator.urlMsg = msg;
        return this;
    }

    /**
     * Adds a regex pattern validation rule to the field.
     *
     * @param regex {string|regex} Regex pattern to match
     * @param msg {string} Optional error message to override the default message
     * @returns {FieldBuilder}
     */
    regex(regex, msg) {
        this.validator.regex = regex;
        this.validator.regexMsg = msg;
        return this;
    }

    /**
     * Overrides the basic validation rules set by required, email, min, etc.
     *
     * Only use this if the built-in validators are not sufficient for your needs.
     *
     * @param yupValidation A validation schema generated by the Yup validation package
     */
    withValidation(yupValidation) {
        this.config.validation = yupValidation;
        return this;
    }

    /**
     * CSS class name(s) to be applied to the field group wrapper div.
     *
     * @param className {string|array}
     * @returns {FieldBuilder}
     */
    wrapperClasses(className) {
        this.config.wrapperClasses = className;
        return this;
    }

    /**
     * CSS class name(s) to be applied to the input field.
     *
     * @param className {string|array}
     * @returns {FieldBuilder}
     */
    inputClasses(className) {
        this.config.inputClasses = className;
        return this;
    }

    /**
     * CSS class name(s) to be applied to the label.
     *
     * @param className {string|array}
     * @returns {FieldBuilder}
     */
    labelClasses(className) {
        this.config.labelClasses = className;
        return this;
    }

    /**
     * CSS class name(s) to be applied to the error.
     *
     * @param className {string|array}
     * @returns {FieldBuilder}
     */
    errorClasses(className) {
        this.config.errorClasses = className;
        return this;
    }

    /**
     * CSS class name(s) to be applied to the hint.
     *
     * @param className {string|array}
     * @returns {FieldBuilder}
     */
    hintClasses(className) {
        this.config.hintClasses = className;
        return this;
    }

    /**
     * A function to determine the field's disabled state. The function will be invoked at render time,
     * and will receive the current form state object as the only parameter.
     *
     * @param disabledFn {function} Callback function executed at render time to determine the field's disabled state
     * @returns {FieldBuilder}
     */
    disabled(disabledFn) {
        this.config.disabled = disabledFn;
        return this;
    }

    /**
     * A function to determine if the field should be displayed. The function will be invoked at render time,
     * and will receive the current form state object as the only parameter.
     *
     * Hidden fields are completely removed from the DOM.
     *
     * @param hiddenFn {function} Callback function executed at render time to determine if the field should be rendered.
     * @returns {FieldBuilder}
     */
    hidden(hiddenFn) {
        this.config.hidden = hiddenFn;
        return this;
    }

    /**
     * A warning message to be displayed below the field. Warnings do not prevent form submission.
     *
     * @param message {String|array} The warning message.
     * @returns {FieldBuilder}
     */
    warning(message) {
        this.config.warning = message;
        return this;
    }


    /**
     * An error message to be displayed below the field. Errors will prevent form submission.
     *
     * @param message {String|array} The error message.
     * @returns {FieldBuilder}
     */
    error(message) {
        this.config.error = message;
        return this;
    }

    /**
     * Optional event handler for change events
     *
     * @param onChange {function}
     * @returns {FieldBuilder}
     */
    onChange(onChange) {
        this.config.onChange = onChange;
        return this;
    }

    /**
     * Optional event handler for blur events
     *
     * @param onBlur {function}
     * @returns {FieldBuilder}
     */
    onBlur(onBlur) {
        this.config.onBlur = onBlur;
        return this;
    }

    /**
     * A component to render immediately after this field in the form.
     *
     * This allows related content (e.g. extra controls or data derived during an onChange/onBlur handler)
     * to be dynamically inserted below a particular field in the form.
     *
     * Do not use this to add new form fields: this will not work. The new components cannot interact
     * with the form.
     *
     * @param component {object}
     * @returns {FieldBuilder}
     */
    renderAfter(component) {
        this.config.renderAfter = component;
        return this;
    }

    /**
     * Finalizer function to complete current field and return the parent FormBuilder.
     *
     * @returns {FormBuilder}
     */
    and() {
        if (!this.config.validation) {
            const {label} = this.config;
            let ana = "";
            if (!this.config.multiple && typeof label === "string") {
                ana = ["a", "e", "i", "o", "u"].indexOf(label.toLowerCase()[0]) > -1 ? "an" : "a";
            }
            let val = this.validator.yup;

            if (this.validator.required) {
                if ([Types.SELECT, Types.CHECKBOX_GROUP, Types.CHECKBOX, Types.RADIO_GROUP, Types.RADIO].indexOf(this.config.type) > -1) {
                    val = val.required(this.validator.requiredMsg || (`Please select ${ana} ${label}`));
                } else {
                    val = val.required(this.validator.requiredMsg || (`Please enter ${ana} ${label}`));
                }
            }
            if (this.validator.min) {
                if (this.config.type === Types.NUMBER) {
                    val = val.min(this.validator.min, this.validator.minMsg || (`${label} must be at least $\{min}`));
                } else {
                    if (this.config.type === Types.MULTI_TEXT) {
                        val = val.of(yup.string().min(this.validator.min, this.validator.minMsg || (`${label} must have at least $\{min} characters`)));
                    } else {
                        val = val.min(this.validator.min, this.validator.minMsg || (`${label} must have at least $\{min} characters`));
                    }
                }
            }
            if (this.validator.max) {
                if (this.config.type === Types.NUMBER) {
                    val = val.max(this.validator.max, this.validator.maxMsg || (`${label} cannot be more than $\{max}`));
                } else {
                    if (this.config.type === Types.MULTI_TEXT) {
                        val = val.of(yup.string().max(this.validator.max, this.validator.maxMsg || (`${label} cannot be more than $\{max} characters long`)));
                    } else {
                        val = val.max(this.validator.max, this.validator.maxMsg || (`${label} cannot be more than $\{max} characters long`));
                    }
                }
            }
            if (this.validator.email) {
                if (this.config.type === Types.MULTI_TEXT) {
                    val = val.of(yup.string().email(this.validator.emailMsg || (`Please enter a valid ${label}`)));
                } else {
                    val = val.email(this.validator.emailMsg || (`Please enter a valid ${label}`));
                }
            }
            if (this.validator.url) {
                if (this.config.type === Types.MULTI_TEXT) {
                    val = val.of(yup.string().matches(URL_REGEX, this.validator.urlMsg || (`Please enter a valid ${label}`)));
                } else {
                    val = val.matches(URL_REGEX, this.validator.urlMsg || (`Please enter a valid ${label}`));
                }
            }
            if (this.validator.regex) {
                if (this.config.type === Types.MULTI_TEXT) {
                    val = val.of(yup.string().val.matches(this.validator.regex, this.validator.regexMsg || (label + " does not match the required pattern")));
                } else {
                    val = val.matches(this.validator.regex, this.validator.regexMsg || (label + " does not match the required pattern"));
                }
            }

            if (this.config.hidden || this.config.disabled) {
                this.config.validation = yup.lazy((a, b) => {
                    let rules = val;
                    let formData = isObject(b.originalValue) ? b.originalValue : b.from ? b.from[0].value : b.parent ? b.parent : undefined;
                    if (formData && this.config.hidden && this.config.hidden(formData)) {
                        rules = yup.mixed().notRequired();
                    }
                    if (formData && this.config.disabled && this.config.disabled(formData)) {
                        rules = yup.mixed().notRequired();
                    }
                    return rules;
                });
            } else {
                this.config.validation = val;
            }
        }

        return this.formBuilder;
    }

    /**
     * Finalize and build the config object for this field.
     *
     * ** Not intended for direct use **.
     *
     * @returns {Object} The finalized configuration object for this field
     * @private
     */
    _buildField() {
        this.and();
        return this.config;
    }

    /**
     * Convenience method to finalize the current field and start a new field on the parent FormBuilder.
     *
     * This is equivalent to calling builder.and().field(...).
     *
     * @param id {string} A unique identifier (within the scope of this form). Required.
     * @param label {string} A label to be displayed. Defaults to the id formatted into sentence case.
     * @param type {FieldTypes} The input field type. Defaults to TEXT.
     * @returns {FieldBuilder}
     */
    field(id, label, type) {
        return this.and().field(...arguments);
    }

    /**
     * Convenience method to start a new fieldset in the parent form. All subsequent fields added to the form
     * before a call to endFieldSet will be wrapped in this fieldset.
     *
     * @param title The title display in the <legend> of the field set
     * @param description Descriptive text to be displayed at the start of the fieldset
     * @param titleLevel The heading level for the title
     * @returns {FieldSetBuilder}
     */
    startFieldSet(title, description, titleLevel) {
        return this.and().startFieldSet(...arguments);
    }

    /**
     * Convenience method to finalise the current field and close any existing wrapping fieldset.
     *
     * @returns {FormBuilder}
     */
    endFieldSet() {
        return this.and().endFieldSet();
    }

    /**
     * Convenience method to finalise the current field and build the entire form.
     *
     * This is equivalent to calling builder.and().build()
     *
     * @returns {Object} Configuration object for the form, which can then be passed to the <SDSform> component.
     */
    build() {
        return this.and().endFieldSet().build();
    }
}

export {FieldBuilder}

export {Types as FieldTypes};