import React from "react"
import PropTypes from "prop-types"
import "./icons.scss"

/**
 * A convenience component for creating CSS or SVG icons from various 'groups' (SDS, FontAwesome, etc).
 *
 * This assumes that the relevant CSS for the source of the icons (e.g. FontAwesome) has been imported.
 *
 * @example
 * <SDSicon group='sds' name='face'/>
 * <SDSicon group='fas' name='ban'/>
 */
const SDSicon = props => {
    let {group, name, noMargin} = props;

    let icon = null;
    let classes = [noMargin ? "sds-icon" : "sds-icon--with-margin"];

    if (group.toLowerCase() === "sds") {
        switch (name) {
            case "face":
                icon = SDSiconFace();
                break;
            case "organisation":
                icon = SDSiconOrganisation();
                break;
            case "brackets":
                icon = SDSiconBrackets();
                break;
            default:
                icon = null;
        }
        icon = icon ? <span className={classes.join(" ")} aria-hidden>{icon}</span> : null;
    } else if (group.toLowerCase().match(/fa.?/)) {
        if (!name.toLowerCase().startsWith("fa-")) {
            name = "fa-" + name;
        }
        classes.push(group);
        classes.push(name);
        icon = <span className={classes.join(" ")} aria-hidden/>;
    }

    return icon;
};

SDSicon.propTypes = {
    /**
     * The icon group:
     * - sds for Sports Design System icons
     * - fa? for Font Awesome icons (e.g. fas, far, etc)
     */
    group: PropTypes.string.isRequired,
    /**
     * The icon class name. If the groups is fa?, then the fa- prefix on the class name is optional.
     */
    name: PropTypes.string.isRequired,
    /**
     * True to remove the default right margin
     */
    noMargin: PropTypes.bool
};

export default SDSicon;




/**
 * SVG icon of a person's head
 *
 * @example
 * <SDSiconFace/>
 */
const SDSiconFace = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" className="face">
        <path fill="none" d="M0 0h24v24H0V0z"/>
        <path fill="#9ECF7C"
              d="M10.25 13c0 .69-.56 1.25-1.25 1.25S7.75 13.69 7.75 13s.56-1.25 1.25-1.25 1.25.56 1.25 1.25zM15 11.75c-.69 0-1.25.56-1.25 1.25s.56 1.25 1.25 1.25 1.25-.56 1.25-1.25-.56-1.25-1.25-1.25zm7 .25c0 5.52-4.48 10-10 10S2 17.52 2 12 6.48 2 12 2s10 4.48 10 10zM10.66 4.12C12.06 6.44 14.6 8 17.5 8c.46 0 .91-.05 1.34-.12C17.44 5.56 14.9 4 12 4c-.46 0-.91.05-1.34.12zM4.42 9.47c1.71-.97 3.03-2.55 3.66-4.44C6.37 6 5.05 7.58 4.42 9.47zM20 12c0-.78-.12-1.53-.33-2.24-.7.15-1.42.24-2.17.24-3.13 0-5.92-1.44-7.76-3.69C8.69 8.87 6.6 10.88 4 11.86c.01.04 0 .09 0 .14 0 4.41 3.59 8 8 8s8-3.59 8-8z"/>
    </svg>;
};
export {SDSiconFace}

/**
 * SVG icon of an office building
 *
 * @example
 * <SDSiconOrganisation/>
 */
const SDSiconOrganisation = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" className="organisation">
        <path fill="none" d="M0 0h24v24H0V0z"/>
        <path fill="#9ECF7C"
              d="M12 7V3H2v18h20V7H12zM6 19H4v-2h2v2zm0-4H4v-2h2v2zm0-4H4V9h2v2zm0-4H4V5h2v2zm4 12H8v-2h2v2zm0-4H8v-2h2v2zm0-4H8V9h2v2zm0-4H8V5h2v2zm10 12h-8v-2h2v-2h-2v-2h2v-2h-2V9h8v10zm-2-8h-2v2h2v-2zm0 4h-2v2h2v-2z"/>
    </svg>
};
export {SDSiconOrganisation}

/**
 * SVG icon of opening and closing angle brackets (< >)
 *
 * @example
 * <SDSiconBrackets/>
 */
const SDSiconBrackets = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" className="brackets">
        <path fill="none" d="M0 0h24v24H0V0z"/>
        <path fill="#9ECF7C"
              d="M9.4 16.6L4.8 12l4.6-4.6L8 6l-6 6 6 6 1.4-1.4zm5.2 0l4.6-4.6-4.6-4.6L16 6l6 6-6 6-1.4-1.4z"/>
    </svg>
};
export {SDSiconBrackets}