import React, {useCallback, useState} from "react"
import {withAuth} from "../../../../../security/authorisation";
import SDSpanel from "../../../../../components/panel/panel";
import {useHistory} from "react-router";
import {FormBuilder} from "../../../../../components/form/form-builder";
import useErrorHandler from "../../../../../hooks/error-handler";
import {ApplicationService} from "../../../../../service/service";
import SDSform, {FieldTypes} from "../../../../../components/form/form";
import {SDSsubPageTitle} from "../../../../../components/page-title/page-title";
import {debounce} from "lodash";

const RequestApplication = ({orgId}) => {
    const [request] = useState({});
    const {errorHandler} = useErrorHandler();
    const router = useHistory();
    const backRoute = `../../${orgId}`;
    const successRoute = `../../${orgId}/app/`;
    const [checking, setChecking] = useState(false);
    const [duplicates, setDuplicates] = useState(false);

    const dupeCheck = useCallback(debounce((e, app) => {
        setChecking(true);
        if (app.name) {
            ApplicationService.checkForDuplicates(orgId, app.name).then(dupes => {
                setDuplicates(dupes);
            }).finally(() => setChecking(false));
        } else {
            setDuplicates(false);
            setChecking(false);
        }
    }), [setDuplicates, setChecking]);

    const config = new FormBuilder()
        .field("orgId", "", FieldTypes.HIDDEN).value(orgId)

        .field("name")
        .required()
        .value(request.name)
        .hint("A short name to uniquely identify your application")
        .onBlur(dupeCheck)
        .error(duplicates ? "An application already exists with this name" : undefined)
        .max(200)

        .build();

    const submitHandler = (formData, actions) => {
        const req = {...formData};
        req.organisation = {
            id: orgId
        };

        ApplicationService.createApplication(orgId, req)
            .then((app) => router.push(successRoute + app.id))
            .catch((e) => {
                errorHandler(e, "registering your application");
                actions.setSubmitting(false);
            });
    };

    return <div
        className="row request-app px-2 d-md-flex flex-row flex-wrap align-content-stretch justify-content-between align-items-stretch">
        <div className="col-sm-12">
            <SDSsubPageTitle byLine={"Register a new application for your organisation"}
                             backButtonRoute={backRoute}>
                Add a linked application
            </SDSsubPageTitle>
        </div>
        <div className="col-sm-12 align-self-stretch mb-4">
            <SDSpanel title="Provide the following details to create a new application">
                <SDSform config={config}
                         progressIndicator={<span className="fa fa-spinner fa-spin ml-1 mr-1"/>}
                         submitHandler={submitHandler}
                         disableSubmit={duplicates || checking}
                         cancelRoute={backRoute}/>
            </SDSpanel>
        </div>
    </div>;
};

RequestApplication.propTypes = {};

export default withAuth(RequestApplication);