import React, {FC, ReactElement, ReactNode} from "react"
import {Modal as BSModal} from "react-bootstrap";
import Button from "@sportaus-digital/buttons";
import "./modal.scss";

/**
 * Modal dialog component, currently based on the Bootstrap modal.
 *
 * NOTE: This component will only work correctly if you have Bootstrap's CSS in your application.
 * This component DOES NOT load the bootstrap CSS.
 */
export const Modal: FC<ModalProps> = ({
                                          open = false,
                                          title,
                                          size = "md",
                                          cancelAction,
                                          cancelLabel = "Cancel",
                                          okAction,
                                          okLabel = "OK",
                                          showCloseIcon = true,
                                          closeIcon = <span>X</span>,
                                          children
                                      }: ModalProps) => {
    const bsSize: 'sm' | 'lg' | 'xl' | undefined = size === "md" ? undefined : size;
    return <BSModal show={open}
                    onHide={() => {
                    }}
                    size={bsSize}
                    className="sds-modal"
                    dialogClassName="sds-modal__dialog"
                    backdropClassName="sds-modal__backdrop">
        {title && <BSModal.Title className="sds-modal__title">
            <div className="sds-modal__titleContent">
                <h3>{title}</h3>
                {showCloseIcon &&
                <Button as="tertiary" onClick={cancelAction} className="sds-modal__close">{closeIcon}</Button>}
            </div>
        </BSModal.Title>}
        <BSModal.Body>
            <div className="sds-modal__body">{children}</div>
        </BSModal.Body>
        <BSModal.Footer>
            <Button className="sds-modal__cancel-btn" as="tertiary"
                    onClick={cancelAction}>{cancelLabel}</Button>
            <Button className="sds-modal__ok-btn" onClick={okAction}>{okLabel}</Button>
        </BSModal.Footer>
    </BSModal>;
};

export interface ModalProps {
    /**
     * Optional title for the modal
     */
    title?: string,
    /**
     * The size of the modal
     *
     * @default md
     */
    size?: "sm" | "md" | "lg",
    /**
     * The label of the ok button of the modal
     *
     * @default OK
     */
    okLabel?: string,
    /**
     * The label of the cancel button
     *
     * @default Cancel
     */
    cancelLabel?: string,
    /**
     * The action to perform when the ok button is clicked
     */
    okAction?: () => void,
    /**
     * The action to perform when the cancel button is clicked
     */
    cancelAction?: () => void,
    /**
     * State variable to control whether the modal is open or not
     *
     * @default false
     */
    open?: boolean
    /**
     * The icon to use for the close control
     *
     * @default <span>X</span>
     */
    closeIcon?: ReactElement,
    /**
     * True to show a close icon in the top right corner. The close icon will execute the cancelAction.
     *
     * @default true
     */
    showCloseIcon?: boolean,
    /**
     * The content of the modal dialog
     */
    children: ReactNode
}

export default Modal;