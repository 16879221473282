import React, {useEffect, useState} from "react"
import {Actions, ProtectedButton} from "../../../../../security/authorisation";
import SDSpanel from "../../../../../components/panel/panel";
import {useRouteMatch} from "react-router";
import {OAuthClientService} from "../../../../../service/service";
import useErrorHandler from "../../../../../hooks/error-handler";
import Card from "@sportaus-digital/card";
import SDSfieldList, {SDSfieldListItem} from "../../../../../components/field-list/field-list";
import {formatUTCISODateTime} from "../../../../../utils/date-utils";
import {useEnum} from "../../../../../utils/enums";
import {OAuthResourceType} from "../../../../../constants/oauth-resource-types";

const OrgOAuthClients = ({orgId, appId}) => {
    const {url} = useRouteMatch();
    const [clients, setClients] = useState([]);
    const [loading, setLoading] = useState(true);
    const {errorHandler} = useErrorHandler();

    useEffect(() => {
        OAuthClientService.getClientsForApplication(orgId, appId)
            .then(c => setClients(c))
            .catch((e) => errorHandler(e, "retrieving your resources"))
            .finally(() => setLoading(false))
    }, [orgId, appId, setClients, errorHandler, setLoading]);

    return <SDSpanel title="OAuth resources"
                     action={<ProtectedButton action={Actions.ORG_APPLICATIONS_CREATE_CLIENT}
                                              orgId={orgId}
                                              className="sds-btn--block"
                                              route={`${url}/client/create`}
                                              as="tertiary">Create a new resource</ProtectedButton>}>
        <div>
            <div className="row panel-container">
                {!loading && clients && clients.map(c => <Client client={c} appId={appId} orgId={orgId} key={c.id}/>)}
            </div>
        </div>
    </SDSpanel>;
};

const Client = ({client, orgId}) => {
    let {url} = useRouteMatch();
    const getType = useEnum(OAuthResourceType);

    const action = <ProtectedButton action={Actions.ORG_APPLICATIONS_UPDATE_CLIENT}
                                    orgId={orgId}
                                    route={{
                                        pathname: `${url}/client/${client.id}`,
                                        state: {oauthClient: client}
                                    }}
                                    as="primary">
        Manage resource
    </ProtectedButton>;

    return <div className=" col-lg-4 col-md-6 col-sm-12">
        <Card title={client.name} className="oauth-card" actions={[action]}>
            <SDSfieldList>
                <SDSfieldListItem label="Type"
                                  value={getType(client.resourceType)}
                                  labelWidth={3}
                                  valueWidth={9}/>
                <SDSfieldListItem label="Resource"
                                  value={client.clientId}
                                  labelWidth={3}
                                  valueWidth={9}/>
                <SDSfieldListItem label="Sport"
                                  value={client.provider.sportName}
                                  labelWidth={3}
                                  valueWidth={9}/>
                {client.baseUrl && <SDSfieldListItem label="Base URL"
                                                     value={client.baseUrl}
                                                     labelWidth={3}
                                                     valueWidth={9}/>}
                <SDSfieldListItem label="Created"
                                  value={formatUTCISODateTime(client.createdDate)}
                                  labelWidth={3}
                                  valueWidth={9}/>
                <SDSfieldListItem label="Updated"
                                  value={formatUTCISODateTime(client.lastUpdatedDate)}
                                  labelWidth={3}
                                  valueWidth={9}/>
            </SDSfieldList>
        </Card>
    </div>;
};

OrgOAuthClients.propTypes = {};

export default OrgOAuthClients;