import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import {AUerrorText, AUformGroup, AUhintText} from '@gov.au/form'
import "./field.scss"
import {useField} from "formik";
import {isArray, isFunction, omit, orderBy, replace, trim, uniq, values} from "lodash"
import {
    Autocomplete,
    Checkbox,
    RadioButton,
    Select,
    TextArea,
    TextField,
    MultiValueTextField,
    Label
} from "@sportaus-digital/form-fields";

/**
 * A generic form field component that will render a fully-formatted and validatable field of the
 * specified type.
 *
 * This component depends on the Formik form library and the Yup validation library to provide
 * data binding and validation.
 *
 * The individual fields are from the Australian Government Digital Transformation Agency (DTA) Design System.
 *
 * @see https://jaredpalmer.com/formik/docs/tutorial
 * @see https://designsystem.gov.au/components/
 * @see SDSform
 *
 * @requires "formik"
 * @requires "yup"
 * @requires "lodash"
 * @requires "@gov.au/form"
 * @requires "@gov.au/text-inputs"
 * @requires "@gov.au/select"
 * @requires "@gov.au/control-input"
 *
 * @example
 * <SDSfield type="email" label="My email" hint="A hint"/>
 * <SDSfield type="email" label="My email" hint="A hint"/>
 */
const SDSfield = props => {
    let formikField = {};
    let meta = {};
    let formikHelpers = {};
    try {
        const [ff, m, fh] = useField(props);
        formikField = ff;
        meta = m;
        formikHelpers = fh;
    } catch (e) {
        console.warn("Formik is not available");
    }

    const {name, type, label, hint, emptyOption, mapper, options, initialOptions, onChange, onBlur, parentForm, renderAfter} = props;

    const [opts, setOpts] = useState({items: [], loading: !!props.options});

    const labelVal = isFunction(label) ? label() : label;

    useEffect(() => {
        if (options) {
            if (isFunction(options)) {
                options().then(opts => {
                    if (isFunction(mapper)) {
                        opts = opts.map(mapper);
                    }
                    opts = orderBy(opts, "label");
                    setOpts({loading: false, items: emptyOption ? [emptyOption].concat(opts) : opts});
                }).catch(() => {
                    setOpts({loading: false, items: [], error: "Failed to load items for " + labelVal});
                });
            } else {
                setOpts({loading: false, items: emptyOption ? [emptyOption].concat(options) : options});
            }
        }
    }, [options, emptyOption, mapper, labelVal]);

    let hidden = invokeCallback(props.hidden, props.formData);
    if (hidden) {
        return null;
    }

    let disabled = invokeCallback(props.disabled, props.formData) || opts.loading;
    let input = getField(type, name, disabled, onChange, onBlur, formikField, props, opts.items, initialOptions, formikHelpers, props.arrayHelpers, props.formData);

    let error = meta && (meta.touched || (parentForm && parentForm.submitCount > 0)) && meta.error;
    let warnings = props.warning ? isArray(props.warning) ? props.warning.length > 0 ? props.warning : undefined : [props.warning] : undefined;
    let otherErrors = props.error ? isArray(props.error) ? props.error.length > 0 ? props.error : undefined : [props.error] : undefined;
    let invalid = warnings || error || otherErrors;
    let formProps = {};
    if (invalid) {
        formProps.status = "invalid";
    }

    let wrapperClasses = props.wrapperClasses ? isArray(props.wrapperClasses) ? props.wrapperClasses : [props.wrapperClasses] : [];
    wrapperClasses.push("sds-field");
    if (disabled) {
        wrapperClasses.push("sds-field--disabled");
    }

    if (warnings && !error) {
        wrapperClasses.push("sds-field--warning");
    }

    const parseError = (error) => {
        if (isArray(error)) {
            error = uniq(error.filter(s => trim(s).length > 0)).join(", ");
        }
        return error;
    };

    return <>
        <AUformGroup className={toClasses(wrapperClasses)}
                     {...formProps}>
            {type !== Types.HIDDEN
            && type !== Types.CHECKBOX
            && label
            && <Label htmlFor={name}
                         label={labelVal}
                         className={"sds-field__label " + toClasses(props.labelClasses)}
                         required={props.required}/>}
            {opts.loading && props.progressIndicator}
            {input}
            {hint && <SDShint hint={hint} className={props.hintClasses}/>}
            {invalid && meta.error && <AUerrorText className={"sds-field__error " + toClasses(props.errorClasses)}
                                                   text={parseError(meta.error)}/>}
            {opts.error && <AUerrorText className="sds-field__error" text={parseError(opts.error)}/>}
            {warnings && warnings.map(w => <AUerrorText className="sds-field__warning" text={parseError(w)} key={w}/>)}
            {otherErrors && otherErrors.map(e => <AUerrorText className="sds-field__error" text={parseError(e)}
                                                              key={e}/>)}
        </AUformGroup>
        {renderAfter}
    </>
};

export const SDShint = ({hint, className}) => {
    return <AUhintText text={hint} className={"sds-field__hint " + toClasses(className)}/>;
};

SDShint.propTypes = {};

/**
 * A set of supported input field types
 *
 * @type {{NUMBER: string, CHECKBOX: string, TEXTAREA: string, CHECKBOX_GROUP: string, RADIO: string, label string, RADIO_GROUP: string, EMAIL: string, SELECT: string, AUTOCOMPLETE: string, MULTI_TEXT: string}}
 */
const Types = {
    TEXT: "text",
    MULTI_TEXT: "multitext",
    EMAIL: "email",
    NUMBER: "number",
    TEXTAREA: "textarea",
    CHECKBOX: "checkbox",
    CHECKBOX_GROUP: "checkboxgroup",
    RADIO: "radio",
    RADIO_GROUP: "radiogroup",
    SELECT: "select",
    AUTOCOMPLETE: "autocomplete",
    HIDDEN: "hidden"
};
export {Types}

const invokeCallback = (callback, formData) => {
    let result;
    if (isFunction(callback)) {
        result = callback(formData);
    }
    return result;
};

const toClasses = (c) => {
    return (isArray(c) ? c.join(" ") : c) || "";
};

const getField = (type, name, disabled, onChangeOpt, onBlurOpt, formikField, allProps, options, initialOptions, formikHelpers, arrayHelpers, formData) => {
    let field;

    let id = replace(name, /[ -.]/, "_");
    let props = removeUnsafeProps(allProps);
    let inputClasses = toClasses(allProps.inputClasses);
    let onChange = onChangeOpt ? (args) => {
        formikField.onChange(args);
        if (arrayHelpers) {
            onChangeOpt(args, arrayHelpers, formData);
        } else {
            onChangeOpt(args);
        }
    } : formikField.onChange;
    let onBlur = onBlurOpt ? (...args) => {
        formikField.onBlur(...args);
        onBlurOpt(...args, formData);
    } : formikField.onBlur;

    switch (type.toLowerCase()) {
        case Types.TEXT:
        case Types.EMAIL:
            field = <TextField {...props}
                               {...formikField}
                               id={id}
                               disabled={disabled}
                               className={inputClasses}
                               onChange={onChange}
                               onBlur={onBlur}
                               inline={props.inline}/>;
            break;
        case Types.MULTI_TEXT:
            field = <MultiValueTextField {...props}
                                         {...formikField}
                                         id={id}
                                         disabled={disabled}
                                         className={inputClasses}
                                         onBlur={(e) => {
                                             formikHelpers.setTouched(true);
                                             onBlurOpt && onBlurOpt(e, formData)
                                         }}
                                         onChange={(option) => {
                                             formikHelpers.setValue(option ? option.map(item => item.value) : option.value);
                                             if (onChangeOpt) {
                                                 if (arrayHelpers) {
                                                     onChangeOpt(option, arrayHelpers, formData);
                                                 } else {
                                                     onChangeOpt(option);
                                                 }
                                             }
                                         }}
                                         inline={props.inline}/>;
            break;
        case Types.NUMBER:
            field = <TextField {...props}
                               {...formikField}
                               id={id}
                               disabled={disabled}
                               className={inputClasses}
                               onChange={onChange}
                               onBlur={onBlur}
                               inline={props.inline}
                               number/>;
            break;
        case Types.TEXTAREA:
            field = <TextArea {...props}
                              {...formikField}
                              id={id}
                              as="textarea"
                              disabled={disabled}
                              className={inputClasses}
                              onChange={onChange}
                              onBlur={onBlur}/>;
            break;
        case Types.RADIO:
            field = <RadioButton {...props}
                                 {...formikField}
                                 id={id}
                                 value={props.value}
                                 className={inputClasses}
                                 onChange={onChange}
                                 onBlur={onBlur}
                                 disabled={disabled}
                                 name={name}/>;
            break;
        case Types.RADIO_GROUP:
            field = options.map((o, idx) => {
                return <RadioButton {...props}
                                    {...formikField}
                                    inputClasses={(props.inputClasses || "") + "sds-radio-group"}
                                    id={id + "_" + idx}
                                    key={name + "_" + idx}
                                    name={name}
                                    className={inputClasses}
                                    value={o.value}
                                    defaultChecked={o.checked}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    label={o.label}
                                    disabled={disabled}/>
            });
            break;
        case Types.CHECKBOX:
            field = <Checkbox {...props}
                              {...formikField}
                              id={id}
                              className={inputClasses}
                              onChange={onChange}
                              onBlur={onBlur}
                              disabled={disabled}
                              name={name}/>;
            break;
        case Types.CHECKBOX_GROUP:
            field = options.map((o, idx) => {
                return <Checkbox {...props}
                                 {...formikField}
                                 id={id + "_" + idx}
                                 key={name + "_" + idx}
                                 name={name}
                                 className={inputClasses}
                                 defaultChecked={o.checked}
                                 disabled={disabled}
                                 onChange={onChange}
                                 onBlur={onBlur}
                                 label={o.label}/>
            });
            break;
        case Types.SELECT:
            if (props.multiple) {
                inputClasses += " sds-field__multi-select";
            }

            let selectValue = props.multiple ?
                orderBy(options.filter(option => formikField.value && formikField.value.indexOf(option.value) > -1), "label") :
                options.find((option) => option.value === formikField.value);

            if (props.autoSelectSingleOption && options.length === 1) {
                selectValue = options[0];
                formData[name] = selectValue.value;
            }

            field = <Select {...props}
                            {...formikField}
                            id={id}
                            options={options}
                            disabled={disabled}
                            name={name}
                            multiple={props.multiple}
                            inputClasses={inputClasses}
                            value={selectValue}
                            onBlur={(e) => {
                                formikHelpers.setTouched(true);
                                onBlurOpt && onBlurOpt(e, formData)
                            }}
                            onChange={(option) => {
                                formikHelpers.setValue(option ? props.multiple ? option.map(item => item.value) : option.value : "");
                                if (onChangeOpt) {
                                    if (arrayHelpers) {
                                        onChangeOpt(option, arrayHelpers, formData);
                                    } else {
                                        onChangeOpt(option);
                                    }
                                }
                            }}/>;
            break;
        case Types.AUTOCOMPLETE:
            if (props.multiple) {
                inputClasses += " sds-field__multi-select";
            }

            let autocompleteValue = initialOptions ? props.multiple ?
                orderBy(initialOptions.filter(option => formikField.value && formikField.value.indexOf(option.value) > -1), "label") :
                initialOptions.find((option) => option.value === formikField.value) : undefined;

            field = <Autocomplete {...props}
                                  {...formikField}
                                  id={id}
                                  autoCompleteSearch={props.autoCompleteSearch}
                                  disabled={disabled}
                                  name={name}
                                  initialOptions={initialOptions}
                                  multiple={props.multiple}
                                  inputClasses={inputClasses}
                                  value={autocompleteValue}
                                  onBlur={(e) => {
                                      formikHelpers.setTouched(true);
                                      onBlurOpt && onBlurOpt(e, formData)
                                  }}
                                  onChange={(option) => {
                                      formikHelpers.setValue(option ? props.multiple ? option.map(item => item.value) : option.value : "");
                                      if (onChangeOpt) {
                                          if (arrayHelpers) {
                                              onChangeOpt(option, arrayHelpers, formData);
                                          } else {
                                              onChangeOpt(option);
                                          }
                                      }
                                  }}/>;
            break;
        case Types.HIDDEN:
            field = <input {...props} type="hidden" id={name} name={name}/>;
            break;
        default:
            field = null;
    }

    return field;
};

const removeUnsafeProps = (allProps) => {
    return omit(allProps, "options", "required", "emptyOption", "progressIndicator",
        "mapper", "disabled", "hidden", "formData", "inputClasses", "errorClasses",
        "labelClasses", "hintClasses", "wrapperClasses", "onChange", "parentForm",
        "arrayHelpers", "renderAfter", "validationSchema");
};

SDSfield.propTypes = {
    /**
     * Unique identifier for this field within the scope of the parent form
     */
    name: PropTypes.string.isRequired,
    /**
     * Field type
     */
    type: PropTypes.oneOf(values(Types)),
    /**
     * The field label
     */
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.element]).isRequired,
    /**
     * An optional hint to be displayed near the field
     */
    hint: PropTypes.string,
    /**
     * Options for checkboxgroup, radiogroup and select fields.
     *
     * For select fields, each option must be {label: 'aa', value: 'aa'}.
     * For checkboxgroup and radiogroup, each option must be {label 'aa', checked: false}
     *
     * A function returning a Promise can be used. The promise must resolve to an array of the correct type,
     * unless a mapper has also been provided
     *
     * Only relevant for types SELECT, CHECKBOX_GROUP and RADIO_GROUP.
     */
    options: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
    /**
     * A mapping function to convert the result of the 'options; callback to an array of the appropriate objects.
     *
     * Only relevant for types SELECT, CHECKBOX_GROUP and RADIO_GROUP.
     */
    mapper: PropTypes.func,
    /**
     * Adds an empty option to the options list.
     *
     * Only relevant for SELECT fields.
     */
    emptyOption: PropTypes.object,
    /**
     * An optional component to be displayed after the label of the field to indicate an action is in progress
     * (such as invoking the options callback)
     */
    progressIndicator: PropTypes.element,
    /**
     * Optional CSS classes to add to the input field
     */
    inputClasses: PropTypes.string,
    /**
     * Optional CSS classes to add to the input label
     */
    labelClasses: PropTypes.string,
    /**
     * Optional CSS classes to add to the field hint
     */
    hintClasses: PropTypes.string,
    /**
     * Optional CSS classes to add to the validation error message
     */
    errorClasses: PropTypes.string
};

SDSfield.defaultProps = {
    type: "text"
};

export default SDSfield;