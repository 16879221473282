import React, {useCallback, useEffect, useState} from "react"
import {Actions, Protected, ProtectedButton, withAuth} from "../../../../../security/authorisation";
import SDSpanel from "../../../../../components/panel/panel";
import {useHistory, useRouteMatch} from "react-router";
import {FormBuilder} from "../../../../../components/form/form-builder";
import useErrorHandler from "../../../../../hooks/error-handler";
import {ApplicationService, GroupInvitationService, OrganisationService} from "../../../../../service/service";
import SDSform, {FieldTypes} from "../../../../../components/form/form";
import {SDSsubPageTitle} from "../../../../../components/page-title/page-title";
import ConfirmBtn from "@sportaus-digital/confirm-btn";
import SDSfieldList, {SDSfieldListItem} from "../../../../../components/field-list/field-list";
import {formatUTCISODateTime} from "../../../../../utils/date-utils";
import OrgOAuthClients from "./org-oauth-clients";
import OrgWebhooks from "./org-webhooks";
import {debounce} from "lodash";
import SDSicon from "../../../../../components/icons/icons";
import GroupsPersons from "../groups/groups-persons";

const UpdateApplication = ({orgId, appId, application}) => {
    const [members, setMembers] = useState([]);
    const [organisation, setOrganisation] = useState({});
    const [mode, setMode] = useState("view");
    const [app, setApp] = useState({});
    const [loading, setLoading] = useState(true);
    const {errorHandler} = useErrorHandler();
    const router = useHistory();
    const backRoute = `../../${orgId}`;
    const [checking, setChecking] = useState(false);
    const [duplicates, setDuplicates] = useState(false);
    const {url} = useRouteMatch();

    useEffect(() => {
        GroupInvitationService.listMembers(orgId, appId).then(data => {
            if (data === undefined || data == null) {
                setMembers([]);
            } else {
                setMembers(data);
            }
        }).catch((e) => {
            errorHandler(e, "retrieving memberships");
        });
    }, [orgId, appId, setMembers, errorHandler]);

    useEffect(() => {
        OrganisationService.getOrganisation(orgId).then(org => {
            setOrganisation(org);
        }).catch((e) => {
            errorHandler(e, "retrieving the organisation");
        });
    }, [orgId, setOrganisation, errorHandler]);

    useEffect(() => {
        if (application) {
            setApp(application);
            setLoading(false);
        } else {
            ApplicationService.getApplication(orgId, appId)
                .then(a => {
                    setApp(a);
                    setLoading(false);
                })
                .catch((e) => errorHandler(e, "retrieving the application details"));
        }
    }, [orgId, appId, application, setApp, errorHandler, setLoading]);

    const memberRemovedHandler = (id) => {
        setMembers(members.filter(m => m.id !== id));
    };

    const dupeCheck = useCallback(debounce((e, application) => {
        setChecking(true);
        if (application.name) {
            ApplicationService.checkForDuplicates(orgId, application.name, appId).then(dupes => {
                setDuplicates(dupes);
            }).finally(() => setChecking(false));
        } else {
            setDuplicates(false);
            setChecking(false);
        }
    }), [setDuplicates, setChecking]);

    const config = new FormBuilder()
        .field("orgId", "", FieldTypes.HIDDEN).value(orgId)
        .field("id", "", FieldTypes.HIDDEN).value(appId)

        .field("name")
        .required()
        .value(app.name)
        .hint("A short name to uniquely identify your application")
        .onBlur(dupeCheck)
        .error(duplicates ? "An application already exists with this name" : undefined)
        .max(200)

        .build();

    const submitHandler = (formData, actions) => {
        ApplicationService.updateApplication(orgId, appId, formData)
            .then((a) => {
                setApp(a);
                actions.setSubmitting(false);
                setMode("view");
            })
            .catch((e) =>{
                actions.setSubmitting(false);
                errorHandler(e, "updating your application");
            });
    };

    const deleteHandler = () => {
        ApplicationService.deleteApplication(orgId, appId)
            .then(() => router.push(backRoute))
            .catch((e) => errorHandler(e, "deleting your application"));
    };

    const changeMode = () => {
        setMode(mode === "view" ? "edit" : "view")
    };

    const removeAction = <>
        <Protected action={Actions.ORG_APPLICATIONS_DELETE_APP} orgId={orgId}>
            <ConfirmBtn as={"secondary"}
                           disabled={loading}
                           onClick={() => deleteHandler()}>
                Remove application
            </ConfirmBtn>
        </Protected>
    </>;

    const editAction = mode === "view" ? <div className="text-right m-4">
        <ProtectedButton action={Actions.ORG_APPLICATIONS_UPDATE_APP}
                         orgId={orgId}
                         as={"secondary"}
                         disabled={loading}
                         onClick={changeMode}>
            Update application
        </ProtectedButton>
    </div> : null;

    return <div className="row">
        <div className="col-sm-12">
            <SDSsubPageTitle backButtonRoute={backRoute} byLine="Manage details and people for this application">
                {app.name}
            </SDSsubPageTitle>
        </div>
        <div className="col-sm-12">
            <SDSpanel title="Application details" action={editAction}>
                {
                    mode === "view"
                    && !loading
                    && <SDSfieldList>
                        <SDSfieldListItem label="Name"
                                          value={app.name}
                                          labelWidth={3}
                                          valueWidth={9}/>
                        <SDSfieldListItem label="Application ID"
                                        value={app.externalId}
                                        labelWidth={3}
                                        valueWidth={9}/>
                        <SDSfieldListItem label="Created"
                                          value={formatUTCISODateTime(app.createdDate)}
                                          labelWidth={3}
                                          valueWidth={9}/>
                        <SDSfieldListItem label="Updated"
                                          value={formatUTCISODateTime(app.lastUpdatedDate)}
                                          labelWidth={3}
                                          valueWidth={9}/>
                    </SDSfieldList>
                }
                {
                    mode === "edit"
                    && !loading
                    && <SDSform config={config}
                                progressIndicator={<span className="fa fa-spinner fa-spin ml-1 mr-1"/>}
                                disableSubmit={loading || duplicates || checking}
                                submitHandler={submitHandler}
                                cancelHandler={() => setMode("view")}
                                nonFormActions={removeAction}/>
                }
            </SDSpanel>
        </div>

        <Protected action={[Actions.GROUP_MANAGEMENT_ORG, Actions.GROUP_MANAGEMENT_APP]} orgId={orgId} appId={appId}>
            <div className="col-sm-12 align-self-stretch">
                <SDSpanel title={
                    <>
                        People
                        <span className="float-right">
                            <ProtectedButton action={[Actions.ORG_APPLICATIONS_UPDATE_APP, Actions.GROUP_MANAGEMENT_ORG, Actions.GROUP_MANAGEMENT_APP]}
                                            orgId={organisation.id}
                                            appId={appId}
                                            route={`${url}/groups/`}
                                            as="primary"
                                            className="mt-n2">
                                Invite a person
                            </ProtectedButton>
                        </span>
                    </>
                } titleLevel={2}
                          className="people-and-invitations"
                          action={<ProtectedButton action={[Actions.GROUP_MANAGEMENT_ORG, Actions.GROUP_MANAGEMENT_APP]}
                                                   orgId={organisation.id}
                                                   appId={appId}
                                                   className="sds-btn--block"
                                                   route={`${url}/groups/`}
                                                   icon={<SDSicon group="fa" name="plus"/>}>
                              Invite a person</ProtectedButton>}>
                    {organisation.id && <GroupsPersons
                      orgId={organisation.id}
                      appId={appId}
                      peopleAndInvitations={members}
                      removedHandler={memberRemovedHandler}/>}
                </SDSpanel>
            </div>
        </Protected>

        <Protected action={Actions.ORG_APPLICATIONS_LIST_CLIENT} orgId={orgId}>
            <div className="col-sm-12 align-self-stretch">
                <OrgOAuthClients orgId={orgId} appId={appId}/>
            </div>
        </Protected>
        <Protected action={Actions.ORG_APPLICATIONS_LIST_WEBHOOK} orgId={orgId}>
            <div className="col-sm-12 align-self-stretch">
                <OrgWebhooks orgId={orgId} appId={appId}/>
            </div>
        </Protected>
    </div>;
};

UpdateApplication.propTypes = {};

export default withAuth(UpdateApplication);