import React from "react"
import PropTypes from "prop-types"
import "./field-set.scss"

const SDSfieldSet = ({title, titleLevel, description, children}) => {
    const Header = "h" + titleLevel;

    return <fieldset className="sds-fieldset">
        <legend className="sds-fieldset__legend">
            <Header className="sds-fieldset__title">{title}</Header>
            {description && <p className="sds-fieldset__description">{description}</p>}
        </legend>
        {children}
    </fieldset>;
};

SDSfieldSet.propTypes = {
    /**
     * The field set's title
     */
    title: PropTypes.string.isRequired,
    /**
     * The h-level of the title. Defaults to h2.
     */
    titleLevel: PropTypes.number,
    /**
     * Optional descriptive text for the field set.
     */
    description: PropTypes.string
};

SDSfieldSet.defaultProps = {
    titleLevel: 3
};

export default SDSfieldSet;