import React, {FC, ReactElement, ReactNode, useEffect, useState} from "react"
import {
    AUtableBody,
    AUtableCaption,
    AUtableCell,
    AUtableHead,
    AUtableResponsiveWrapper,
    AUtableRow
} from "@gov.au/table"
import {
    filter as _filter,
    debounce,
    forOwn,
    isEqual,
    isFunction,
    keys,
    orderBy,
    some,
    startsWith,
    toLower,
    trim
} from "lodash"
import Pagination from "@sportaus-digital/pagination";
import "./data-table.scss"
import Alert from "@sportaus-digital/alert";
import Button from "@sportaus-digital/buttons";
import {classes} from "@sportaus-digital/core/src";

/**
 * This table component extends the DTA Design System AUtable component to add sorting, filtering, paging
 * and ajax data retrieval.
 */
export const DataTable: FC<DataTableProps> = ({
                                                  data,
                                                  headers,
                                                  caption,
                                                  striped = false,
                                                  pageable = true,
                                                  pageSize = 10,
                                                  defaultSort,
                                                  spinner,
                                                  refreshTrigger,
                                                  emptyMessage,
                                                  emptyMessageSeverity,
                                                  highlightOnHover = true
                                              }: DataTableProps) => {
    let startPage = 0;

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [tableData, setTableData] = useState<DataObject>({content: [], totalElements: 0, pageSize: pageSize});
    const [page, setPage] = useState(startPage);
    let sortable = some(headers, h => h.sortable);
    const [sortBy, setSortBy] = useState({key: sortable ? defaultSort || "" : "", asc: true});
    const [filters, setFilters] = useState({});

    useEffect(() => {
        if (isFunction(data)) {
            setLoading(true);
            data(page, pageSize, sortBy.key, sortBy.asc, filters, refreshTrigger)
                .then(d => {
                    setTableData(d);
                    setLoading(false);
                })
                .catch(() => {
                    setError("Failed to fetch data");
                    setLoading(false);
                });
        } else {
            let totalElements = data.length;
            let newData = orderBy(data.slice(0), [(r: any) => toLower(r[sortBy.key])], sortBy.asc ? "asc" : "desc");
            if (filters) {
                newData = _filter(newData, (r: any) => {
                    let match = true;
                    forOwn(filters, (value, key) => {
                        match = match && startsWith(toLower(r[key]), value);
                    });
                    return match;
                });
                totalElements = newData.length;
            }
            if (pageable) {
                newData = newData.slice((page || 0) * pageSize, (page || 0) * pageSize + pageSize);
            }
            setTableData({
                content: newData,
                totalElements: totalElements,
                pageSize: pageable ? pageSize : totalElements
            });
            setLoading(false);
        }
    }, [data, pageable, page, pageSize, sortBy, filters, refreshTrigger, setLoading]);

    const changePage = (page: number) => {
        setPage(page);
    };

    const sort = (column: string) => {
        let newSort = {...sortBy};
        if (newSort.key === column) {
            if (newSort.asc) {
                newSort.asc = false;
            } else {
                newSort = {key: "", asc: false};
            }
        } else {
            newSort.key = column;
            newSort.asc = true;
        }

        setSortBy(newSort);
    };

    const filter = debounce((key: string, value: any) => {
        value = trim(value);
        let newFilters: any = {...filters};
        if (newFilters[key] && !trim(value)) {
            delete newFilters[key];
        } else if (trim(value)) {
            newFilters[key] = toLower(value);
        }
        if (!isEqual(newFilters, filters)) {
            setFilters(newFilters);
        }
    }, 250);

    const isEmpty = !loading && (!tableData || !tableData.content || tableData.content.length === 0);
    const isFiltered = keys(filters).length > 0;

    return <AUtableResponsiveWrapper>
        <div className="sds-data-table">
            {error && <Alert severity="error" className="sds-data-table__error">{error}</Alert>}
            {isEmpty && emptyMessage && !isFiltered && (emptyMessageSeverity ?
                <Alert severity={emptyMessageSeverity}>{emptyMessage}</Alert> : emptyMessage)}
            {(!isEmpty || !emptyMessage || isFiltered) &&
            <>
                <div className="sds-data-table__spinner">{loading && spinner}</div>
                <table className={"sds-data-table__table au-table" + (striped ? " au-table--striped" : "")}>
                    {caption && <AUtableCaption tableCaption={caption}/>}
                    <AUtableHead>
                        <AUtableRow>
                            {headers.map((h) => {
                                let clazzes = classes(h.className, []);

                                return <TableHeader type={h.type || "text"}
                                                    key={h.key || h.title}
                                                    width={h.width}
                                                    className={clazzes}>
                                    {
                                        h.sortable ?
                                            <Button as="tertiary"
                                                    className="sds-data-table__header-sort"
                                                    onClick={() => sort(h.sortKey || h.key)}>
                                                {h.title}
                                                <span
                                                    className={"sds-data-table__header--sortable " + (sortBy.key === (h.sortKey || h.key) ? sortBy.asc ? " sds-data-table__header--asc" : " sds-data-table__header--desc" : "")}/>
                                            </Button>
                                            : h.title
                                    }
                                    {
                                        h.filterable && <>
                                            <label className="sds-data-table__filter-label"
                                                   htmlFor={h.key + "_filter"}>Filter {h.title}</label>
                                            <input type="text"
                                                   id={h.key + "_filter"}
                                                   className="sds-data-table__filter"
                                                   name={h.key + "_filter"}
                                                   onChange={(e: any) => {
                                                       e.preventDefault();
                                                       filter(h.key, e.target.value);
                                                   }}/>
                                        </>
                                    }
                                </TableHeader>
                            })}
                        </AUtableRow>
                    </AUtableHead>
                    <AUtableBody>{
                        tableData.content.map((row: any, rowIndex: number) =>
                            <AUtableRow key={rowIndex}
                                        className={highlightOnHover ? "sds-data-table__row--with-hover" : ""}>{
                                headers.map((header, columnIndex) => {
                                    // check to render first cell in rows as a header or not
                                    return <AUtableCell
                                        key={columnIndex}
                                        data={row[header.key] ? row[header.key] : ''}
                                        type={header.type}
                                        render={header.render ? header.render(row[header.key], row) : null}/>
                                })
                            }</AUtableRow>
                        )
                    }</AUtableBody>
                </table>

                {pageable &&
                <Pagination onPageChange={changePage}
                            totalRecords={tableData.totalElements}
                            pageSize={pageSize}/>}
            </>}
        </div>
    </AUtableResponsiveWrapper>;
};

const TableHeader: FC<TableHeaderProps> = ({
                                               title,
                                               type = "text",
                                               width,
                                               className,
                                               children,
                                               ...attributeOptions
                                           }: TableHeaderProps) => {
    let classes = "sds-data-table__header au-table__header";
    if (type === "numeric") {
        classes += " au-table__header--numeric";
    }
    const rest = {...attributeOptions, width: `${width}%`};
    return <th className={classes}
               {...rest}>
        {children || title}
    </th>;
};

export interface TableHeaderProps {
    title?: string,
    type?: "numeric" | "text",
    width?: number | string,
    className?: string | Array<string> | undefined,
    children?: ReactNode
}

export interface DataObject {
    /**
     * Array of record objects with a structure matching the headers definitions
     * (same as when data is just an object array)
     */
    content: Array<object>,
    /**
     * Total number of records in the data set
     */
    totalElements: number,
    /**
     * The current page (if using pagination)
     */
    page?: number,
    /**
     * The page size (if using pagination)
     *
     * @default 10
     */
    pageSize?: number
}

export type HeaderDef = {
    /**
     * The column header
     */
    title: string,
    /**
     * The object property to render
     */
    key: string,
    /**
     * enables/disables sorting by this column
     *
     * @default false
     */
    sortable?: boolean,
    /**
     * The object property to sort by. Defaults to the key if not provided.
     */
    sortKey?: string,
    /**
     * Enables/disables filtering by this column
     *
     * @default false
     */
    filterable?: boolean,
    /**
     * An optional render function to control what is rendered in each cell
     * @param row the row object
     * @param col the column value
     */
    render?: (row: object, col: object) => void
} & TableHeaderProps;

export interface DataTableProps {
    /**
     * Either an array of objects, or a function returning a Promise which resolves to an object with the structure
     * {
     *     content: [], // array of record objects with a structure matching the headers definitions (same as when data is just an object array)
     *     totalElements: 0, // total number of content in the data set
     *     page: 0, // the current page (if using pagination)
     *     size: 10 // the page size (if using pagination)
     * }
     *
     * The function must accept the following arguments
     * page, pageSize, sortBy.key, sortBy.asc, filters, refreshTrigger
     *
     * The objects must match the structure defined by the headers parameter.
     */
    data: Array<object> | ((page: number, pageSize: number, sortByKey: string, sortByAsc: boolean, filters: object | undefined, refreshTrigger: any) => Promise<DataObject>),
    /**
     * Array of header definitions to define the structure of the data table
     */
    headers: Array<HeaderDef>,
    /**
     * True/false to enable or disable pagination
     *
     * @default true
     */
    pageable?: boolean,
    /**
     * The page size to use when pagination is enabled
     *
     * @default 10
     */
    pageSize?: number,
    /**
     * Hides the "Page x of y" and paging controls when there is only 1 page.
     * The record could will still be shown unless explicitly hidden using showRecordCount={false}
     *
     * @default true
     */
    hideControlsForSinglePage?: boolean,
    /**
     * Sort the table by default by this field
     *
     * @default none
     */
    defaultSort?: string,
    /**
     * An optional loading spinner control, to be displayed whenever the table is loading data
     */
    spinner?: ReactElement,
    /**
     * A value to use to explicitly trigger a re-render.
     * This can be any value: it just needs to change in order to trigger an update.
     * The simplest approach is to use a number: to force the data table to refresh, just change the number.
     * The primary usecase for this prop is when using an async function for the data prop:
     * changing the refreshTrigger will result in the data function being invoked again,
     * so you can refresh the data table following some other action that updated the
     * underlying data store.
     */
    refreshTrigger?: any,
    /**
     * An optional message to display when the table is empty. This does NOT apply when the table
     * has been filtered.
     *
     * If no message is provided, or if the table is empty because it was filtered, then an empty
     * table will be displayed.
     */
    emptyMessage?: string | ReactElement,
    /**
     * Set a severity to style the empty message as an Alert. If not set, the message will be plain text.
     *
     * @default none
     */
    emptyMessageSeverity?: "info" | "warning" | "error" | "success",
    /**
     * Highlight rows on mouseover
     *
     * @default true
     */
    highlightOnHover?: boolean,
    /**
     * A caption to display above the table
     */
    caption?: string | ReactElement,
    /**
     * Whether the table should have a default odd/even striped style
     *
     * @default false
     */
    striped?: boolean
}

export default DataTable;