import React, {Fragment, useEffect, useState} from "react"
import {OAuthAdminService} from "../../../../service/service";
import useErrorHandler from "../../../../hooks/error-handler";
import ConfirmBtn from "@sportaus-digital/confirm-btn";
import SDSpanel from "../../../../components/panel/panel";
import DataTable from "@sportaus-digital/data-table";
import Spinner from "@sportaus-digital/spinner";
import SDSform, {FormBuilder} from "../../../../components/form/form";

const OAuthRealms = () => {
    const [realms, setRealms] = useState([]);
    const {errorHandler} = useErrorHandler();

    useEffect(() => {
        OAuthAdminService.getRealms()
            .then((data) => setRealms(data))
            .catch((e) => errorHandler(e, "retrieving the list of realms"))
    }, [errorHandler]);

    const deleteHandler = (realm) => {
        OAuthAdminService.deleteRealm(realm.id).then(() => {
            let updatedRealms = realms.slice();
            updatedRealms.splice(updatedRealms.indexOf(realm), 1);
            setRealms(updatedRealms);
        });
    };

    const headers = [
        {title: "Realm ID", key: "name", width: "20", filterable: true, sortable: true},
        {title: "Org Name", key: "orgName", width: "20", filterable: true, sortable: true},
        {title: "Org ID", key: "orgId", width: "20", filterable: true, sortable: true},
        {title: "Sport", key: "sportName", width: "20", filterable: true, sortable: true},
        {
            title: "", key: "", width: "20", render: (data, row) => {
                return <Fragment>
                    <ConfirmBtn as={"tertiary"} onClick={() => deleteHandler(row)}>Delete</ConfirmBtn>
                </Fragment>
            }
        }
    ];

    const form = new FormBuilder()
        .field("name", "Realm ID").required()
        .field("orgId", "Organisation Id (UUID)").required()
        .field("sportId", "Sport ID").required()
        .build();

    const submitHandler = (realm, actions) => {
        OAuthAdminService.createRealm(realm)
            .then((r) => {
                actions.resetForm();
                let updatedRealms = realms.slice();
                updatedRealms.push(r);
                setRealms(updatedRealms);
            })
            .catch((e) => errorHandler(e, "registering the realm"))
            .finally(() => actions.setSubmitting(false));
    };

    return <div className="row">
        <div className="col-sm-12">
            <SDSpanel title="Sports" titleLevel={2}>
                <DataTable headers={headers}
                           spinner={<Spinner hideText={true} inline={true}/>}
                           data={realms}
                           defaultSort="name"
                           pageSize={20}/>
            </SDSpanel>
        </div>
        <div className="col-sm-12">
            <SDSpanel title="Add a new realm" titleLevel={2}>
                <SDSform config={form}
                         progressIndicator={<span className="fa fa-spinner fa-spin ml-1 mr-1"/>}
                         submitHandler={submitHandler}/>
            </SDSpanel>
        </div>
    </div>;
};

OAuthRealms.propTypes = {};

export default OAuthRealms;