import React from "react"
import "./page-title.scss";
import Button from "@sportaus-digital/buttons";
import PropTypes from "prop-types";

const SDSpageTitle = (props) => {
    const {titleLevel, icon, divider, byLine, backButtonRoute} = props;

    let iconSpan;
    let title;
    let divide;
    let byLineText;
    let Header;
    let backButton;

    if (icon) {
        iconSpan = <span className='sds-page-title__icon'>{icon}</span>;
    }

    if (props.children) {
        Header = "h" + titleLevel;
        title = <Header className='sds-page-title__title'>{props.children}</Header>;
    }

    if (divider) {
        divide = <hr className="sds-page-title__divider"/>;
    }

    if (byLine) {
        byLineText = <div className={'sds-page-title__byLine'}>{byLine}</div>;
    }

    if (backButtonRoute) {
        backButton = <div className={'sds-page-title__backButton'}><Button route={backButtonRoute} as='tertiary' className="sac-page-header__backButton" icon={<span className="fa fa-arrow-left fa-2x"/>}/></div>;
    }

    return <div className={`sds-page-title`}>
            {backButton}
            <div className={'sds-page-title__pageTitle'}>
            {iconSpan}
            {title}
            {divide}
            {byLineText}
            </div>
        </div>
};

SDSpageTitle.propTypes = {
    /**
     * Optional number for the h level you want the title to be (e.g. 1 = h1, 2 = h2), will default to h1
     */
    titleLevel: PropTypes.number,
    /**
     * Optional element to include an icon on the header
     */
    icon: PropTypes.element,
    /**
     * Optional boolean to render a hr between the main title and the by line text
     */
    divider: PropTypes.bool,
    /**
     * Optional text to be included as a by line under the title
     */
    byLine: PropTypes.oneOfType([PropTypes.string,PropTypes.element]),
    /**
     * If you include a route here an optional back button will be included on the left side of the title
     */
    backButtonRoute: PropTypes.string
};

SDSpageTitle.defaultProps = {
    titleLevel: 1
};

export default SDSpageTitle;

export const SDSlandingPageTitle = (props) => {
    return <SDSpageTitle titleLevel={1} divider={false} {...props}/>
};

export const SDSsubPageTitle = (props) => {
    return <SDSpageTitle titleLevel={1} divider={true} {...props}/>
};