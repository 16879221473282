import React from "react"
import {SDSlandingPageTitle} from "../../../../components/page-title/page-title";
import SDSpanel from "../../../../components/panel/panel";
import OAuthRealms from "./realms";

const AuthAdmin = () => {
    return <div className="row">
        <div className="col-sm-12">
            <SDSlandingPageTitle icon={<span className="fas fa-key fa-4x"/>}
                                 byLine='Manage OAuth realms and clients'>
                Realms
            </SDSlandingPageTitle>
        </div>
        <div className="col-sm-12">
            <SDSpanel title="Keycloak realms">
                <OAuthRealms/>
            </SDSpanel>
        </div>
    </div>;
};

AuthAdmin.propTypes = {};

export default AuthAdmin;