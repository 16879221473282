export const australianStates = [
  { abbreviation: "ACT", name: "Australian Capital Territory" },
  { abbreviation: "NSW", name: "New South Wales" },
  { abbreviation: "NT", name: "Northern Territory" },
  { abbreviation: "QLD", name: "Queensland" },
  { abbreviation: "SA", name: "South Australia" },
  { abbreviation: "TAS", name: "Tasmania" },
  { abbreviation: "WA", name: "Western Australia" },
  { abbreviation: "VIC", name: "Victoria" },
];
