export const isDisabled = (disabled: boolean | (() => boolean) | undefined) : boolean => {
    return disabled ? typeof disabled === "function" ? disabled() : disabled : false;
};

export interface StandardProps {
    /**
     * The unique identifier for the html element. Defaults to the name if not provided
     *
     * @default name prop
     */
    id?: string,
    /**
     * The field name
     */
    name: string,
    /**
     * Optional classes to apply to the input field
     */
    inputClasses?: string | Array<string>,
    /**
     * Optional onChange handler
     */
    onChange?: (event?: any) => void,
    /**
     * Optional onBlur handler
     */
    onBlur?: () => void,
    /**
     * Whether to disable the field or not. Can be a boolean or a no-arg function that returns a boolean.
     */
    disabled?: boolean | (() => boolean),
    /**
     * True to change the display to inline-block. Input fields are rendered as block elements by default.
     */
    inline?: boolean
}

export {classes} from "@sportaus-digital/core";

export interface Option {
    label: string,
    value: string | number
}
