import React, {useCallback, useEffect, useState} from "react"
import PropTypes from "prop-types"
import {RoleInvitationService, OrganisationService} from "../../../../../service/service";
import SDSform, {FieldTypes, FormBuilder} from "../../../../../components/form/form";
import {SDSsubPageTitle} from "../../../../../components/page-title/page-title";
import SDSpanel from "../../../../../components/panel/panel";
import {useHistory} from "react-router";
import {Actions, ProtectedButton, withAuth} from "../../../../../security/authorisation";
import {debounce} from "lodash";
import Alert from "@sportaus-digital/alert";
import useErrorHandler from "../../../../../hooks/error-handler";
import * as Terms from "../../../../../constants/terms"

const OrganisationInvitation = ({orgId}) => {
    const [organisation, setOrganisation] = useState({});
    const {errorHandler} = useErrorHandler();
    const [duplicate, setDuplicate] = useState(false);
    const [loading, setLoading] = useState(false);
    const router = useHistory();
    const backRoute = `../${orgId}`;

    useEffect(() => {
        OrganisationService.getOrganisation(orgId).then(org => {
            setOrganisation(org);
        }).catch((e) => {
            errorHandler(e, "retrieving the organisation");
        })
    }, [orgId, setOrganisation, errorHandler]);

    let roles;
    if (organisation.allRoles) {
        roles = organisation.allRoles.map((role) => {
            return {label: role.role, value: role.role}
        });
    }

    const dupeCheck = useCallback(debounce((e, invitation) => {
        setLoading(true);
        RoleInvitationService.checkForDuplicates(orgId, invitation.email).then(dupe => {
            if (dupe) {
                setDuplicate(true);
            } else {
                setDuplicate(false);
            }
        }).finally(() => setLoading(false));
    }), [orgId, setDuplicate, setLoading]);

    const formBuilder = new FormBuilder();

    const config = formBuilder
        .field("email", "Contact email", FieldTypes.EMAIL).required().email()
        .wrapperClasses("col-sm-9")
        .onBlur(dupeCheck)

        .field("role", "Role", FieldTypes.SELECT)
        .required()
        .options(roles, undefined)
        .wrapperClasses("col-sm-3")
        .renderAfter(<div className="manage-roles-link col-sm-12 text-right mb-5">
            <ProtectedButton action={Actions.ORG_LIST_ALL_ROLES}
                             orgId={orgId}
                             className="mb-3"
                             route={'./roles'}>Manage roles</ProtectedButton>
        </div>)

        .build();

    const submitHandler = (invitation, actions) => {
        RoleInvitationService.sendInvitation([invitation], orgId).then(() => {
            actions.setSubmitting(false);
            actions.resetForm();
            router.push(backRoute);
        }).catch(() => {
            actions.setStatus({status: "error"});
            actions.setSubmitting(false);
        });

    };

    let byLineText;
    if (organisation) {
        byLineText = <>Invite a person who can act on behalf of <em>{organisation.name}</em> in {Terms.PRODUCT_NAME} </>;
    }

    return <div
        className="organisation-invitation-add row px-2 d-md-flex flex-row flex-wrap align-content-stretch justify-content-between align-items-stretch">
        <div className="col-sm-12">
            <SDSsubPageTitle byLine={byLineText} backButtonRoute={backRoute}>Invite a person to
                join <em>{organisation.name}</em></SDSsubPageTitle>
        </div>
        <div className="col-sm-12 align-self-stretch mb-4">
            <SDSpanel title="Invite person">
                {duplicate && <Alert severity="error"
                                        message={`An invitation to join ${organisation.name} has already been sent this email address.`}/>}
                <div className="row">
                    <SDSform config={config}
                             progressIndicator={<span className="fa fa-spinner fa-spin ml-1 mr-1"/>}
                             submitHandler={submitHandler}
                             disableSubmit={loading || duplicate}
                             cancelRoute={backRoute}/>
                </div>
            </SDSpanel>
        </div>
    </div>
};

OrganisationInvitation.propTypes = {
    orgId: PropTypes.number.isRequired
};

export default withAuth(OrganisationInvitation);