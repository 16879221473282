import React from "react"
import PropTypes from "prop-types"
import SDSform, {FieldTypes, FormBuilder} from "../../components/form/form";
import {EnumService} from "../../service/service";
import {OrganisationType} from "../../utils/enums"
import useDuplicateOrgChecks from "../../hooks/organisation/duplicate-org-check";
import useOrgFunctionsAndSports from "../../hooks/organisation/org-functions-and-sports";
import {useAuth} from "../../security/authorisation";

const OrganisationForm = props => {
    const {currentOrganisation, submitHandler, cancelHandler, cancelRoute} = props;

    const {isAdmin} = useAuth();
    const {dupeOrgCheck, dupeOrgChecking, dupeOrgsExist, dupeFieldMessages, dupeOrgMessage, dupeWarningOnly} = useDuplicateOrgChecks();
    const {allSports, allFunctions, loading, sportValidationSchema, functionChangeHandler} = useOrgFunctionsAndSports();

    let acnTypes = OrganisationType.filter(ot => ot.acn).map(ot => ot.value);
    let abnTypes = OrganisationType.filter(ot => ot.abn).map(ot => ot.value);

    const config = new FormBuilder()
        .field("id", "", FieldTypes.HIDDEN).value(currentOrganisation.id)

        .startFieldSet("Organisation details")

        .field("name", "Organisation name").required()
        .value(currentOrganisation.name)
        .max(256)
        .onBlur(dupeOrgCheck)
        .warning(dupeFieldMessages.warnings.name)
        .error(dupeFieldMessages.errors.name)

        .field("type", "Organisation type", FieldTypes.SELECT).required()
        .value(currentOrganisation.type)
        .max(255)
        .options(OrganisationType).emptyOption()

        .field("acn", <><abbr title="Australian Company Number">ACN</abbr> (if applicable)</>)
        .value(currentOrganisation.acn)
        .regex(/^(\d{9})$|^(\d{3} \d{3} \d{3})$/, "Please enter a valid ACN. An ACN must be a 9 digit number in the format XXXXXXXXX or XXX XXX XXX.")
        .hidden((formValues) => acnTypes.indexOf(formValues.type) < 0)
        .onBlur(dupeOrgCheck)
        .warning(dupeFieldMessages.warnings.acn)
        .error(dupeFieldMessages.errors.acn)

        .field("abn", <><abbr title="Australian Business Number">ABN</abbr> (if applicable)</>)
        .value(currentOrganisation.abn)
        .regex(/^(\d{11})$|^(\d{2} \d{3} \d{3} \d{3})$/, "Please enter a valid ABN. An ABN must be an 11 digit number in the format XXXXXXXXXXX or XX XXX XXX XXX.")
        .hidden((formValues) => abnTypes.indexOf(formValues.type) < 0)
        .onBlur(dupeOrgCheck)
        .warning(dupeFieldMessages.warnings.abn)
        .error(dupeFieldMessages.errors.abn)

        .endFieldSet()

        .startFieldSet("Physical address")

        .field("addressLine1", "Address Line 1").required("Please enter an Address")
        .value(currentOrganisation.addressLine1)
        .max(256)

        .field("addressLine2", "Address Line 2")
        .value(currentOrganisation.addressLine2)
        .max(256)

        .field("suburb", "Suburb").required()
        .value(currentOrganisation.suburb)
        .max(255)

        .field("state", "State", FieldTypes.TEXT).required()
        .value(currentOrganisation.state)
        .max(255)
        .onBlur(dupeOrgCheck)
        .warning(dupeFieldMessages.warnings.state)
        .error(dupeFieldMessages.errors.state)

        .field("postcode", "Postcode").required()
        .max(255)
        .value(currentOrganisation.postcode)

        .field("country", "Country", FieldTypes.SELECT)
        .value(currentOrganisation.country)
        .options(EnumService.getCountries)
        .required()

        .field("latitude", "Latitude")
        .value(currentOrganisation.latitude)
        .regex(/^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)$/,"Please enter a valid latitude.")
        .hidden(() => !isAdmin)

        .field("longitude", "Longitude")
        .value(currentOrganisation.longitude)
        .hidden(() => !isAdmin)
        .regex(/^[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/,"Please enter a valid longitude.")

        .endFieldSet()
        .startFieldSet("Public contact details")

        .field("email", "Email").email()
        .value(currentOrganisation.email)
        .max(1000)
        .placeholder("E.g. info@organisation.org.au")

        .field("url", "Website URL").url()
        .value(currentOrganisation.url)
        .max(1000)
        .placeholder("E.g. www.organisation.org.au")

        .field("phone", "Phone number")
        .value(currentOrganisation.phone)
        .max(100)

        .endFieldSet()
        .startFieldSet("Organisation function(s)")
        .dynamicField("sports")// the sports fields will be derived at run time from the selected functions

        .field("functions", "Organisation function(s)", FieldTypes.SELECT)
        .value(currentOrganisation.functions)
        .options(allFunctions)
        .multiple().required()
        .onChange(functionChangeHandler)
        .onBlur(dupeOrgCheck)
        .warning(dupeFieldMessages.warnings.functions)
        .error(dupeFieldMessages.errors.functions)

        .field("sports", "Sport function", FieldTypes.SELECT)
        .value(currentOrganisation.sports)
        .options(allSports)
        .multiple().withValidation(sportValidationSchema)
        .onBlur(dupeOrgCheck)

        .endFieldSet()

        .build();

    return <div>
        {dupeOrgMessage}
        <SDSform config={config}
                 progressIndicator={<span className="fa fa-spinner fa-spin ml-1 mr-1"/>}
                 disableSubmit={dupeOrgChecking || (dupeOrgsExist && !dupeWarningOnly) || loading}
                 submitHandler={submitHandler}
                 cancelHandler={cancelHandler}
                 cancelRoute={cancelRoute}/>
    </div>
};

OrganisationForm.propTypes = {
    currentOrganisation: PropTypes.object,
    submitHandler: PropTypes.func.isRequired,
    cancelHandler: PropTypes.func,
    cancelRoute: PropTypes.string
};

OrganisationForm.defaultProps = {
    currentOrganisation: {}
};

export default OrganisationForm;
