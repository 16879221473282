import React, {Fragment, useCallback, useEffect, useState} from "react"
import {UserService} from "../../../../service/service";
import SDSpanel from "../../../../components/panel/panel";
import {SDSsubPageTitle} from "../../../../components/page-title/page-title";
import Spinner from "@sportaus-digital/spinner";
import IdCard from "../../../../containers/identity/identity-card";
import {Actions, useAuth, withAuth} from "../../../../security/authorisation";
import {assign, groupBy, kebabCase, keys} from "lodash";
import useErrorHandler from "../../../../hooks/error-handler";
import {OTHER_ID, TEMP_ID} from "../../../../constants/identity-categories";
import Pill from "@sportaus-digital/pill";
import {useHistory} from "react-router";
import {deKebabCase} from "../../../../utils/string-utils";
import * as Terms from "../../../../constants/terms"

const UserIdentities = ({sportFilter}) => {
    const {userProfile} = useAuth();
    const [identities, setIdentities] = useState([]);
    const [grouped, setGrouped] = useState({});
    const [loading, setLoading] = useState(true);
    const {errorHandler} = useErrorHandler();
    const router = useHistory();
    const [sportFilterLabel] = useState(deKebabCase(sportFilter))
    const clearFilter = useCallback(() => {
        router.push("../identities");
    }, [router]);

    useEffect(() => {
        const bySport = groupBy(identities, id => kebabCase(id.sport));
        if (sportFilter) {
            const g = {};
            g[sportFilter] = bySport[sportFilter] || [];
            setGrouped(g);
        } else {
            setGrouped(bySport);
        }
    }, [identities, sportFilter]);

    useEffect(() => {
        UserService.getOtherIdentities([TEMP_ID, OTHER_ID])
            .then(ids => {
                setIdentities(ids);
            })
            .catch((e) => errorHandler(e, "retrieving your identities"))
            .finally(() => setLoading(false))
    }, [setIdentities, setGrouped, setLoading, errorHandler]);

    const idUpdated = (id) => {
        const updates = [...identities];
        const toUpdate = updates.find(i => i.hash === id.hash);
        assign(toUpdate, id);
        setIdentities(updates);
    };

    return (loading
        ? <Spinner/>
        : <div  
            className="row user-identities px-2 d-md-flex flex-row flex-wrap align-content-stretch justify-content-between align-items-stretch">
            <div className="col-sm-12">
                <SDSsubPageTitle byLine={`Manage the identities linked with your ${Terms.PRODUCT_NAME} account`}
                                 backButtonRoute={"/home"}>
                    Your identities</SDSsubPageTitle>
            </div>
            <div className="col-sm-12">
                <SDSpanel>
                    {sportFilter && <div>Filter by: <Pill text={sportFilterLabel} onClick={clearFilter}/></div>}
                    {!identities || identities.length === 0
                        ? <p className="text-center">Hi {userProfile.firstName}, it looks like you don't have any
                            other identities linked with your {Terms.PRODUCT_NAME} account.</p>
                        : keys(grouped).map(sport => <Fragment key={sport}>
                            <h2 className="groupedIdHeading">{deKebabCase(sport)}</h2>
                            <div className="row panel-container">
                                {grouped[sport].map(id => <div className="col-md-6 col-sm-12 col-xs-12" key={id.hash}>
                                    <IdCard identity={id} onUpdate={idUpdated}/>
                                </div>)}
                            </div>
                        </Fragment>)}
                </SDSpanel>
            </div>
        </div>);
};

export default withAuth(UserIdentities, Actions.USER_PROFILE_LIST_IDENTITIES);