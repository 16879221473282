import React, {useEffect, useState} from "react"
import {withAuth} from "../../../../../security/authorisation";
import SDSpanel from "../../../../../components/panel/panel";
import {useHistory} from "react-router";
import {FormBuilder} from "../../../../../components/form/form-builder";
import useErrorHandler from "../../../../../hooks/error-handler";
import {OAuthClientService} from "../../../../../service/service";
import SDSform, {FieldTypes} from "../../../../../components/form/form";
import {SDShint, Types} from "../../../../../components/form/field";
import {
    AUTH_CODE_FLOW_PRIVATE,
    AUTH_CODE_FLOW_PUBLIC,
    CLIENT_CREDENTIALS_GRANT,
    OAuthResourceType
} from "../../../../../constants/oauth-resource-types.js";
import {SDSsubPageTitle} from "../../../../../components/page-title/page-title";
import Alert from "@sportaus-digital/alert";
import Button from "@sportaus-digital/buttons";
import {useEnum} from "../../../../../utils/enums";
import * as Terms from "../../../../../constants/terms"

const RequestOAuthClient = ({orgId, appId}) => {
    const [providers, setProviders] = useState([]);
    const [typeDescription, setTypeDescription] = useState("");
    const [request] = useState({});
    const [loading, setLoading] = useState(true);
    const {errorHandler} = useErrorHandler();
    const router = useHistory();
    const backRoute = `../../${appId}`;
    const successRoute = `../../${appId}/client/`;
    const getType = useEnum(OAuthResourceType);

    useEffect(() => {
        OAuthClientService.getAvailableOAuthProviders(orgId)
            .then(data => setProviders(data.map(p => ({label: p.label, value: p.id, ...p}))))
            .catch((e) => errorHandler(e, "retrieving the list of OAuth Providers"))
            .finally(() => setLoading(false))
    }, [orgId, setProviders, errorHandler, setLoading]);

    const integrationTip = <Alert severity="info" className="my-5" message={<>
        We recommend that you use version 9.0.x of the official&nbsp;
        <Button as="tertiary"
                   className="m-0 p-0 d-inline"
                   link='https://www.keycloak.org/docs/9.0/securing_apps/index.html'
                   target='_blank' rel="noopener noreferrer">
            Keycloak Client Adapter
        </Button>
        &nbsp;for your platform when integrating with ${Terms.PRODUCT_NAME_POSSESSIVE} OIDC service.
        While this is not essential, it can simplify the integration process for many use cases.</>}/>;

    const config = new FormBuilder()
        .field("orgId", "", FieldTypes.HIDDEN).value(orgId)

        .field("description", "Description", Types.TEXTAREA)
        .required()
        .value(request.description)
        .max(500)

        .field("resourceType", "What type of resource do you require?", Types.RADIO_GROUP)
        .value(request.resourceType)
        .options([
            {label: getType(AUTH_CODE_FLOW_PUBLIC) + " " + getType(AUTH_CODE_FLOW_PUBLIC, "shortDesc"), value: AUTH_CODE_FLOW_PUBLIC, checked: false},
            {label: getType(AUTH_CODE_FLOW_PRIVATE) + " " + getType(AUTH_CODE_FLOW_PRIVATE, "shortDesc"), value: AUTH_CODE_FLOW_PRIVATE, checked: false},
            {label: getType(CLIENT_CREDENTIALS_GRANT) + " " + getType(CLIENT_CREDENTIALS_GRANT, "shortDesc"), value: CLIENT_CREDENTIALS_GRANT, checked: false}
        ])
        .required("Please indicate which type of OIDC resource you need")
        .onChange((e) => setTypeDescription(getType(e.currentTarget.value, "longDesc")))
        .renderAfter(<SDShint hint={typeDescription} className="mb-4"/>)
        .wrapperClasses("mb-0")

        .field("baseUrl")
        .value(request.baseUrl)
        .url()
        .max(1000)
        .required()
        .hidden((formValues) => !formValues.resourceType || formValues.resourceType === CLIENT_CREDENTIALS_GRANT)
        .hint(`What is the base URL for your client? E.g. the base URL for${Terms.PRODUCT_NAME} Self Service is ` + window.envConfig.baseUrl)

        .field("validRedirects", "Redirect URLs", FieldTypes.MULTI_TEXT)
        .value(request.validRedirects)
        .required("Please provide at least one valid redirect URL")
        .url("Please provide one or more valid URLs")
        .max(1000)
        .hidden((formValues) => !formValues.resourceType || formValues.resourceType === CLIENT_CREDENTIALS_GRANT)
        .hint("This will be used to limit the allowed redirect URLs that can be passed to the login process. As a minimum, this should include the Base URL.")

        .field("allowedOrigins", "Allowed Origins", FieldTypes.MULTI_TEXT)
        .value(request.allowedOrigins)
        .required("Please provide at least one allowed origin")
        .url("Please provide one or more valid URLs")
        .max(1000)
        .hidden((formValues) => !formValues.resourceType || formValues.resourceType === CLIENT_CREDENTIALS_GRANT || formValues.resourceType === AUTH_CODE_FLOW_PRIVATE)
        .hint("This will be used to limit the allowed origins for HTTP requests to the public client")

        .field("providerId", "What OAuth Provider will this resource to connected to?", FieldTypes.SELECT)
        .value(request.providerId)
        .optionComponent(ProviderOption)
        .options(providers)
        .required("Please specify which OAuth provider you will be using this resource for")
        .renderAfter(integrationTip)
        .build();

    const submitHandler = (formData, actions) => {
        const req = {...formData};
        if (formData.resourceType === CLIENT_CREDENTIALS_GRANT) {
            req.baseUrl = undefined;
            req.allowedOrigins = undefined;
            req.validRedirects = undefined;
        }
        if (formData.resourceType !== AUTH_CODE_FLOW_PUBLIC) {
            req.allowedOrigins = undefined;
        }
        req.provider = providers.find(p => p.value === formData.providerId);
        req.application = {
            id: appId
        };

        OAuthClientService.createClient(orgId, appId, req)
            .then((client) => router.push(successRoute + client.id))
            .catch((e) => {
                errorHandler(e, "registering your resource");
                actions.setSubmitting(false);
            });
    };

    return <div
        className="row request-oauth-client px-2 d-md-flex flex-row flex-wrap align-content-stretch justify-content-between align-items-stretch">
        <div className="col-sm-12">
            <SDSsubPageTitle byLine={"Register a new resource for your application"}
                             backButtonRoute={backRoute}>
                Add an OAuth resource
            </SDSsubPageTitle>
        </div>
        <div className="col-sm-12 align-self-stretch mb-4">
            {!loading && (!providers || providers.length === 0)
            && <Alert severity="error"
                         message={`You can only create resources if your organisation has been connected to at least one organisation that has been authorised by ${Terms.PRODUCT_NAME} as an OAuth Provider`}/>}
            {providers && providers.length > 0 &&
            <SDSpanel title="Provide the following details to create a new resource">
                <SDSform config={config}
                         progressIndicator={<span className="fa fa-spinner fa-spin ml-1 mr-1"/>}
                         disableSubmit={loading}
                         submitHandler={submitHandler}
                         cancelRoute={backRoute}/>
            </SDSpanel>}
        </div>
    </div>;
};

const ProviderOption = ({data}) => {
    return <div className="org-select-option">
        <div className="d-inline-block mr-4">
            <strong className="mr-4">Organisation:</strong>
            <span>{data.orgName};</span>
        </div>
        <div className="d-inline-block">
            <strong className="mr-4">Sport:</strong>
            <span>{data.sportName || "Any"}</span>
        </div>
    </div>;
};

RequestOAuthClient.propTypes = {};

export default withAuth(RequestOAuthClient);