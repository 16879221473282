import {FC, RefObject, useEffect} from "react"
import {useLocation} from "react-router";

/**
 * When rendered inside a React Router view, will scroll the page to the specified element ref or coordinates if the current scroll position is beyond that point.
 *
 * If no coordinates or element ref are provided, then this component will scroll to the top of the page.
 *
 */
export const RouteScrollTo: FC<RouteScrollToProps> = ({
                                                componentRef,
                                                coord = {top: 0, left: 0},
                                                smooth= true
                                            }: RouteScrollToProps) => {
    const location = useLocation();
    const behaviour = smooth ? "smooth" : "auto";

    useEffect(() => {
        let top = componentRef ? componentRef.current.offsetTop : coord.top;
        let left = componentRef ? componentRef.current.offsetTop : coord.left;
        if (window.scrollTo && (top < window.scrollY || left < window.scrollX)) {
            window.scrollTo({
                top: top,
                left: left,
                behavior: behaviour
            });
        }
    }, [componentRef, coord, behaviour, location]);

    return null;
};

export interface RouteScrollToProps {
    /**
     * An element ref to scroll to. If provided, coord will be ignored.
     */
    componentRef?: RefObject<any>,
    /**
     * {top: x, left: y} coord object to scroll to. If not provided, and if no componentRef is provided,
     * then coord will default to {top: 0, left: 0}
     *
     * @default {top: 0, left: 0}
     */
    coord?: { top: number, left: number },
    /**
     * Controls the scroll behaviour. True (the default) enables smooth scrolling; False sets the behaviour to 'auto'.
     *
     * @default true
     */
    smooth?: boolean
}

export default RouteScrollTo;