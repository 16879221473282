import React, {useContext, useEffect, useState} from "react"
import PropTypes from "prop-types"
import SDSfieldList, {SDSfieldListItem} from "../../components/field-list/field-list";
import {IdentityStatus, useEnum} from "../../utils/enums";
import "./identity-card.scss";
import {useRouteMatch} from "react-router";
import {Actions, ProtectedButton} from "../../security/authorisation";
import {UserService} from "../../service/service";
import {PageAlertActionsContext} from "../../utils/page-alerts";
import useErrorHandler from "../../hooks/error-handler";
import * as Terms from "../../constants/terms"

const IdCard = ({identity, noBorder, showControls, onUpdate}) => {
    let {url} = useRouteMatch();
    const getStatus = useEnum(IdentityStatus);
    const [ident, setIdent] = useState({});
    const setPageAlert = useContext(PageAlertActionsContext);
    const {errorHandler} = useErrorHandler();

    useEffect(() => setIdent(identity), [setIdent, identity]);

    const approveClaim = () => {
        UserService.approveClaim(ident)
            .then((id) => {
                setIdent(id);
                setPageAlert({
                    title: "Success",
                    severity: "success",
                    message: `This identity is now linked from your ${Terms.PRODUCT_NAME} account`
                });

                if (onUpdate) {
                    onUpdate(id);
                }
            })
            .catch((e) => {
                errorHandler(e, "updating your identity");
            })
    };

    const rejectClaim = () => {
        UserService.rejectClaim(ident)
            .then((id) => {
                setIdent(id);
                setPageAlert({
                    title: "Success",
                    severity: "success",
                    message: `This identity is now unlinked from your ${Terms.PRODUCT_NAME} account`
                });

                if (onUpdate) {
                    onUpdate(id);
                }
            })
            .catch((e) => {
                errorHandler(e, "updating your identity");
            });
    };

    let controls = null;
    if (showControls) {
        controls = ident.status === "PENDING"
            ? <div className="id-card__doubleButtonRow">
                <div className="id-card__leftContainer">
                    <ProtectedButton action={Actions.USER_PROFILE_UPDATE_IDENTITIES}
                                     as="secondary"
                                     onClick={rejectClaim}>
                        No, this is not me
                    </ProtectedButton>
                </div>
                <div className="id-card__rightContainer">
                    <ProtectedButton action={Actions.USER_PROFILE_UPDATE_IDENTITIES}
                                     onClick={approveClaim}>
                        Yes, this is me
                    </ProtectedButton>
                </div>
            </div>
            : <div className="id-card__singleButtonRow">
                <ProtectedButton action={Actions.USER_PROFILE_UPDATE_IDENTITIES}
                                 route={{
                                     pathname: `${url}/${ident.hash}`,
                                     state: {identity: identity}
                                 }}
                                 as="primary">
                    Manage identity
                </ProtectedButton>
            </div>;
    }

    return <div className={`id-card id-card--${ident.status} ${noBorder ? "id-card--no-border" : ""}`}>
        <h2>{ident.provider || ident.sport}
            {ident.status === "PENDING" && <span className="pending">(Pending)</span>}
        </h2>

        <SDSfieldList>
            {ident.name && <SDSfieldListItem label="Name"
                                                 value={ident.name}
                                                 labelWidth={3}
                                                 valueWidth={9}/>}
            {ident.email && <SDSfieldListItem label="Email"
                                                 value={ident.email}
                                                 labelWidth={3}
                                                 valueWidth={9}/>}
            {ident.phone && <SDSfieldListItem label="Phone"
                                                 value={ident.phone}
                                                 labelWidth={3}
                                                 valueWidth={9}/>}
            {ident.dateOfBirth && <SDSfieldListItem label="Date of birth"
                                                 value={ident.dateOfBirth}
                                                 labelWidth={3}
                                                 valueWidth={9}/>}
            {ident.creator && <SDSfieldListItem label="Creator"
                                                     value={ident.creator}
                                                     labelWidth={3}
                                                     valueWidth={9}/>}
            {ident.status && <SDSfieldListItem label="Status"
                                                  labelClassName="id-card__statusLabel"
                                                  valueClassName={`id-card__status id-card__status--${ident.status}`}
                                                  value={getStatus(ident.status)}
                                                  labelWidth={3}
                                                  valueWidth={9}/>}
        </SDSfieldList>

        {controls}
    </div>;
};

IdCard.propTypes = {
    identity: PropTypes.object.isRequired,
    noBorder: PropTypes.bool,
    showControls: PropTypes.bool
};

IdCard.defaultProps = {
    noBorder: false,
    showControls: true
};

export default IdCard;