import React from "react"
import "./not-authorised.scss";
import PropTypes from 'prop-types';
import Alert from "@sportaus-digital/alert";
import Button from "@sportaus-digital/buttons";

/**
 * Display a message when the user is not authorised to view a resource
 *
 * @requires Alert
 *
 * @example
 * <SDSnotAuthorised message="You cannot see this"></SDSnotAuthorised>
 */
const SDSnotAuthorised = props => {
    let message = props.message || "You do not have permission to view this resource.";

    return <div className="not-authorised">
        <Alert severity="error" message={message}/>
        <div className="text-center mt-5">
            <Button as="tertiary"
                       route="/home">
                Take me home
            </Button>
        </div>
    </div>
};

SDSnotAuthorised.propTypes = {
    /**
     * Message to be displayed instead of the default.
     */
    message: PropTypes.string
};

export default SDSnotAuthorised;