import React from "react"
import PropTypes from "prop-types"
import {Address} from "../address/address";
import "./organisation-summary.scss";
import {uniq} from "lodash";

const OrganisationSummary = ({org, addressFormat, className, showAbn, showAcn, showAddress, showFunctions, showSports}) => {
    return org ? <div className={`sac-org-summary ${className}`}>
        <div className="sac-org-summary__name">{org.name}</div>
        {showAddress && <div><Address address={org.primaryLocation} className="sac-org-summary__address" as={addressFormat}/></div>}
        {showFunctions && <OrgFunctions functions={org.functions} className="sac-org-summary__functions"/>}
        {showSports && <OrgSports functions={org.functions} className="sac-org-summary__sports sac-org-summary__item"/>}
        {showAcn && org.acn && <div className="sac-org-summary__acn sac-org-summary__item">ACN {org.acn}</div>}
        {showAbn && org.abn && <div className="sac-org-summary__abn sac-org-summary__item">ABN {org.abn}</div>}
    </div> : null;
};

OrganisationSummary.propTypes = {
    org: PropTypes.object,
    classNamePrefix: PropTypes.string,
    showAbn: PropTypes.bool,
    showAcn: PropTypes.bool,
    showAddress: PropTypes.bool,
    showSports: PropTypes.bool,
    showFunctions: PropTypes.bool,
    addressFormat: PropTypes.oneOf(["standard", "inline"])
};

OrganisationSummary.defaultProps = {
    addressFormat: "standard",
    showAbn: true,
    showAcn: true,
    showAddress: true,
    showFunctions: true,
    showSports: false
};

export const CompactOrganisationSummary = (props) => {
    return <OrganisationSummary {...props}
                                addressFormat="inline"
                                className="sac-org-summary--compact"/>
};

CompactOrganisationSummary.propTypes = {...OrganisationSummary.propTypes};


export default OrganisationSummary;

export const OrgFunctions = ({functions, className}) => {
    return <div className={className}>
        {functions && functions.map(f => <div key={f.code}>{f.name} {f.sports && f.sports.length > 0 && " for " + f.sports.map(s => s.name).join(", ")}</div>)}
    </div>;
};

export const OrgSports = ({functions, className}) => {
    return <div className={className}>
        {functions && uniq(functions.flatMap(f => f.sports).map(s => s.name)).join(", ")}
    </div>
};

OrgFunctions.propTypes = {
    functions: PropTypes.array
};

