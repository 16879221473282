import React, {createContext, useState} from "react";
import Alert from "@sportaus-digital/alert";

export const GlobalAlertContext = createContext({});
export const GlobalAlertActionsContext = createContext({});

const GlobalAlertContextProvider = ({children}) => {
    const [globalAlert, setGlobalAlert] = useState({
        severity: undefined, // 'info', 'success', 'warning', 'error'
        message: undefined
    });

    return <GlobalAlertContext.Provider value={globalAlert}>
        <GlobalAlertActionsContext.Provider value={setGlobalAlert}>
            {
                globalAlert && globalAlert.message &&
                <Alert severity={globalAlert.severity}
                          title={globalAlert.title}
                          action={globalAlert.action}
                          message={globalAlert.message}/>
            }
            {children}
        </GlobalAlertActionsContext.Provider>
    </GlobalAlertContext.Provider>
};

export default GlobalAlertContextProvider;