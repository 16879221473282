import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import {ApplicationService, OrganisationService} from "../../../../../service/service";
import {SDSsubPageTitle} from "../../../../../components/page-title/page-title";
import RoleForm from "../../../../../containers/role-form/role-form";
import {useHistory} from "react-router";
import SDSpanel from "../../../../../components/panel/panel";
import DataTable from "@sportaus-digital/data-table";
import Spinner from "@sportaus-digital/spinner";
import useErrorHandler from "../../../../../hooks/error-handler";

const OrganisationRoleAdd = ({orgId}) => {
    const [organisation, setOrganisation] = useState({});
    const [capabilities, setCapabilities] = useState([]);
    const [apps, setApps] = useState([]);
    const {errorHandler} = useErrorHandler();
    const router = useHistory();
    const backRoute = '../roles';

    useEffect(() => {
        OrganisationService.getOrganisation(orgId)
            .then(org => {setOrganisation(org)})
            .catch((e) => errorHandler(e, "retrieving the organisation"));

        OrganisationService.getAvailableUserCapabilities(orgId)
            .then((data) => {setCapabilities(data)})
            .catch((e) => errorHandler(e, "retrieving the organisation capabilities"));

        ApplicationService.getApplicationsForOrg(orgId)
          .then(a => setApps(a))
          .catch((e) => errorHandler(e, "retrieving your applications"));
    }, [orgId, setOrganisation, setApps, errorHandler, setCapabilities]);

    const submitHandler = (role, actions) => {
        role.capabilities = role.capabilities.map(c => ({code: c}));
        // Applications were nested under a capability, but we simplify things and apply them to the whole role
        let allApplications = [];
        role.applications.forEach(a => {
            if (a.applications) {
                a.applications.forEach(a2 => allApplications.push(a2))
            }
        });
        role.applications = allApplications;

        OrganisationService.createOrganisationRole(orgId, role).then(() => {
            actions.setSubmitting(false);
            actions.resetForm();
            router.push(backRoute);
        }).catch(() => {
            actions.setStatus({status: "error"});
            actions.setSubmitting(false);
        });
    };

    const headers = [
        {
            title: "Capability",
            key: "name",
            sortable: true
        },
        {
            title: "Description",
            key: "description"
        }
    ];

    let byLineText;
    if (organisation) {
        byLineText = <>Create a custom role which can be assigned to people within <em>{organisation.name}</em></>;
    }

    return <div
        className="row px-2 d-md-flex flex-row flex-wrap align-content-stretch justify-content-between align-items-stretch">
        <div className="col-sm-12">
            <SDSsubPageTitle byLine={byLineText} backButtonRoute={backRoute}>Create a custom role</SDSsubPageTitle>
        </div>
        <div className="col-sm-6">
            <SDSpanel titleLevel={2} title="Details">
                <RoleForm orgId={orgId}
                          currentRole={undefined}
                          orgApps={apps}
                          submitHandler={submitHandler}
                          cancelRoute={backRoute}/>
            </SDSpanel>
        </div>
        <div className="col-sm-6">
            <SDSpanel titleLevel={2} title="Capabilities">
                <DataTable headers={headers}
                           spinner={<Spinner hideText={true} inline={true}/>}
                           data={capabilities}
                           pageable={true}
                           defaultSort="capability"/>
            </SDSpanel>
        </div>
    </div>;
};

OrganisationRoleAdd.propTypes = {
    orgId: PropTypes.number.isRequired
};

export default OrganisationRoleAdd;