import axios from "./sac-axios-auth";

export const OTPService = {
    getOTPConfig: async () => {
        try {
            const response = await axios.get('/otp-api/createotp');
            return response;
        } catch (error) {
            console.error('Error in OTPService.getOTPConfig:', error);
            throw error;
        }
    },

    getConfiguredOTPs: async () => {
        try {
            const response = await axios.get('/otp-api/otp');
            return response;
        } catch (error) {
            console.error('Error in OTPService.getConfiguredOTPs:', error);
            throw error;
        }
    },

    createOTP: async (otpDeviceDTO) => {
        try {
            const response = await axios.post("/otp-api/otp", otpDeviceDTO);
            return response;
        } catch (error) {
            console.error('Error in OTPService.createOTP:', error);
            throw error;
        }

    },

    removeOTP: async (userLabel) => {
        try {
            const response = await axios.delete(`/otp-api/otp/${userLabel}`);
            return response;
        } catch (error) {
            console.error('Error in OTPService.removeOTP:', error);
            throw error;
        }
    }
};



