import _ from "lodash";
import {Types} from "./field";
import {FieldBuilder} from "./field-builder";

/**
 * A builder class to wrap fields in a fieldset. Not intended to be used directly.
 */
class FieldSetBuilder {
    /**
     * @constructor
     */
    constructor(formBuilder, title, description, titleLevel, dynamicField) {
        this.builderType = "FieldSetBuilder";
        this.formBuilder = formBuilder;
        this.config = {
            isFieldset: true,
            dynamicField: dynamicField,
            fsTitle: title,
            fsDescription: description,
            fsTitleLevel: titleLevel,
            fields: {}
        };
        this.fieldBuilders = {};
    }

    /**
     * When set, this wraps all fields in the FieldSet with Formik's FieldArray, and will pass Formik's arrayHelps
     * object as the LAST arg to any onChange handler defined within the fieldset.
     *
     * Only 1 dynamic field can be used per Field Set.
     *
     * A dynamic field is a field that is created from an array of values derived from another actions, e.g.
     * from the selected values in a previous select field - i.e. a multi-select that produces a new field for
     * each selected value, as with Functions and Sports in the Organisation forms.
     *
     * @see https://jaredpalmer.com/formik/docs/api/fieldarray
     *
     * @returns {FieldSetBuilder}
     */
    dynamicField(id) {
        this.config.dynamicField = id;
        return this;
    }

    /**
     * Create a new field with the minimum required configuration. The id MUST be provided.
     *
     * If no label is provided, the id will be formatted to sentence-case and used instead.
     *
     * If no type is provided, FieldTypes.TEXT will be used as the default.
     *
     * @param id {string} A unique identifier (within the scope of this form). Required.
     * @param label {string|function} A label to be displayed. Defaults to the id formatted into sentence case.
     * @param type {FieldTypes} The input field type. Defaults to TEXT.
     * @returns {FieldBuilder}
     */
    field(id, label, type) {
        let field = {
            name: id,
            label: label || _.capitalize(_.startCase(id)),
            type: type || Types.TEXT
        };
        let builder = new FieldBuilder(field, this);
        this.fieldBuilders[id] = builder;
        return builder;
    }

    /**
     * Adds an array of FieldBuilders to the FieldSetBuilder. These fields will be appended to any existing fields
     * in the fieldset in the order in which they appear in the array.
     *
     * @param fieldArray {[FieldBuilder]} Array of field builders to append to the fieldset
     */
    withFields(fieldArray) {
        let self = this;
        fieldArray.forEach(builder => {
            self.fieldBuilders[builder.getName()] = builder;
        });
        return this;
    }

    /**
     * Close off the field set and return the parent form builder. This MUST be called to end a field set.
     *
     * @returns {FormBuilder}
     */
    endFieldSet() {
        return this.formBuilder.endFieldSet();
    }

    /**
     * Finalize and build the fieldset configuration object. The output of this function should be passed without modification to the <SDSform config={}> component.
     * @returns {{}}
     */
    build() {
        let fieldset = this.config;
        _.forOwn(this.fieldBuilders, (builder, id) => {
            return fieldset.fields[id] = builder._buildField();
        });
        return fieldset;
    }
}

export {FieldSetBuilder}
