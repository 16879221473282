import React from "react"

export interface CoreProps {
}

const Core: React.FC<CoreProps> = ({}: CoreProps) => {
    return <div></div>;
};

export default Core;

export const classes = (base: string | Array<string> | undefined,
                        others: string | Array<string> | undefined): string => {
    others = others ? Array.isArray(others) ? others : [others] : [];
    base = base ? Array.isArray(base) ? base : [base] : [];

    return base.concat(others).join(" ");
};