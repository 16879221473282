import React from "react";
import { useRouteMatch } from "react-router";
import { Link } from "react-router-dom";
import { SDSlandingPageTitle } from "../../../components/page-title/page-title";
import SDSpanel from "../../../components/panel/panel";
import { Actions, Protected, withAuth } from "../../../security/authorisation";

const AccountDashboard = () => {
  const { path } = useRouteMatch();
  return (
    <div>
      <div className="row">
        <div className="col-sm-12">
          <SDSlandingPageTitle
            icon={<span className="fas fa-user-circle fa-4x" />}
            byLine="Manage your account details, linked accounts and security settings."
          >
            Account
          </SDSlandingPageTitle>
        </div>
      </div>
      <div className="row panel-container">
        <div className="col-xs-12 col-sm-6">
          <SDSpanel
            title="Account details"
            action={
              <Link
                to={`${path}/userdetails`}
                className="au-btn sds-button sds-btn--block sds-button--tertiary"
              >
                View details
              </Link>
            }
          ></SDSpanel>
        </div>
        <Protected action={Actions.DOCUMENT_VERIFICATION_READ}>
          <div className="col-xs-12 col-sm-6">
            <SDSpanel
              title="Identity Verification"
              action={
                <Link
                  to={`${path}/documents`}
                  className="au-btn sds-button sds-btn--block sds-button--tertiary"
                >
                  View details
                </Link>
              }
            ></SDSpanel>
          </div>
        </Protected>

        <Protected action={Actions.USER_PROFILE_LIST_IDENTITIES}>
          <div className="col-xs-12 col-sm-6">
            <SDSpanel
              title="Linked accounts"
              action={
                <Link
                  to={`${path}/linkedaccounts`}
                  className="au-btn sds-button sds-btn--block sds-button--tertiary"
                >
                  View details
                </Link>
              }
            ></SDSpanel>
          </div>
        </Protected>
        <div className="col-xs-12 col-sm-6">
          <SDSpanel
            title="Security"
            action={
              <Link
                to={`${path}/security`}
                className="au-btn sds-button sds-btn--block sds-button--tertiary"
              >
                View details
              </Link>
            }
          ></SDSpanel>
        </div>
      </div>
    </div>
  );
};

AccountDashboard.propTypes = {};

export default withAuth(AccountDashboard);