// These types must be kept in sync with the OAuthResourceType enum in the backend
export const AUTH_CODE_FLOW_PUBLIC = "AUTH_CODE_FLOW_PUBLIC";
export const AUTH_CODE_FLOW_PRIVATE = "AUTH_CODE_FLOW_PRIVATE";
export const CLIENT_CREDENTIALS_GRANT = "CLIENT_CREDENTIALS_GRANT";

export const OAuthResourceType = [
    {
        value: AUTH_CODE_FLOW_PUBLIC,
        label: "Public OIDC client",
        shortDesc: "(e.g. a web client or a native mobile app)",
        longDesc: "A public client that uses the OAuth2 'Authorization Flow' grant type. Since the client is public, no private credential will be provided: instead, you will need to configure a list of allowed web origins."
    },
    {
        value: AUTH_CODE_FLOW_PRIVATE,
        label: "Private OIDC client",
        shortDesc: "(e.g. integrating with a 3rd party application like Wordpress)",
        longDesc: "A private client that uses the OAuth2 'Authorization Flow' grant type. Since the client is private, you will be issued with a private credential."
    },
    {
        value: CLIENT_CREDENTIALS_GRANT,
        label: "Service client",
        shortDesc: "(e.g. a back-end API service)",
        longDesc: "A service account that uses the OAuth2 'Client Credentials' grant type. This is intended for applications that have no user interactions."
    }
];