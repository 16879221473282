/* eslint-disable react-hooks/exhaustive-deps */
// intentionally excluding the userProfile dependency since it is updated in this hook:
// including the dependency results in a render loop.
import React, {useCallback, useContext} from "react"
import {AuthActionsContext, AuthContext} from "./auth-context";
import PropTypes from "prop-types"
import Button from "@sportaus-digital/buttons";
import SDSnotAuthorised from "../components/not-authorised/not-authorised";
import {useKeycloak} from "@react-keycloak/web";
import {intersection, isArray} from "lodash";
import {UserService} from "../service/service";

/**
 * A custom hook providing utility functionality for checking authorisation.
 *
 */
const useAuth = () => {
    const userProfile = useContext(AuthContext);
    const setUserProfile = useContext(AuthActionsContext);
    const isAdmin = userProfile && userProfile.groups && userProfile.groups.indexOf("sac-admin") > -1;

    const updateUserProfile = useCallback(() => {
        return UserService.getUserProfile()
            .then(profData => setUserProfile({
                ...userProfile,
                ...profData,
                fullyInitialised: true
            }));
    }, [setUserProfile]);
    // intentionally excluding the userProfile dependency since it is updated in this hook:
    // including the dependency results in a render loop.

    const allow = (actions, orgId, appId) => {
        if (!userProfile || !userProfile.fullyInitialised) {
            return false;
        }
        if (isAdmin) {
            return true;
        }

        actions = isArray(actions) ? actions : [actions];

        let allowed = false;
        if (appId || orgId) {
            // App specific
            if (appId) {
                let appPerms = userProfile.permissions.find(p => p.appId === appId) || {};
                let allowedActions = (appPerms.allowedActions || []);
                allowed = intersection(allowedActions, actions).length > 0;
            }
            // Org specific
            if (!allowed && orgId) {
                let orgPerms = userProfile.permissions.find(p => (p.orgId === orgId && p.appId === null)) || {};
                let allowedActions = (orgPerms.allowedActions || []);
                allowed = intersection(allowedActions, actions).length > 0;
                // Only look into app-specific tree (for org data) if this component has not been locked down to specific
                // applications. Typically use case for this will be parent elements that show app-specific children.
                if (!allowed && !appId) {
                    orgPerms = userProfile.permissions.find(p => (p.orgId === orgId && p.appId !== null)) || {};
                    allowedActions = (orgPerms.allowedActions || []);
                    allowed = intersection(allowedActions, actions).length > 0;
                }
            }

        } else {
            let allowedActions = ((userProfile.permissions.find(p => (!p.orgId && !p.appId)) || {}).allowedActions) || [];
            allowed = intersection(allowedActions, actions).length > 0;
        }

        return allowed;
    };

    const hasPermission = (perm, orgId) => {
        orgId = orgId || null;
        return userProfile.permissions.find(p => p.orgId === orgId
            && p.allowedActions.indexOf(perm) > -1) !== undefined;
    };

    return {
        allow: allow,
        Actions: Actions,
        isAdmin: isAdmin,
        authenticated: userProfile && userProfile.authenticated,
        userProfile: userProfile,
        updateUserProfile: updateUserProfile,
        hasPermission: hasPermission
    };
};

/**
 * The list of possible actions a user can perform which may require permissions.
 * Must match the corresponding server-side list.
 */
const Actions = {
    // Actions pertaining to the management of the official list of supported sports
    SPORT_MANAGE: "SPORT_MANAGE",
    SPORT_CREATE: "SPORT_CREATE",
    SPORT_READ: "SPORT_READ",
    SPORT_UPDATE: "SPORT_UPDATE",
    SPORT_DELETE: "SPORT_DELETE",
    SPORT_LIST_ALL: "SPORT_LIST_ALL",

    // Actions pertaining to the user's own organisation
    REQUEST_ORG_ACCESS: "REQUEST_ORG_ACCESS",
    ORG_LIST_ALL: "ORG_LIST_ALL",
    ORG_LIST_OWN: "ORG_LIST_OWN",
    ORG_SEARCH: "ORG_SEARCH",
    ORG_VIEW_METADATA: "ORG_VIEW_METADATA",
    ORG_UPDATE: "ORG_UPDATE",
    ORG_CREATE: "ORG_CREATE",
    ORG_INVITE_USER: "ORG_INVITE_USER",
    ORG_LIST_ALL_USERS: "ORG_LIST_ALL_USERS",
    ORG_READ_USER: "ORG_READ_USER",
    ORG_UPDATE_USER: "ORG_UPDATE_USER",
    ORG_REMOVE_USER_FROM_ORG: "ORG_REMOVE_USER_FROM_ORG",
    ORG_LIST_ALL_ROLES: "ORG_LIST_ALL_ROLES",
    ORG_CREATE_ROLES: "ORG_CREATE_ROLES",
    ORG_UPDATE_ROLES: "ORG_UPDATE_ROLES",
    ORG_DELETE_ROLES: "ORG_DELETE_ROLES",
    ORG_LIST_ALL_CAPABILITIES: "ORG_LIST_ALL_CAPABILITIES",
    ORG_LIST_ALL_FUNCTIONS: "ORG_LIST_ALL_FUNCTIONS",
    ORG_AFFILIATIONS_REQUEST: "ORG_AFFILIATIONS_REQUEST",
    ORG_AFFILIATIONS_LIST: "ORG_AFFILIATIONS_LIST",
    ORG_AFFILIATIONS_ACCEPT: "ORG_AFFILIATIONS_ACCEPT",
    ORG_AFFILIATIONS_DECLINE: "ORG_AFFILIATIONS_DECLINE",
    ORG_AFFILIATIONS_WITHDRAW: "ORG_AFFILIATIONS_WITHDRAW",
    ORG_AFFILIATIONS_REVOKE: "ORG_AFFILIATIONS_REVOKE",

    // Actions pertaining to an organisation that is affiliated with user's organisation
    // (the user may not be directly associated with that organisation).
    AFFILIATED_ORG_CREATE: "AFFILIATED_ORG_CREATE",
    AFFILIATED_ORG_UPDATE: "AFFILIATED_ORG_UPDATE",
    AFFILIATED_ORG_INVITE_USER: "AFFILIATED_ORG_INVITE_USER",
    AFFILIATED_ORG_MANAGE_USERS: "AFFILIATED_ORG_MANAGE_USERS",
    AFFILIATED_ORG_MANAGE_ROLES: "AFFILIATED_ORG_MANAGE_ROLES",

    // Actions related to the management of applications, OAuth Resources and Webhooks
    ORG_APPLICATIONS_LIST_APP: "ORG_APPLICATIONS_LIST_APP",
    ORG_APPLICATIONS_CREATE_APP: "ORG_APPLICATIONS_CREATE_APP",
    ORG_APPLICATIONS_UPDATE_APP: "ORG_APPLICATIONS_UPDATE_APP",
    ORG_APPLICATIONS_DELETE_APP: "ORG_APPLICATIONS_DELETE_APP",
    ORG_APPLICATIONS_LIST_CLIENT: "ORG_APPLICATIONS_LIST_CLIENT",
    ORG_APPLICATIONS_CREATE_CLIENT: "ORG_APPLICATIONS_CREATE_CLIENT",
    ORG_APPLICATIONS_UPDATE_CLIENT: "ORG_APPLICATIONS_UPDATE_CLIENT",
    ORG_APPLICATIONS_REGEN_CLIENT_CRED: "ORG_APPLICATIONS_REGEN_CLIENT_CRED",
    ORG_APPLICATIONS_DELETE_CLIENT: "ORG_APPLICATIONS_DELETE_CLIENT",
    ORG_APPLICATIONS_LIST_WEBHOOK: "ORG_APPLICATIONS_LIST_WEBHOOK",
    ORG_APPLICATIONS_CREATE_WEBHOOK: "ORG_APPLICATIONS_CREATE_WEBHOOK",
    ORG_APPLICATIONS_UPDATE_WEBHOOK: "ORG_APPLICATIONS_UPDATE_WEBHOOK",
    ORG_APPLICATIONS_DELETE_WEBHOOK: "ORG_APPLICATIONS_DELETE_WEBHOOK",

    // Groups
    GROUP_MANAGEMENT_ORG: "GROUP_MANAGEMENT_ORG",
    GROUP_MANAGEMENT_APP: "GROUP_MANAGEMENT_APP",

    API_KEY_ADMIN: "API_KEY_ADMIN",

    // Actions pertaining to access to, and management of, a user's own profile
    USER_PROFILE_READ: "USER_PROFILE_READ",
    USER_PROFILE_VERIFY_EMAIL: "USER_PROFILE_VERIFY_EMAIL",
    USER_PROFILE_READ_CONSENTS: "USER_PROFILE_READ_CONSENTS",
    USER_PROFILE_LIST_IDENTITIES: "USER_PROFILE_LIST_IDENTITIES",
    USER_PROFILE_UPDATE_IDENTITIES: "USER_PROFILE_UPDATE_IDENTITIES",
    DOCUMENT_VERIFICATION_READ: "DOCUMENT_VERIFICATION_READ",
    DOCUMENT_VERIFICATION_SUBMIT: "DOCUMENT_VERIFICATION_SUBMIT"
};

/**
 * Simple wrapper component to show or hide children depending on whether the current
 * user has the appropriate permissions
 * @param action {String|Array} the {@link Actions} action being performed. Can be an array (will check if ANY action is allowed, not all). Required.
 * @param orgId The organisation's GUID external id if the action is specific to an organisation. Optional.
 * @param showNotAuthorised If true, the 'not authorised' error message will be displayed instead of the component. If false, nothing will be displayed.
 * @param notAuthorisedMessage Override the default 'not authorised' message when showNotAuthorised = true
 * @param loginIfNotAuthenticated Redirect the user to the login screen if they are not authenticated
 * @param children The child element(s) to render if the user has permissions
 *
 * @component
 */
const Protected = ({action, orgId, appId, showNotAuthorised, notAuthorisedMessage, loginIfNotAuthenticated, children}) => {
    const {allow, authenticated} = useAuth();
    const {keycloak, initialized} = useKeycloak();

    if (!authenticated && loginIfNotAuthenticated && keycloak && initialized) {
        keycloak.login(); // triggers a redirect
        return null;
    } else {
        return !action || allow(action, orgId, appId)
            ? <>{children}</>
            : showNotAuthorised
                ? <SDSnotAuthorised message={notAuthorisedMessage}/>
                : null;
    }
};

Protected.propTypes = {
    /**
     * The action being performed
     */
    action: PropTypes.oneOfType([PropTypes.oneOf(Object.keys(Actions)), PropTypes.arrayOf(PropTypes.oneOf(Object.keys(Actions)))]),
    /**
     * The organisation's external id (GUID) if the action is specific to an organisation.
     * Leave undefined if the action is not specific to an organisation.
     */
    orgId: PropTypes.number,
    /**
     * If true, the 'not authorised' error message will be displayed instead of the component.
     * If false, nothing will be displayed.
     */
    showNotAuthorised: PropTypes.bool,
    /**
     * Override the default 'not authorised' message when showNotAuthorised = true
     */
    notAuthorisedMessage: PropTypes.string,
    /**
     * Redirect the user to the login screen if they are not authenticated. This does NOT affect
     * the behaviour if the user is authenticated but not AUTHORISED.
     */
    loginIfNotAuthenticated: PropTypes.bool
};

const ProtectedButton = ({action, orgId, appId, children, ...btnProps}) => {
    return <Protected action={action} orgId={orgId} appId={appId}>
        <Button {...btnProps}>{children}</Button>
    </Protected>
};

const ProtectedPage = ({action, orgId, appId, children}) => {
    return <Protected action={action}
                      orgId={orgId}
                      appId={appId}
                      loginIfNotAuthenticated={true}
                      showNotAuthorised={true}>
        {children}
    </Protected>
};

const withAuth = (Component, action, orgId, appId) => (props) => {
    return <ProtectedPage action={action} orgId={orgId} appId={appId}>
        <Component {...props}/>
    </ProtectedPage>
};

export {Protected, ProtectedButton, ProtectedPage, Actions, useAuth, withAuth};
