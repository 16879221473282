import AUtextInput from "@gov.au/text-inputs";
import React, {FC} from "react";
import {StandardProps, classes, isDisabled} from "./_common";
import "./input-fields.scss";

export const TextArea: FC<TextAreaProps> = ({
                                                id,
                                                name,
                                                disabled,
                                                onChange,
                                                onBlur,
                                                inline,
                                                inputClasses,
                                                ...rest
                                            }: TextAreaProps) => {
    return <AUtextInput {...rest}
                        id={id || name}
                        name={name || id}
                        as="textarea"
                        disabled={isDisabled(disabled)}
                        className={classes("sds-text-area", inputClasses)}
                        onChange={onChange}
                        onBlur={onBlur}
                        block={!inline}/>
};

export type TextAreaProps = {} & StandardProps;