import React, {FC, ReactElement, useEffect, useState} from "react"
import AUpageAlert from "@gov.au/page-alerts";
import "./alert.scss";
import Button from "@sportaus-digital/buttons";

/**
 * A component to display alert messages, based on the Australian Government Design System Page Alert component.
 *
 * This component will import FontAwesome CSS.
 */
export const Alert: FC<AlertProps> = ({
                                          severity = "error",
                                          message,
                                          action,
                                          title,
                                          children,
                                          dismiss,
                                          dismissIcon = <span className="fa fa-times"/>,
                                          timeout,
                                          className
                                      }: AlertProps) => {
    const [fadeOut, setFadeOut] = useState(false);
    let dismissFn: any = undefined;

    if (dismiss) {
        dismissFn = () => {
            setFadeOut(true);
            setTimeout(dismiss, 750);
        }
    }

    useEffect(() => {
        if (timeout && dismissFn) {
            let timer = setTimeout(() => {
                dismissFn();
            }, timeout);

            return () => clearTimeout(timer);
        }
    }, [timeout, dismissFn]);


    return <AUpageAlert as={severity || "error"}
                        className={`sds-alert sds-alert--${severity} ${fadeOut ? "fade-out" : ""} ${className}`}>
        {title && <h3 className="sds-alert__title">{title}</h3>}
        {message}
        {children}
        {
            action && <Button onClick={action.onClick} as="tertiary" className="ml-2 px-0">{action.text}.</Button>
        }
        {dismissFn && <div className="sds-alert__dismiss">
            <button onClick={dismissFn}
                    title="Dismiss"
                    className="sds-alert__dismissBtn">
                {dismissIcon}
            </button>
        </div>}
    </AUpageAlert>;
};

export interface AlertProps {
    /**
     * The severity of the alert.
     *
     * @default error
     */
    severity?: "error" | "warning" | "info" | "success",
    /**
     * The alert content (can be replaced with children)
     */
    message?: string | ReactElement,
    /**
     * An optional action to display after the message. This will render a Button component in 'tertiary' style.
     */
    action?: Action,
    /**
     * If provided, a h3 element will be added to the alert containing the provided title text.
     */
    title?: string | undefined,
    /**
     * An optional dismiss function - this results in adding a control to the top right corner of the alert
     * to allow the alert to be dismissed. When clicked, the dismiss function is called and the alert will
     * fade out.
     */
    dismiss?: () => void,
    /**
     * The component to use to override the default dismiss icon.
     *
     * This is only relevant when the dismiss attribute has been provided.
     */
    dismissIcon?: ReactElement,
    /**
     * If set, the alert will automatically invoke the dismiss function after [timeout] milliseconds.
     *
     * This is only relevant when the dismiss attribute has been provided.
     */
    timeout?: number,
    /**
     * CSS classes
     */
    className?: string,
    /**
     * Child components/content
     */
    children?: any
}

/**
 * Type to be used when providing an action attribute for the alert. Only onClick events are supported at present.
 */
export interface Action {
    /**
     * The text of the action
     */
    text: string,
    /**
     * The onclick event handler for the action
     */
    onClick: () => void
}

export default Alert;