import React, {useEffect, useState} from "react"
import _ from "lodash"
import SDSpanel from "../../../components/panel/panel";
import DataTable from "@sportaus-digital/data-table";
import Spinner from "@sportaus-digital/spinner";
import {OrganisationService, SportService} from "../../../service/service";
import {useRouteMatch} from "react-router";
import {SDSlandingPageTitle} from "../../../components/page-title/page-title";
import {Actions, ProtectedButton, useAuth, withAuth} from "../../../security/authorisation";
import SDSicon from "../../../components/icons/icons";
import useErrorHandler from "../../../hooks/error-handler";
import * as Terms from "../../../constants/terms"

const Organisations = () => {
    let {path} = useRouteMatch();
    const {userProfile} = useAuth();

    const {errorHandler} = useErrorHandler();
    const [sports, setSports] = useState([]);

    const {isAdmin} = useAuth();
    const fetchService = isAdmin ? OrganisationService.getOrganisations : OrganisationService.getMyOrganisations;

    useEffect(() => {
        SportService.getSports().then((data) => {
            setSports(data);
        }).catch((e) => {
            errorHandler(e, "retrieving the list of sports");
        })
    }, [setSports, errorHandler]);

    const headers = [
        {title: "Name", key: "name", width: "25", filterable: true, sortable: true},
        {
            title: "Sports",
            width: "50",
            render: (colData, org) => {
                let result;
                if (sports && sports.length > 0) {
                    result = (org.functions ? _.orderBy(_.flatten(org.functions.filter(f => f.sports).map(f => f.sports.map(s => (_.find(sports, {externalId: s.externalId}) || {name: ""}).name)))).join(", ") : "")
                } else {
                    result = org.functions ? org.functions.map(f => f.sports || []).map(s => s.name).join(", ") : "";
                }
                return result;
            }
        },
        {
            title: "Action", key: "", width: "15", render: (data, row) => {
                return <>
                    <ProtectedButton route={`${path}/${row.id}`}
                                     action={Actions.ORG_VIEW_METADATA}
                                     orgId={row.id}>View details</ProtectedButton>
                </>
            }
        }
    ];

    const panelAction = <ProtectedButton action={[Actions.ORG_CREATE, Actions.AFFILIATED_ORG_CREATE]}
                                         className="sds-btn--block"
                                         route={`${path}/add`}>
        <SDSicon group="fa" name="plus"/>Add an organisation
    </ProtectedButton>;

    return <div className="row">
        <div className="col-sm-12">
            <SDSlandingPageTitle icon={<span className="fa fa-sitemap fa-4x"/>}
                                 byLine='Manage organisations, members, their roles and organisation affiliation'>
                Organisations
            </SDSlandingPageTitle>
        </div>
        <div className="col-sm-12">
            <SDSpanel title="Organisations" titleLevel={2} action={panelAction}>
                <DataTable headers={headers}
                           data={fetchService}
                           spinner={<Spinner hideText={true} inline={true}/>}
                           emptyMessage={<><p>Hi {userProfile.firstName}, it looks like you’re not linked with any
                               organisations yet.</p> <p>Don’t worry we’re here to help...</p> <p>If you’re a staff
                               member of an existing organisation, please contact your manager and request to be added
                               to the organisation in {Terms.PRODUCT_NAME}</p><p>Alternatively, add your organisation (if it
                               doesn’t already exist) by clicking the ‘Add an organisation’ button below</p></>}
                           defaultSort="name"
                           pageSize={20}/>
            </SDSpanel>
        </div>
    </div>;
};

Organisations.propTypes = {};

export default withAuth(Organisations);