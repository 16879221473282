import React from "react"
import PropTypes from "prop-types"
import "./panel.scss"
import Spinner from "@sportaus-digital/spinner";

const SDSpanel = props => {
    const Header = "h" + (props.titleLevel);

    let actionDiv;

    if (props.action) {
        actionDiv = <div className="sds-panel__action">{props.action}</div>;
    }

    let panelClass = "sds-panel"
        + (props.size ? " sds-panel--" + props.size : "")
        + (props.scroll ? "" : " sds-panel--expand")
        + (props.className ? " " + props.className : "");

    return <div className={panelClass}>
        <Header className="sds-panel__title">{props.title}</Header>
        <div className="sds-panel__body">
            {
                props.loading
                ? <Spinner text={props.loadingText}/>
                : props.children
            }
        </div>
        {actionDiv}
    </div>;
};

SDSpanel.propTypes = {
    /**
     * Title for the panel
     */
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    /**
     * The heading level for the title (converts to a <h1>, <h2>, etc tab)
     */
    titleLevel: PropTypes.oneOf([1, 2, 3, 4, 5]),
    /**
     * The height of the panel. If not provided, the height will be determined by the contents.
     * If scroll = true, this sets both the minimum and maximum height;
     * if scroll = false, it only sets the minimum height.
     */
    size: PropTypes.oneOf(["sm", "md", "lg"]),
    /**
     * True to scroll the contents if they exceed the height of the component
     */
    scroll: PropTypes.bool,
    /**
     * Optional Buttons. When creating a panel you can add 0..* Buttons. These will be added to a list
     * inside the SDSpanel at the bottom with a dividing line from panel contents.
     */
    action: PropTypes.element,
    /**
     * Flag to indicate that the panel's contents are being loaded and the loading spinner should be displayed.
     */
    loading: PropTypes.bool,
    /**
     * Optional text to override the default text displayed with the loading spinner
     */
    loadingText: PropTypes.string
};

SDSpanel.defaultProps = {
    scroll: false,
    titleLevel: 2
};

export default SDSpanel;