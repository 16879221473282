import React, {FC, ReactElement} from "react"
import AUbutton from "@gov.au/buttons";
import "./buttons.scss"
import {Link} from "react-router-dom";

/**
 * Button acts as a wrapper for both the gov AU DTA design system's AUbutton and the react-routes Link components.
 * Returning one or the other depending on props passed in.
 *
 * Providing a 'route' will cause Button to return react Link, if no link is provided then AUbutton will be used.
 */
export const Button: FC<ButtonProps> = ({
                                            icon,
                                            iconPosition,
                                            route,
                                            className,
                                            as,
                                            disabled,
                                            onClick,
                                            children,
                                            ...rest
                                        }: ButtonProps) => {
    let iconSpan: ReactElement | null = null;
    let button: ReactElement;

    if (icon) {
        iconSpan = <span className={`sds-button sds-button__icon sds-button__icon--${iconPosition}`}>{icon}</span>
    }

    if (route) {
        button = <Link
            className={`sds-button sds-button--${as || "tertiary"} au-btn au-btn--${as || "tertiary"} ${className ? className : ''} ${disabled ? "disabled" : ""}`}
            to={route}
            {...rest}
            onClick={(e: any) => {
                if (disabled) {
                    e.preventDefault();
                } else if (onClick) {
                    onClick(e);
                }
            }}>
            {iconPosition === 'left' && iconSpan}
            {children}
            {iconPosition === 'right' && iconSpan}
        </Link>;
    } else {
        button = <AUbutton className={`sds-button ${className ? className : ''} ${options[as || "primary"]}`}
                           onClick={onClick}
                           disabled={disabled}
                           {...rest}>
            {iconPosition === 'left' && iconSpan}
            {children}
            {iconPosition === 'right' && iconSpan}
        </AUbutton>;
    }

    return button
};

/**
 * All different kind of button options
 */
const options = {
    'primary': 'sds-button--primary',
    'secondary': 'sds-button--secondary',
    'tertiary': 'sds-button--tertiary',
};

export interface ButtonProps {
    /**
     * The kind of button (primary, secondary or tertiary)
     *
     * @default primary
     */
    as?: "primary" | "secondary" | "tertiary",
    /**
     * If a link is set the button will render as a link
     */
    link?: string,
    /**
     * CSS class names
     */
    className?: string | Array<string>,
    /**
     * On Click event handler
     */
    onClick?: (event: any) => void,
    /**
     * True to disable the button
     */
    disabled?: boolean,
    /**
     * Optional icon for the link/button
     *
     * @default no icon
     */
    icon?: ReactElement,
    /**
     * Which side of the text to display the icon
     *
     * @default left
     */
    iconPosition?: "left" | "right",
    /**
     * Optional route will be created as a react-router-dom Link object with the route mapped to the 'to' prop
     */
    route?: string | {
        pathname: string,
        state?: object
    },
    /**
     * Child components/content
     */
    children?: any
}

Button.defaultProps = {
    iconPosition: "left"
};

export default Button;