import React from "react";
import "./public-home.scss";
import * as Terms from "../../constants/terms"


const PublicHome = () => {

  return (
    <div className="public-home">
      <div className="public-home__main-content container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <h1 className="title public-home__title">
              Welcome to the {Terms.PRODUCT_NAME} Portal
            </h1>
            <p className="public-home__description">
              {Terms.PRODUCT_NAME} (previously known as SportAUS Connect) is aimed at giving Australian sport participants and users a single, secure, digital identity. 
              Over the coming months you will be able to use this identity to connect to ASC and AIS applications.
            </p>
            <p className="public-home__description">
             When fully rolled out {Terms.PRODUCT_NAME} will enable a seamless experience to Australians when digitally engaging with sport and make it easier for sport organisations (and their technology partners) to deliver that experience.
            </p>
            <p className="public-home__description">
              Visit the <a href="https://www.ausport.gov.au/sportconnect" >ASC website</a> for more information.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PublicHome;
