import React, { useState } from "react";
import { Form, Field, Formik, ErrorMessage } from "formik";
import { SDSlandingPageTitle } from "../../../components/page-title/page-title";
import * as Yup from "yup";
import { useHistory } from "react-router";
import "../../../components/form/form.scss";
import SDSpanel from "../../../components/panel/panel";
import {useAuth} from "../../../security/authorisation";
import { IdentityService } from "../../../service/service";
import useErrorHandler from "./error-handler";
import SelectState from "./selectState";
import DateOfBirth from "./dateOfBirth";
import DocumentSubmitMessage from "./documentSubmitMessage";
import DocumentSubmitButtons from "./documentSubmitButtons";

const DriversLicence = ({ mode }) => {
  const router = useHistory();
  const {userProfile} = useAuth();
  const backRoute = "../";
  const documentType = "driverslicence";
  const {errorHandler, clearError} = useErrorHandler();
  const [australianState, setAustralianState] = useState("");

  const initialValues = {
    consent: false,
    australianState: "",
    dobDD: "",
    dobMM: "",
    dobYYYY: "",
    givenNames: userProfile.firstName,
    middleNames: "",
    familyName: userProfile.lastName,
    licenceNumber: "",
  };

  const validationSchema = Yup.object().shape({
    australianState: Yup.string().required().label('State of Issue'),
    dobDD: Yup.number().label('Day')
      .min(1)
      .max(31)
      .required("Please enter a valid day (DD)"),
    dobMM: Yup.number().label('Month')
      .min(1)
      .max(12)
      .required("Please enter a valid month (MM)"),
    dobYYYY: Yup.number().label('Year')
      .min(1900)
      .max(new Date().getFullYear())
      .required("Please enter a valid year (YYYY)"),
    givenNames: Yup.string().max(60).label('Given Name'),
    middleNames: Yup.string().max(60).label('Middle Name'),
    familyName: Yup.string().max(60).label("Family Name")
      .when("givenNames", {
        is: (givenNames) => !givenNames || givenNames.length === 0,
        then: Yup.string().required("Given Name or Family Name is mandatory"),
      }),
    licenceNumber: Yup.string().required().max(10).label("Licence Number"),
    cardNumber: Yup.string().required().max(10).label("Card Number")
  });

  function handleAustralianState(value) {
    setAustralianState(value);
  }

  function onSubmit(fields, { setStatus, setSubmitting, resetForm }) {
    setStatus();
    clearError();

    const document = {
      consentToDocumentCheck: fields.consent,
      state: fields.australianState,
      givenName: fields.givenNames,
      middleName: fields.middleNames,
      familyName: fields.familyName,
      birthDateDD: fields.dobDD,
      birthDateMM: fields.dobMM,
      birthDateYYYY: fields.dobYYYY,
      licenceNumber: fields.licenceNumber,
      cardNumber: fields.cardNumber,
    };

    IdentityService.validateDocument(documentType, document)
      .then((result) => {
        setSubmitting(false);
        resetForm();
        router.push(backRoute);
      })
      .catch((err) => {
        errorHandler(err, 'Drivers Licence');
        setStatus({ status: "error" });
        setSubmitting(false);
      });
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ errors, touched, isSubmitting, setFieldValue }) => {
        return (
          <Form className="sds-form">
            <div className="row">
              <div className="col-sm-12">
                <SDSlandingPageTitle
                  icon={<span className="fas fa-user-circle fa-4x" />}
                  byLine="Please provide Drivers Licence details"
                >
                  Drivers Licence Details
                </SDSlandingPageTitle>
              </div>
            </div>

            <SDSpanel>
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="australianState">
                      <strong>State of Issue *</strong>
                    </label>
                    <div className="form-row">
                      <div className="col-xs-12">
                      <SelectState
                          name="australianState"
                          onStateChange={handleAustralianState}
                          className={
                            "form-control" +
                            (errors.australianState && touched.australianState
                              ? " is-invalid"
                              : "")
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <label htmlFor="givenNames">
                      <strong>Given Name/s </strong>
                    </label>
                    <div className="form-row">
                      <div className="col-xs-12">
                        <Field
                          id="givenNames"
                          name="givenNames"
                          type="text"
                          as="input"
                          className={
                            "form-control" +
                            (errors.givenNames && touched.givenNames
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="givenNames"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <label htmlFor="middleNames">
                      <strong>Middle Name/s </strong>
                    </label>
                    <div className="form-row">
                      <div className="col-xs-12">
                        <Field
                          name="middleNames"
                          id="middleNames"
                          type="text"
                          as="input"
                          className={
                            "form-control" +
                            (errors.middleNames && touched.middleNames
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="middleNames"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <label htmlFor="familyName">
                      <strong>Family Name </strong>
                    </label>
                    <div className="form-row">
                      <div className="col-xs-12">
                        <Field
                          name="familyName"
                          id="familyName"
                          type="text"
                          as="input"
                          className={
                            "form-control" +
                            (errors.familyName && touched.familyName
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="familyName"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                  </div>
                  
                  <DateOfBirth
                      errors={errors}
                      touched={touched}
                      required="true"
                    />

                  <div className="form-group">
                    <label htmlFor="licenceNumber">
                      <strong>Licence Number *</strong>
                    </label>
                    <div className="form-row">
                      <div className="col-xs-12">
                        <Field
                          name="licenceNumber"
                          id="licenceNumber"
                          type="text"
                          as="input"
                          maxLength="10"
                          className={
                            "form-control" +
                            (errors.licenceNumber && touched.licenceNumber
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="licenceNumber"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <label htmlFor="cardNumber">
                      <strong>Card Number *</strong>
                    </label>
                    <div className="form-row">
                      <div className="col-xs-12">
                        <Field
                          name="cardNumber"
                          id="cardNumber"
                          type="text"
                          as="input"
                          maxLength="10"
                          className={
                            "form-control" +
                            (errors.cardNumber && touched.cardNumber
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="cardNumber"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                  </div>

                  <DocumentSubmitMessage name="consent" errors={errors} />
                </div>
                <div className="col-lg-6">
                  {
                    australianState &&
                    <>
                      <img className="identity-doc-sample" src={`${process.env.PUBLIC_URL}/images/identity/dl_${australianState.toLowerCase()}_front.jpg`} alt="" />
                      <img className="identity-doc-sample" src={`${process.env.PUBLIC_URL}/images/identity/dl_${australianState.toLowerCase()}_back.jpg`} alt="" />
                    </>
                  }
                </div>
              </div>

              <DocumentSubmitButtons backRoute={backRoute} isSubmitting={isSubmitting} />
            </SDSpanel>
          </Form>
        );
      }}
    </Formik>
  );
};

export default DriversLicence;
