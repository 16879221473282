import React from "react"
import SDSpanel from "../../../components/panel/panel";
import { withAuth } from "../../../security/authorisation";
import {SDSlandingPageTitle} from "../../../components/page-title/page-title";
import Button from "@sportaus-digital/buttons";
import keycloak from "../../../security/keycloak";
import {replace} from "lodash";
import * as Terms from "../../../constants/terms"

const SportAusConnectDashboard = () => {
    const passwordAction = <Button link={replace(keycloak.createAccountUrl(), "/account?", "/account/password?")}
                                   className="sds-btn--block"
                                   as="tertiary">Change your password</Button>;


    return <div>
        <div className="row">
            <div className="col-sm-12">
                <SDSlandingPageTitle icon={<span className="fas fa-user-circle fa-4x"/>}
                                     byLine={`Manage your ${Terms.PRODUCT_NAME}`}>
                    {Terms.PRODUCT_NAME}
                </SDSlandingPageTitle>
            </div>
        </div>
        <div className="row panel-container">
            <div className="col-xs-12 col-sm-6">
                <SDSpanel title="Change your password" action={passwordAction}>
                    Change your {Terms.PRODUCT_NAME} password.
                </SDSpanel>
            </div>
        </div>
    </div>;
};

SportAusConnectDashboard.propTypes = {};

export default withAuth(SportAusConnectDashboard);