import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import {OrganisationService} from "../../../../../service/service";
import {SDSsubPageTitle} from "../../../../../components/page-title/page-title";
import SDSpanel from "../../../../../components/panel/panel";
import OrganisationForm from "../../../../../containers/organisaction-form/organisation-form";
import {useHistory} from "react-router";
import {SportFunctionLabelMapper} from "../../../../../utils/sport-function-label-mapper";
import {withAuth} from "../../../../../security/authorisation";
import useErrorHandler from "../../../../../hooks/error-handler";


const OrganisationEdit = ({orgId}) => {
    const [organisation, setOrganisation] = useState({});
    const {errorHandler} = useErrorHandler();
    const router = useHistory();
    const backRoute = `../${orgId}`;

    useEffect(() => {
        OrganisationService.getOrganisation(orgId).then(org => {
            if (org.primaryLocation) {
                org.addressLine1 = org.primaryLocation.addressLine1;
                org.addressLine2 = org.primaryLocation.addressLine2;
                org.state = org.primaryLocation.state;
                org.country = org.primaryLocation.country;
                org.postcode = org.primaryLocation.postcode;
                org.suburb = org.primaryLocation.suburb;
                org.localityType = org.primaryLocation.type;
                org.latitude = org.primaryLocation.latitude;
                org.longitude = org.primaryLocation.longitude;
            }
            org.sports = {};
            let sports = [];
            let functions = [];
            (org.functions || []).forEach(f => {
                if (!f.systemAssigned) {
                    functions.push(f.code);
                    if (f.sportSpecific) {
                        sports.push({
                            function: f.code,
                            sports: f.sports.map(s => s.externalId),
                            label: SportFunctionLabelMapper(f.code, f.name)
                        });
                    }
                }
            });
            org.functions = functions;
            org.sports = sports;
            setOrganisation(org);
        }).catch((e) => {
            errorHandler(e, "retrieving the organisation");
        })
    }, [orgId, setOrganisation, errorHandler]);

    const submitHandler = (org, actions) => {
        org = {...org};
        let functions = org.functions.map(f => ({code: f}));
        org.sports.forEach(s => {
            let fn = functions.find(f => f.code === s.function);
            fn.sports = (s.sports || []).map(s2 => ({externalId: s2}));
        });

        delete org.sports;

        org.functions = functions;

        org.primaryLocation = {
            type: org.localityType,
            postcode: org.postcode,
            addressLine1: org.addressLine1,
            addressLine2: org.addressLine2,
            suburb: org.suburb,
            state: org.state,
            country: org.country,
            latitude: org.latitude,
            longitude: org.longitude
        };
        OrganisationService.updateOrganisation(org).then(() => {
            actions.setSubmitting(false);
            router.push(backRoute);
        }).catch(() => {
            actions.setStatus({status: "error"});
            actions.setSubmitting(false);
        });

    };

    const byLineText = 'Update the details of your organisation';
    return <div
        className="row px-2 d-md-flex flex-row flex-wrap align-content-stretch justify-content-between align-items-stretch">
        <div className="col-sm-12">
            <SDSsubPageTitle byLine={byLineText} backButtonRoute={backRoute}> Update organisation
                details</SDSsubPageTitle>
        </div>
        <div className="col-sm-12 align-self-stretch mb-4">
            <SDSpanel>
                <OrganisationForm submitHandler={submitHandler} cancelRoute={backRoute}
                                  currentOrganisation={organisation}/>
            </SDSpanel>
        </div>
    </div>;
};

OrganisationEdit.propTypes = {
    orgId: PropTypes.number.isRequired
};

export default withAuth(OrganisationEdit);
