import React, {FC, useState} from "react"
import "./pagination.scss"
import Button from "@sportaus-digital/buttons";

/**
 * A simple pagination control for paged datasets.
 *
 * NOTE: Pages are 0-indexed - i.e. the first page is page 0
 *
 * @requires Button
 */
export const Pagination: FC<PaginationProps> = ({
                                             onPageChange,
                                             pageSize = 10,
                                             totalRecords,
                                             showPageCount = true,
                                             showRecordCount = true,
                                             hideControlsForSinglePage = true
                                         }: PaginationProps) => {
    const [page, setPage] = useState(0);
    const totalPages = Math.ceil(totalRecords / pageSize);

    const changePage = (page: number) => {
        setPage(page);
        onPageChange(page);
    };

    let showPages = showPageCount
        && totalPages > 0
        && (!hideControlsForSinglePage || totalPages > 1);

    return <div className="sds-pagination">
        <div className="sds-pagination__left">
            {showPages &&
            <div className="sds-pagination__pageCount">
                <div>Page</div>
                <div>{page + 1} of {totalPages}</div>
            </div>}
        </div>
        <div className="sds-pagination__centre">
            {(!hideControlsForSinglePage || totalPages > 1) &&
            <>
                <Button as="tertiary"
                        className="sds-pagination__pageControl sds-pagination__pageControl--first"
                        onClick={() => changePage(0)}
                        disabled={page <= 0}>
                    First
                </Button>
                <span className="sds-pagination__separator"/>
                <Button as="tertiary"
                        className="sds-pagination__pageControl sds-pagination__pageControl--prev"
                        onClick={() => changePage(page - 1)}
                        disabled={page <= 0}>
                    Prev
                </Button>
                <span className="sds-pagination__separator"/>
                <Button as="tertiary"
                        className="sds-pagination__pageControl sds-pagination__pageControl--next"
                        onClick={() => changePage(page + 1)}
                        disabled={page >= totalPages - 1}>
                    Next
                </Button>
                <span className="sds-pagination__separator"/>
                <Button as="tertiary"
                        className="sds-pagination__pageControl sds-pagination__pageControl--last"
                        onClick={() => changePage(totalPages - 1)}
                        disabled={page >= totalPages - 1}>
                    Last
                </Button>
            </>}
        </div>
        <div className="sds-pagination__right">
            {
                showRecordCount && totalRecords > 0 &&
                <div className="sds-pagination__recordCount">
                    <div>Showing</div>
                    <div>{page * pageSize + 1} to {Math.min(totalRecords, page * pageSize + pageSize)} of {totalRecords}</div>
                </div>
            }
        </div>
    </div>
};

export interface PaginationProps {
    /**
     * Function to be called when the page is changed. Takes the NEW page number as the single parameter.
     */
    onPageChange: (newPage: number) => void,
    /**
     * The page size
     *
     * @default 10
     */
    pageSize?: number,
    /**
     * The total number of records in the dataset
     */
    totalRecords: number,
    /**
     * True to show "Page x of y" to the left of the page controls
     *
     * @default true
     */
    showPageCount?: boolean,
    /**
     * True to show "Showing x to y of z" to the right of the page controls
     *
     * @default true
     */
    showRecordCount?: boolean,
    /**
     * Hides the "Page x of y" and paging controls when there is only 1 page.
     * The record could will still be shown unless explicitly hidden using showRecordCount={false}
     *
     * @default true
     */
    hideControlsForSinglePage?: boolean
}

export default Pagination;