import React, {FC, ReactElement} from "react";
import "./label.scss";

export const Label: FC<LabelProps> = ({
                                          htmlFor,
                                          label,
                                          className,
                                          required,
                                          requiredIndicator = '*'
                                      }: LabelProps) => {
    let labelVal = label;
    if (typeof label === "function") {
        labelVal = label();
    }

    return <label htmlFor={htmlFor} className={"sds-label " + (className || "")}>
        {labelVal}
        {required && <span className="sds-label__req-ind">{requiredIndicator}</span>}
    </label>;
};

export interface LabelProps {
    /**
     * The 'for' attribute of the label
     */
    htmlFor: string,
    /**
     * The label content. Can be plain text, a React component, or a no-arg function that returns either.
     */
    label: string | ReactElement | (() => string | ReactElement),
    /**
     * Optional class names to append to the label.
     */
    className?: string,
    /**
     * True if the corresponding field is required. This will result in rendering the requiredIndicator after the label.
     *
     * @default false
     */
    required?: boolean,
    /**
     * The required field indicator. Can be either a string or a component. This will be rendered after the label if required = true.
     *
     * @default *
     */
    requiredIndicator?: string | ReactElement
}