import React, {FC, ReactElement, useState} from "react";
import {AccordionButton, AccordionItem, AccordionPanel, Accordion as ReachAccordion} from "@reach/accordion";
import "@reach/accordion/styles.css";
import "./accordion.scss"

/**
 * Simple accordion component to show/hide content
 */
export const Accordion: FC<AccordionProps> = React.memo(({
                                                             expandLink,
                                                             collapseLink,
                                                             linkLevel ,
                                                             startOpen,
                                                             openIndicator = <span>&#x25B2;</span>,
                                                             closedIndicator = <span>&#x25BC;</span>,
                                                             children
                                                         }: AccordionProps) => {
    const [isOpen, setIsOpen] = useState(startOpen);

    const Header: any = linkLevel ? "h" + (linkLevel) : "span";

    return <div className="sds-accordion">
        <ReachAccordion collapsible
                        defaultIndex={startOpen ? 0 : -1}
                        onChange={() => setIsOpen(currentState => !currentState)}>
            <AccordionItem className="sds-accordion__item">
                <Header>
                    <AccordionButton className="sds-accordion__link">
                        {isOpen ? collapseLink || expandLink : expandLink}
                        {isOpen
                            ? <span className="sds_accordion__indicator sds_accordion__indicator--open"
                                    aria-hidden>{openIndicator}</span>
                            : <span className="sds_accordion__indicator sds_accordion__indicator--closed"
                                    aria-hidden>{closedIndicator}</span>
                        }
                    </AccordionButton>
                </Header>
                <AccordionPanel className="sds-accordion__panel">
                    {children}
                </AccordionPanel>
            </AccordionItem>
        </ReachAccordion>
    </div>;
});

export interface AccordionProps {
    /**
     * Text to display in the link to expand the accordion.
     */
    expandLink: string,
    /**
     * Text to display in the link to collapse the accordion. Defaults to the expandLink if not provided.
     *
     * @default expandLink prop
     */
    collapseLink?: string,
    /**
     * The header level to wrap the open/close link. If not provided, then the link will be wrapped in a simple span.
     */
    linkLevel?: number,
    /**
     * True to open the accordion by default
     */
    startOpen?: boolean,
    /**
     * Component to show beside the link to indicate that the accordion is open
     *
     * @default <span>&#x25B2;</span>
     */
    openIndicator?: ReactElement,
    /**
     * Component to show beside the link to indicate that the accordion is closed
     *
     * @default <span>&#x25BC;</span>
     */
    closedIndicator?: ReactElement,
    /**
     * The content of the collapsible area
     */
    children: any
}

export default Accordion;