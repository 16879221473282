import React, {Fragment, useEffect, useState} from "react"
import {Actions, withAuth} from "../../../../security/authorisation";
import SDSform, {FormBuilder} from "../../../../components/form/form";
import {SportService} from "../../../../service/service";
import DataTable from "@sportaus-digital/data-table";
import Spinner from "@sportaus-digital/spinner";
import SDSpanel from "../../../../components/panel/panel";
import {SDSlandingPageTitle} from "../../../../components/page-title/page-title";
import _ from "lodash"
import ConfirmBtn from "@sportaus-digital/confirm-btn";
import Button from "@sportaus-digital/buttons";
import useErrorHandler from "../../../../hooks/error-handler";

const SportsAdmin = () => {
    const [sports, setSports] = useState([]);
    const [selectedSport, setSelectedSport] = useState({});
    const {errorHandler} = useErrorHandler();

    useEffect(() => {
        SportService.getSports().then((data) => {
            setSports(data);
        }).catch((e) => {
            errorHandler(e, "retrieving the list of sports");
        })
    }, [errorHandler]);

    const form = new FormBuilder()
        .field("name").required().value(selectedSport.name)
        .field("externalId", "Identifier").required().value(selectedSport.externalId)
        .disabled(() => !_.isUndefined(selectedSport.externalId))
        .build();

    const submitHandler = (sport, actions) => {
        if (_.some(sports, s => s.id !== selectedSport.id && s.externalId === _.toUpper(sport.externalId))) {
            actions.setStatus({
                status: "error",
                message: "A sport with external id " + _.toUpper(sport.externalId) + " already exists"
            });
            actions.setSubmitting(false);
        } else if (selectedSport && selectedSport.id) {
            SportService.updateSport(selectedSport.id, sport).then((result) => {
                actions.setSubmitting(false);
                let updatedSports = sports.slice();
                let index = _.findIndex(updatedSports, (r) => r.id === selectedSport.id);
                let existing = {...updatedSports[index]}; // copy so we don't directly update the state
                existing.name = result.name;
                existing.externalId = result.externalId;
                updatedSports[index] = existing;
                setSelectedSport({});
                setSports(updatedSports);
            }).catch(() => {
                actions.setStatus({status: "error"});
                actions.setSubmitting(false);
            });
        } else {
            SportService.createSport(sport).then((result) => {
                actions.setSubmitting(false);
                let updatedSports = sports.slice();
                updatedSports.push(result);
                setSports(updatedSports);
                actions.resetForm();
            }).catch(() => {
                actions.setStatus({status: "error"});
                actions.setSubmitting(false);
            });
        }
    };

    const cancelHandler = () => {
        setSelectedSport({});
    };

    const deleteHandler = (sport) => {
        SportService.deleteSport(sport).then(() => {
            let updatedSports = sports.slice();
            updatedSports.splice(updatedSports.indexOf(sport), 1);
            setSports(updatedSports);
        });
    };

    const headers = [
        {title: "Name", key: "name", width: "33", filterable: true, sortable: true},
        {title: "External ID", key: "externalId", width: "33", filterable: true, sortable: true},
        {
            title: "", key: "", width: "33", render: (data, row) => {
                return <Fragment>
                    <ConfirmBtn as={"tertiary"} onClick={() => deleteHandler(row)}
                                disabled={selectedSport.id}>Delete</ConfirmBtn>
                    <Button as={"tertiary"} onClick={() => setSelectedSport(row)}
                            disabled={selectedSport.id}>Edit</Button>
                </Fragment>
            }
        }
    ];

    return <div className="row">
        <div className="col-sm-12">
            <SDSlandingPageTitle icon={<span className="fa fa-running fa-4x"/>}
                                byLine='Sports officially recognised bt the Australian Sports Commission'>
                Sports
            </SDSlandingPageTitle>
        </div>
        <div className="col-sm-6">
            <SDSpanel title="Sports" titleLevel={2}>
                <DataTable headers={headers}
                           spinner={<Spinner hideText={true} inline={true}/>}
                           data={sports}
                           defaultSort="name"
                           pageSize={20}/>
            </SDSpanel>
        </div>
        {(!selectedSport || !selectedSport.id) && <div className="col-sm-6">
            <SDSpanel title="Add a new sport" titleLevel={2}>
                <SDSform config={form}
                         progressIndicator={<span className="fa fa-spinner fa-spin ml-1 mr-1"/>}
                         submitHandler={submitHandler}/>
            </SDSpanel>
        </div>}
        {(selectedSport && selectedSport.id) && <div className="col-sm-6">
            <SDSpanel title="Update sport" titleLevel={2}>
                <SDSform config={form}
                         progressIndicator={<span className="fa fa-spinner fa-spin ml-1 mr-1"/>}
                         cancelHandler={cancelHandler}
                         submitHandler={submitHandler}/>
            </SDSpanel>
        </div>}
    </div>;
};

SportsAdmin.propTypes = {};

export default withAuth(SportsAdmin, Actions.SPORT_MANAGE);