import React from 'react';
import PropTypes from 'prop-types';
import "./sac-footer.scss";
import AUfooter, {AUfooterNav, AUfooterEnd} from '@gov.au/footer';
import AUlinkList from "@gov.au/link-list";
import moment from "moment-timezone";
import _ from "lodash";

/**
 * Standard Sport Connect footer
 *
 * @example
 * <SACfooter minimal{false}/>
 */
const SACfooter = props => {
    let buildDate = moment.utc(window.envConfig.buildDate, 'DD/MM/YYYY hh:mm:ss');
    buildDate = moment.tz(buildDate, moment.tz.guess()).format('DD/MM/YYYY HH:mm:ss z');

    return (<div className="sac-footer">
        <AUfooter>
            {!props.minimal && <div className="container">
                <div className="row">
                    <div className="col-xs-12 col-sm-9">
                        <div className="row">
                            <AUfooterNav>
                                <div className="col-xs-12 col-sm-6 col-md-4">
                                    <h3 className="au-display-md">About</h3>
                                    <AUlinkList items={[
                                        {
                                            link: "https://www.ausport.gov.au/sportconnect",
                                            text: "Home"
                                        },
                                        {
                                            link: "https://www.sportaus.gov.au/legal_information/privacy_policy",
                                            text: "Privacy Policy"
                                        },
                                        {
                                            link: "https://www.sportaus.gov.au/legal_information/disclaimer",
                                            text: "Disclaimer"
                                        },
                                        {
                                            link: "https://www.ausport.gov.au/sportconnect/terms-of-service",
                                            text: "Terms of Service"
                                        },
                                    ]}/>
                                </div>
                                <div className="col-xs-12 col-sm-6 col-md-4">
                                    <h3 className="au-display-lg">Forms</h3>
                                    <AUlinkList items={[
                                        {
                                            link: "https://www.ausport.gov.au/sportconnect/contact-us",
                                            text: "Contact us"
                                        }
                                    ]}/>
                                </div>
                            </AUfooterNav>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-3">
                        <div className="row sac-footer__logos">
                            <div className="col-xs-12">
                                <a href="https://www.sportaus.gov.au/sportaus/about" className="sac-footer__logo">
                                    <img src="https://www.sportaus.gov.au/__data/assets/file/0010/674740/asc-crest.svg"
                                         width="168" alt="Australian Sports Commission" className="img-fluid"/>
                                </a>
                            </div>
                            <div className="col-xs-12">
                                <a href="https://ais.gov.au" className="sac-footer__logo">
                                    <img
                                        src="https://www.sportaus.gov.au/__data/assets/file/0009/674739/ais-crest.svg"
                                        width="168" alt="Australian Institute of Sport logo" className="img-fluid"/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}

            <AUfooterEnd>
                <div className="container">
                    <AUlinkList inline items={[
                        {
                            link: "https://www.sportaus.gov.au/legal_information/copyright",
                            text: "Copyright Australian Sports Commission"
                        }
                    ]}/>
                </div>

                {window.envConfig.buildVersion &&
                <div className="sac-footer__build pl-3 float-md-right float-sm-none mt-sm-4 mt-md-0 mt-lg-n1">
                    {!_.startsWith(_.toLower(window.envConfig.env), "prod") ? _.capitalize(window.envConfig.env) : ""} Build {window.envConfig.buildVersion} ({buildDate})
                </div>}
            </AUfooterEnd>
        </AUfooter>
    </div>);
};

SACfooter.propTypes = {
    /**
     * True to only show the bottom portion of the footer containing a horizontal list of links
     */
    minimal: PropTypes.bool
};

SACfooter.defaultProps = {
    minimal: false
};

export default SACfooter;