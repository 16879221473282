import React, {useContext} from "react"
import "./sac-header.scss";
import AUheader from '@gov.au/header';
import SAClogin from "../../security/sac-login";
import {AuthContext} from "../../security/auth-context";
import _ from "lodash"
import UserMenu from "../../security/user-menu";

const SACheader = () => {
    const userProfile = useContext(AuthContext);

    return (<AUheader className="sac-header">
        <EnvBadge/>
        <asc-header useCustomSubbrand>
            <div className="sac-header__login" slot="end">
                {
                    (!userProfile || !!!userProfile.authenticated) && <SAClogin/>
                }
                {
                    userProfile && !!userProfile.authenticated &&
                    <UserMenu/>
                }
            </div>
            <asc-sportconnect-logo slot="subbrand"></asc-sportconnect-logo>
        </asc-header>
    </AUheader>);
};

const EnvBadge = () => {

    let env = window.envConfig.env;
    if (_.startsWith(_.toLower(env), "prod")) {
        env = undefined;
    }

    return env ? <div className={`env env--${_.toLower(env)}`}>
        <div className="envLabel">
            <span className="label">{env}</span>
        </div>
    </div> : null;
};

export default SACheader;