import React, {useEffect} from "react";
import "./full-page-loader.scss"
import PropTypes from 'prop-types';
import Spinner from "@sportaus-digital/spinner";

/**
 * A component that displays a full-screen loading indicator containing a coloured spinner and optional logo
 *
 * @example
 * return <SDSfullPageLoader timeoutHandler={handler}></SDSfullPageLoader>
 */
const SDSfullPageLoader = (props) => {
    const timeout = props.timeoutMillis || 15000;
    const timeoutHandler = props.timeoutHandler;

    useEffect(() => {
        const timer = setTimeout(() => {
            timeoutHandler();
        }, timeout);

        return () => clearTimeout(timer);
    }, [timeoutHandler, timeout]);

    return <div className="full-page-loader">
        <div className="full-page-loader_container">
            {
                props.logo &&
                <img className="full-page-loader__logo" src={props.logo} alt={props.logoAlt}/>
            }
            <Spinner size="lg" hideText={true}/>
        </div>
    </div>
};

SDSfullPageLoader.propTypes = {
    /**
     * Timeout Handler function
     */
    timeoutHandler: PropTypes.func.isRequired,
    /**
     * Timeout period in milliseconds
     */
    timeoutMillis: PropTypes.number,
    /**
     * Optional logo to display above the spinner
     */
    logo: PropTypes.string,
    /**
     * Alt text for the optional logo
     */
    logoAlt: PropTypes.string
};

SDSfullPageLoader.defaultProps = {
    timeoutMillis: 15000
};

export default SDSfullPageLoader;