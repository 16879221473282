import React, {FC} from "react";
import {AUradio} from "@gov.au/control-input";
import {StandardProps, classes, isDisabled} from "./_common";
import "./input-fields.scss";

export const RadioButton: FC<RadioButtonProps> = ({
                                                      id,
                                                      name,
                                                      value,
                                                      disabled,
                                                      onChange,
                                                      onBlur,
                                                      inline,
                                                      inputClasses,
                                                      ...rest
                                                  }: RadioButtonProps) => {
    return <AUradio {...rest}
                    id={id || name}
                    name={name || id}
                    value={value}
                    disabled={isDisabled(disabled)}
                    className={classes("sds-text-field sds-radio", inputClasses)}
                    onChange={onChange}
                    onBlur={onBlur}
                    block={!inline}
                    type="radio"/>;
};

export type RadioButtonProps = {
    label: string,
    value?: any
} & StandardProps;