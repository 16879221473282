import React from 'react';
import SACheader from "./containers/sac-header/sac-header";
import SACfooter from "./containers/sac-footer/sac-footer";
import {Route, Switch} from "react-router";
import {BrowserRouter as Router} from "react-router-dom";
import PublicHome from "./views/public-home/public-home";
import UserHome from "./views/user-home/user-home";
import AcceptInvitation from "./views/user-home/accept-invite/accept-invitation";
import PageNotFound from "./views/page-not-found";
import RouteScrollTo from "@sportaus-digital/route-scroll-to";

function App() {
    return (
        <main className="au-grid container-fixed-width">
            <a className="sr-only sr-only-focusable skippy" href="#content">Skip to main content</a>
            <div className="container-fluid">
                <Router>
                    <RouteScrollTo/>
                    <SACheader/>

                    <div className="sds-content" id="content">
                        <Switch>
                            <Route exact path="/"><PublicHome/></Route>
                            <Route path="/home"><UserHome/></Route>
                            <Route exact path="/invitation/:token/accept"
                                   render={props => <AcceptInvitation token={props.match.params.token}/>}/>
                            <Route component={PageNotFound}/>
                        </Switch>
                    </div>

                    <SACfooter minimal={false}/>
                </Router>
            </div>
        </main>
    );
}

export default App;
