import React, {useContext, useEffect, useState} from "react"
import PropTypes from "prop-types"
import {OrganisationPersonService, OrganisationService} from "../../../../../service/service";
import {SDSsubPageTitle} from "../../../../../components/page-title/page-title";
import SDSpanel from "../../../../../components/panel/panel";
import {PageAlertActionsContext} from "../../../../../utils/page-alerts";
import {Actions, ProtectedButton, withAuth} from "../../../../../security/authorisation";
import {FormBuilder} from "../../../../../components/form/form-builder";
import {FieldTypes} from "../../../../../components/form/field-builder";
import SDSform from "../../../../../components/form/form";
import {useHistory} from "react-router";

const OrganisationPersonEdit = ({orgId, personId}) => {
    const [organisation, setOrganisation] = useState({});
    const [person, setPerson] = useState({});
    const [orgRoles, setOrgRoles] = useState([]);
    const setPageAlert = useContext(PageAlertActionsContext);
    const router = useHistory();
    const backRoute = `../../${orgId}`;

    useEffect(() => {
        OrganisationService.getOrganisation(orgId).then(org => {
            setOrganisation(org);
            setOrgRoles(org.allRoles.map(r => ({label: r.role, value: r.id})));
        }).catch(() => {
            setPageAlert({
                severity: "error",
                message: "An unexpected error occurred while retrieving the organisation"
            });
        });
        OrganisationPersonService.getOrganisationPerson(orgId,personId).then(orgPerson => {
            setPerson(orgPerson);
        }).catch(() => {
            setPageAlert({
                severity: "error",
                message: "An unexpected error occurred while retrieving the person"
            });
        });
    }, [orgId, personId, setOrganisation, setPageAlert, setPerson, setOrgRoles]);

    let existingRoles = person.roles ? person.roles : [];

    const form = new FormBuilder()
        .field("roles", "Role(s)", FieldTypes.SELECT)
        .value(existingRoles.map(r => r.id))
        .options(orgRoles)
        .multiple().required()
        .renderAfter(<div className="text-right mb-5">
            <ProtectedButton action={Actions.ORG_LIST_ALL_ROLES}
                             orgId={orgId}
                             className="mb-3"
                             route={'../roles'}>Manage roles</ProtectedButton>
        </div>)
        .build();

    const submitHandler = (role, actions) => {
        OrganisationPersonService.updateOrganisationPerson(orgId, personId, role.roles).then(() => {
            actions.setSubmitting(false);
            actions.resetForm();
            router.push(backRoute);
        }).catch(() => {
            actions.setStatus({status: "error"});
            actions.setSubmitting(false);
        });
    };

    const byLineText = <>Update a persons role for <em>{organisation.name}</em></>;

    return <div className="row px-2 d-md-flex flex-row flex-wrap align-content-stretch justify-content-between align-items-stretch">
        <div className="col-sm-12">
            <SDSsubPageTitle byLine={byLineText} backButtonRoute={backRoute}> Update a person's role</SDSsubPageTitle>
        </div>
        <div className="col-sm-12 align-self-stretch mb-4">
            <SDSpanel title={person.firstName + " " + person.lastName} titleLevel={2}>
                <SDSform config={form}
                         progressIndicator={<span className="fa fa-spinner fa-spin ml-1 mr-1"/>}
                         submitHandler={submitHandler}
                         cancelRoute={backRoute} />
        </SDSpanel>
        </div>
    </div>;
};


OrganisationPersonEdit.propTypes = {
    orgId: PropTypes.number.isRequired,
    personId: PropTypes.number.isRequired
};

export default withAuth(OrganisationPersonEdit);