import React, {useEffect, useState} from "react"
import {Actions, ProtectedButton} from "../../../../../security/authorisation";
import SDSpanel from "../../../../../components/panel/panel";
import {useRouteMatch} from "react-router";
import {WebhookService} from "../../../../../service/service";
import useErrorHandler from "../../../../../hooks/error-handler";
import Card from "@sportaus-digital/card";
import WebhookDetails from "../../../../../containers/app-management/webhook-details";

const OrgWebhooks = ({orgId, appId}) => {
    const {url} = useRouteMatch();
    const [webhooks, setWebhooks] = useState([]);
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    const {errorHandler} = useErrorHandler();

    useEffect(() => {
        WebhookService.getWebhooksForApplication(orgId, appId)
            .then(resp => {
                setWebhooks(resp.webhooks);
                setEvents(resp.events);
            })
            .catch((e) => errorHandler(e, "retrieving your webhooks"))
            .finally(() => setLoading(false))
    }, [orgId, appId, setWebhooks, setEvents, errorHandler, setLoading]);

    return <SDSpanel title="Webhooks"
                     action={<ProtectedButton action={Actions.ORG_APPLICATIONS_CREATE_WEBHOOK}
                                              orgId={orgId}
                                              className="sds-btn--block"
                                              route={{
                                                  pathname: `${url}/webhook/create`,
                                                  state: {
                                                      webhooks: webhooks,
                                                      events: events
                                                  }
                                              }}
                                              as="tertiary">Register a new webhook</ProtectedButton>}>
        <div>
            <div className="row panel-container">
                {!loading && webhooks && webhooks.map(c => <Webhook webhook={c}
                                                                    orgId={orgId}
                                                                    events={events}
                                                                    key={c.identifier}/>)}
            </div>
        </div>
    </SDSpanel>;
};

const Webhook = ({webhook, orgId, events}) => {
    let {url} = useRouteMatch();

    const action = <ProtectedButton action={Actions.ORG_APPLICATIONS_UPDATE_WEBHOOK}
                                    orgId={orgId}
                                    route={{
                                        pathname: `${url}/webhook/${webhook.identifier}`,
                                        state: {webhook: webhook, events: events}
                                    }}
                                    as="primary">
        Manage webhook
    </ProtectedButton>;

    return <div className=" col-lg-6 col-md-6 col-sm-12">
        <Card title={webhook.name} className="webhook-card" actions={[action]}>
            <WebhookDetails webhook={webhook}
                            eventLabelMapper={(eventCode) => (events.find(e => e.identifier === eventCode) || {}).description}/>
        </Card>
    </div>;
};

OrgWebhooks.propTypes = {};

export default OrgWebhooks;