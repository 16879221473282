import React from "react"
import SDSpanel from "../../../components/panel/panel";
import {Actions, Protected, withAuth, useAuth} from "../../../security/authorisation";
import {SDSlandingPageTitle} from "../../../components/page-title/page-title";
import {useRouteMatch} from "react-router";
import { Link } from "react-router-dom";
import * as Terms from "../../../constants/terms"

const AdminHome = () => {
    const {isAdmin} = useAuth();
    const {path} = useRouteMatch();

    return <div>
        <div className="row">
            <div className="col-sm-12">
                <SDSlandingPageTitle icon={<span className="fas fa-user-circle fa-4x"/>}
                                     byLine={`${Terms.PRODUCT_NAME} administration.`}>
                    Administration
                </SDSlandingPageTitle>
            </div>
        </div>
        <div className="row panel-container">
            <Protected action={isAdmin ? Actions.ORG_LIST_ALL : Actions.ORG_LIST_OWN}>
                <div className="col-xs-12 col-sm-6">
                    <SDSpanel title="Organisations"
                            action={
                        <Link to={`${path}/organisations`}
                            className="au-btn sds-button sds-btn--block sds-button--tertiary">
                            View details
                        </Link>
                    }>
                    </SDSpanel>
                </div>
            </Protected>

            <Protected action={Actions.SPORT_MANAGE}>
                <div className="col-xs-12 col-sm-6">
                    <SDSpanel title="Sports"
                            action={
                        <Link to={`${path}/sports`}
                            className="au-btn sds-button sds-btn--block sds-button--tertiary">
                            View details
                        </Link>
                    }>
                    </SDSpanel>
            </div>
            </Protected>

            <Protected action={Actions.API_KEY_ADMIN}>
                <div className="col-xs-12 col-sm-6">
                    <SDSpanel title="Realms"
                        action={
                            <Link to={`${path}/realms`}
                                    className="au-btn sds-button sds-btn--block sds-button--tertiary">
                                View details
                            </Link>
                        }>
                    </SDSpanel>
                </div>
            </Protected>
        </div>
    </div>;
};

AdminHome.propTypes = {};

export default withAuth(AdminHome);