import React from "react"
import {SDSsubPageTitle} from "../../../components/page-title/page-title";
import SDSpanel from "../../../components/panel/panel";
import OrganisationForm from "../../../containers/organisaction-form/organisation-form";
import {useHistory} from "react-router";
import {OrganisationService} from "../../../service/service";
import {Actions, useAuth, withAuth} from "../../../security/authorisation";
import * as Terms from "../../../constants/terms"

const OrganisationAdd = () => {
    const router = useHistory();
    const backRoute = "../organisations"; // back to the organisations page
    const onSaveRoutePrefix = "../organisations/"; // add the org id to route to the view page
    const {isAdmin, updateUserProfile} = useAuth();

    const submitHandler = (org, actions) => {
        org = {...org};
        let functions = org.functions.map(f => ({code: f}));
        org.sports.forEach(s => {
            let fn = functions.find(f => f.code === s.function);
            fn.sports = (s.sports || []).map(s2 => ({externalId: s2}));
        });
        delete org.sports;

        org.functions = functions;

        org.primaryLocation = {
            type: org.localityType,
            addressLine1: org.addressLine1,
            addressLine2: org.addressLine2,
            postcode: org.postcode,
            suburb: org.suburb,
            state: org.state,
            country: org.country
        };

        let service;
        if (isAdmin) {
            service = OrganisationService.createStandaloneOrganisation;
        } else {
            service = OrganisationService.createOrganisationForMe;
        }

        service(org).then((newOrg) => {
            actions.setSubmitting(false);
            updateUserProfile();
            router.push(onSaveRoutePrefix + newOrg.id);
        }).catch(() => {
            actions.setStatus({status: "error"});
            actions.setSubmitting(false);
        });
    };

    const byLineText = `Add an organisation to ${Terms.PRODUCT_NAME} such as sport organisations, technology, ` +
        'facility providers and others';

    return <div className="row px-2 d-md-flex flex-row flex-wrap align-content-stretch justify-content-between align-items-stretch">
            <div className="col-sm-12">
                <SDSsubPageTitle byLine={byLineText} backButtonRoute={backRoute}>Add an organisation</SDSsubPageTitle>
            </div>
            <div className="col-sm-12 align-self-stretch mb-4">
                <SDSpanel>
                    <OrganisationForm currentOrganisation={{sports: {}}} submitHandler={submitHandler} cancelRoute={backRoute}/>
                </SDSpanel>
            </div>
        </div>;
};

OrganisationAdd.propTypes = {
};

export default withAuth(OrganisationAdd, [Actions.ORG_CREATE, Actions.AFFILIATED_ORG_CREATE]);
