import React, {useEffect, useState} from "react"
import {useAuth, withAuth} from "../../../security/authorisation";
import {RoleInvitationService} from "../../../service/service";
import {Redirect} from "react-router";
import Spinner from "@sportaus-digital/spinner";
import Button from "@sportaus-digital/buttons";
import Alert from "@sportaus-digital/alert";
import {SDSsubPageTitle} from "../../../components/page-title/page-title";

const AcceptInvitation = ({token}) => {
    const {updateUserProfile} = useAuth();
    const [loading, setLoading] = useState(true);
    const [redirect, setRedirect] = useState();
    const [error, setError] = useState();

    useEffect(() => {
        let timer = undefined;
        if (!redirect) {
            RoleInvitationService.acceptInvitation(token).then((result) => {
                let redirect = "/home/organisations/" + result.organisation.id;
                updateUserProfile().then(() => {
                    if (result.needsCompleting) {
                        redirect += "/edit";
                    }
                });

                timer = setTimeout(() => {
                    setRedirect(redirect);
                    setLoading(false);
                }, 5000); // wait a bit so the user can see the message
            }).catch((error) => {
                if (error.response && error.response.status === 400
                    && error.response.data && error.response.data.reason) {
                    setError(error.response.data.reason);
                } else {
                    setError("UNEXPECTED");
                }
                setLoading(false);
            });
        }
        return timer;
    }, [token, setLoading, redirect, setRedirect, setError, updateUserProfile]);

    return <div className="container-fluid sds-container__self-service">
        <div className="row">
            <div className="container-content">
                <div className="col-sm-12">
                    <SDSsubPageTitle
                        byLine="Please wait while we verify your invitation. If everything checks out then you will be automatically redirected to your new organisation.">
                        Verifying...
                    </SDSsubPageTitle>
                </div>
                <div className="col-sm-12">
                    {
                        loading
                            ? <Spinner size="lg" text="Please wait..."/>
                            : redirect
                            ? <Redirect to={redirect}/>
                            : error && (<div>
                                <Alert severity="error">
                                    {formatError(error)}
                                </Alert>
                            </div>)
                    }
                </div>
                <div className="col-sm-12 text-center mt-5">
                    <Button as="tertiary"
                               route="/home">
                        Take me home
                    </Button>
                </div>
            </div>
        </div>
    </div>;
};

AcceptInvitation.propTypes = {};

export default withAuth(AcceptInvitation);

const formatError = (error) => {
    let message;

    switch (error) {
        case "EMAIL_NOT_VERIFIED":
            message = <>
                <p>It looks like you haven’t verified your email address yet. Invitations can only be accepted when sent to a verified email address.</p>
                <p>Check your emails and click the link to verify your registered email address, then try the accept invitation link again.</p>
            </>;
            break;
        case "EMAILS_DO_NOT_MATCH":
            message = <>
                <p>It looks like your email address doesn’t match the email address to which the invitation was sent.</p>
                <p>Please contact your organisation manager and ask them to send another invitation to your registered email address.</p>
            </>;
            break;
        case "ALREADY_IN_ORG":
            message = <>
                <p>Nice one! It appears that you’re already a member of this organisation.</p>
                <p>
                <Button as="tertiary"
                           route="/home/organisations">
                    View your organisations here
                </Button></p>
            </>;
            break;
        case "INVITATION_EXPIRED":
            message = <>
                <p>Uh oh, this invitation has expired.</p>
                <p>Please contact your organisation manager and ask them to send another invitation to your registered email address.</p>
            </>;
            break;
        case "INVITATION_NOT_FOUND":
            message = <>
                <p>Well this is awkward, we couldn't find your invitation. It's possible you may have already accepted it.</p>
                <p><Button as="tertiary"
                              route="/home/organisations">
                    View your organisations
                </Button> to see if you’ve already accepted this invitation.</p>
            </>;
            break;
        default:
            message = <p>Well this is awkward, there was a problem at our end while verifying your invitation. Hold tight, we’re looking into the problem.</p>
    }

    return message;
};