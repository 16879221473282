import React, {useContext, useEffect, useState} from "react"
import {UserService} from "../../../../service/service";
import {PageAlertActionsContext} from "../../../../utils/page-alerts";
import SDSpanel from "../../../../components/panel/panel";
import {SDSsubPageTitle} from "../../../../components/page-title/page-title";
import Spinner from "@sportaus-digital/spinner";
import IdCard from "../../../../containers/identity/identity-card";
import {isEmpty} from "lodash";
import {Actions, Protected, ProtectedButton, withAuth} from "../../../../security/authorisation";
import ConfirmBtn from "@sportaus-digital/confirm-btn";
import Alert from "@sportaus-digital/alert";
import {useHistory} from "react-router";
import useErrorHandler from "../../../../hooks/error-handler";
import {FEDERATED_ID, TEMP_ID} from "../../../../constants/identity-categories";
import * as Terms from "../../../../constants/terms"

const UpdateIdentity = ({idHash, idObject}) => {
    const backUrl = '../identities';
    const homeUrl = '/home';
    const router = useHistory();
    const [identity, setIdentity] = useState();
    const [loading, setLoading] = useState(true);
    const setPageAlert = useContext(PageAlertActionsContext);
    const {errorHandler} = useErrorHandler();

    useEffect(() => {
        if (!idObject || isEmpty(idObject)) {
            UserService.getOtherIdentities()
                .then(ids => setIdentity(ids.find(i => i.hash === idHash || i.identifier === idHash)))
                .catch((e) => errorHandler(e, "retrieving your identities"))
                .finally(() => setLoading(false));
        } else {
            setIdentity(idObject);
            setLoading(false);
        }
    }, [idHash, idObject, setIdentity, setLoading, errorHandler]);

    const unlinkIdentity = () => {
        UserService.unlinkIdentity(identity)
            .then((id) => {
                setIdentity(id);
                setPageAlert({
                    title: "Success",
                    severity: "success",
                    message: `This identity is now unlinked from your ${Terms.PRODUCT_NAME} account`
                });
                router.push(id.category === FEDERATED_ID ? homeUrl : backUrl);
            })
            .catch((e) => errorHandler(e, "updating your identities"));
    };

    const approveClaim = () => {
        UserService.approveClaim(identity)
            .then((id) => {
                setIdentity(id);
                setPageAlert({
                    title: "Success",
                    severity: "success",
                    message: `This identity is now linked from your ${Terms.PRODUCT_NAME} account`
                });
            })
            .catch((e) => errorHandler(e, "updating your identities"))
    };

    const rejectClaim = () => {
        UserService.rejectClaim(identity)
            .then((id) => {
                setIdentity(id);
                setPageAlert({
                    title: "Success",
                    severity: "success",
                    message: `This identity is now unlinked from your ${Terms.PRODUCT_NAME} account`
                });
            })
            .catch((e) => errorHandler(e, "updating your identities"))
    };

    const actions = identity ? <div className="text-right m-4">
        {
            identity.category === FEDERATED_ID && identity.status === "LINKED"
            && <Protected action={Actions.USER_PROFILE_UPDATE_IDENTITIES}>
                <ConfirmBtn onClick={unlinkIdentity}
                               as="secondary">
                    Remove this identity
                </ConfirmBtn>
            </Protected>
        }
        {
            identity.category === TEMP_ID && (identity.status === "PENDING" || identity.status === "LINKED")
            && <ProtectedButton action={Actions.USER_PROFILE_UPDATE_IDENTITIES}
                                as="secondary"
                                onClick={rejectClaim}>
                No, this is not me
            </ProtectedButton>
        }
        {
            identity.category === TEMP_ID && (identity.status === "PENDING" || identity.status === "REVOKED" || identity.status === "DECLINED")
            && <ProtectedButton action={Actions.USER_PROFILE_UPDATE_IDENTITIES}
                                onClick={approveClaim}>
                Yes, this is me
            </ProtectedButton>
        }
    </div> : null;

    return (loading
        ? <Spinner/>
        : <div
            className="row user-identities px-2 d-md-flex flex-row flex-wrap align-content-stretch justify-content-between align-items-stretch">
            <div className="col-sm-12">
                <SDSsubPageTitle byLine={`Manage an identity linked with your ${Terms.PRODUCT_NAME} account`}
                                 backButtonRoute={`/home/account/linkedaccounts`}>
                    Manage an identity</SDSsubPageTitle>
            </div>
            {<div className="col-sm-12">
                <SDSpanel action={actions}>
                    {
                        identity ? (
                        <div className="row">
                            <div className="col-sm-6 col-xs-12">
                                <IdCard identity={identity}
                                        noBorder={true}
                                        showControls={false}/>
                            </div>
                        </div>
                        ) : <Alert message="Sorry, we can't seem to find this identifier!"/>
                    }
                </SDSpanel>
            </div>
            }
        </div>);
};

export default withAuth(UpdateIdentity, Actions.USER_PROFILE_UPDATE_IDENTITIES);