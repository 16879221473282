import React, {useContext} from "react"
import PropTypes from "prop-types"
import DataTable from "@sportaus-digital/data-table";
import Spinner from "@sportaus-digital/spinner";
import {useRouteMatch} from "react-router";
import "../organisation.scss"
import ConfirmBtn from "@sportaus-digital/confirm-btn";
import {RoleInvitationService} from "../../../../../service/service";
import {PageAlertActionsContext} from "../../../../../utils/page-alerts";
import {Actions, Protected} from "../../../../../security/authorisation";
import Button from "@sportaus-digital/buttons";
import * as Terms from "../../../../../constants/terms"

const OrganisationPersons = ({orgId, peopleAndInvitations, removedHandler}) => {
    const {url} = useRouteMatch();
    const setPageAlert = useContext(PageAlertActionsContext);

    const cancelInvitationHandler = (invitation) => {
        RoleInvitationService.cancelInvitation(orgId, invitation.id).then(() => {
            removedHandler(invitation.id);
        }).catch(() => {
            setPageAlert({severity: "error", message: "An unexpected error occurred while cancelling the invitation"});
        });
    };

    const headers = [
        {
            title: "Name", key: "formattedName", width: "50", filterable: true, sortable: true,
            render: (name, row) => {
                let result;
                if (row.invite) {
                    result = <>
                        {name}<span className="invitation-indicator">(Invited)</span>
                    </>;
                } else {
                    result = <Button route={`${url}/person/${row.id}`}>{name}</Button>;
                }
                return result;
            }
        },
        {
            title: "Role",
            width: "35",
            filterable: false,
            sortable: false,
            render: (colData, row) => (row.roles || []).map(r => r.role).join(", ")
        },
        {
            title: "Action",
            width: "15",
            render: (colData, row) => (row.invite ?
                <Protected orgId={orgId}
                           action={Actions.ORG_INVITE_USER}>
                    <ConfirmBtn onClick={() => cancelInvitationHandler(row)} as="tertiary"
                                message="Are you sure you wish to cancel this invitation?">
                        Cancel
                    </ConfirmBtn></Protected> : null)
        }
    ];

    return <div>
        People who can act in {Terms.PRODUCT_NAME} on behalf of this organisation
        <DataTable headers={headers}
                   spinner={<Spinner hideText={true} inline={true}/>}
                   data={peopleAndInvitations}
                   defaultSort="name"
                   pageSize={20}/>
    </div>
};

OrganisationPersons.propTypes = {
    orgId: PropTypes.number.isRequired,
    peopleAndInvitations: PropTypes.array.isRequired
};

export default OrganisationPersons;