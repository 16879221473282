import React, {useEffect, useState} from "react"
import {UserService} from "../../service/service";
import useErrorHandler from "../../hooks/error-handler";
import SDSpanel from "../../components/panel/panel";
import {Actions, Protected, ProtectedButton} from "../../security/authorisation";
import {useRouteMatch} from "react-router";
import {OTHER_ID, TEMP_ID} from "../../constants/identity-categories";
import {uniq, kebabCase} from "lodash";
import {deKebabCase} from "../../utils/string-utils";
import "./identity-card.scss";

const SportingIdentities = () => {
    const [sports, setSports] = useState([]);
    const {errorHandler} = useErrorHandler();
    let {path} = useRouteMatch();

    useEffect(() => {
        UserService.getOtherIdentities([TEMP_ID, OTHER_ID])
            .then(data => setSports(uniq(data.map(id => kebabCase(id.sport)))))
            .catch((e) => errorHandler(e, "retrieving your identities"))
    }, [setSports, errorHandler]);

    return sports && sports.length > 0 ? <Protected action={Actions.USER_PROFILE_LIST_IDENTITIES}>
        <div className="col-xs-12 col-sm-6">
            <SDSpanel title="Sport identities" action={<ProtectedButton action={Actions.USER_PROFILE_LIST_IDENTITIES}
                                                                        route={`${path}/identities`}
                                                                        className="sds-btn--block"
                                                                        as="tertiary">View your sport identities
            </ProtectedButton>}>
                <p>These are your sport identities</p>
                <ul className={`identity-summary undecorated-list ${sports.length > 3 ? 'column-list-2' : ''}`}>
                    {
                        sports.map((sport) => <li key={sport}>
                            <ProtectedButton action={Actions.USER_PROFILE_UPDATE_IDENTITIES}
                                             route={{
                                                 pathname: `${path}/${sport}/identities`
                                             }}
                                             key={sport}
                                             as="tertiary">
                                {deKebabCase(sport)}
                            </ProtectedButton>
                        </li>)
                    }
                </ul>
            </SDSpanel>
        </div>
    </Protected> : null;
};

export default SportingIdentities;