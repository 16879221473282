import React, {useCallback, useEffect, useState} from "react"
import "../organisation.scss";
import {Actions, Protected, withAuth} from "../../../../../security/authorisation";
import {SDSsubPageTitle} from "../../../../../components/page-title/page-title";
import SDSpanel from "../../../../../components/panel/panel";
import {FormBuilder} from "../../../../../components/form/form-builder";
import {FieldTypes} from "../../../../../components/form/field-builder";
import {AffiliationsService, OrganisationService} from "../../../../../service/service";
import SDSform from "../../../../../components/form/form";
import {useHistory} from "react-router";
import {InlineAddress} from "../../../../../containers/address/address";
import Button from "@sportaus-digital/buttons";
import {CompactOrganisationSummary} from "../../../../../containers/organisation-summary/organisation-summary";
import {intersectionBy, sortBy} from "lodash";
import {
    SPORT_ADMIN_NATIONAL, SPORT_ADMIN_REGIONAL,
    SPORT_ADMIN_STATE, SOFTWARE_VENDOR
} from "../../../../../constants/org-functions";
import {
    MANAGES_NATIONAL, MANAGES_REGIONAL, MANAGES_STATE,
    REPORTS_TO_NATIONAL, REPORTS_TO_REGIONAL,
    REPORTS_TO_STATE, USES_SOFTWARE_PROVIDED_BY, PROVIDES_SOFTWARE_FOR
} from "../../../../../constants/affilitation-types";
import * as Terms from "../../../../../constants/terms"

const AddAffiliation = ({orgId, toOrg}) => {
    const router = useHistory();
    const backRoute = `../connections`;
    const createAndInviteOrgRoute = `../connections/add-and-invite`;
    const [organisation, setOrganisation] = useState({});
    const [selectedOrg, setSelectedOrg] = useState();
    const [enteredName, setEnteredName] = useState();
    const [affiliation] = useState({
        fromOrgId: orgId,
        sportIds: [],
        toOrgId: (toOrg && toOrg.org ? toOrg.org.id : undefined)
    });
    const [fromTypes, setFromTypes] = useState([]);
    const [availableTypes, setAvailableTypes] = useState([]);
    const [orgSports, setOrgSports] = useState([]);
    const [availableSports, setAvailableSports] = useState([]);
    const [showSport, setShowSport] = useState(false);
    const [disableSport, setDisableSport] = useState(false);

    useEffect(() => {
        AffiliationsService.getAvailableTypes(orgId).then(d => setFromTypes(d));
    }, [orgId, setFromTypes]);

    useEffect(() => {
        OrganisationService.getOrganisation(orgId).then(org => {
            setOrganisation(org);
            let sports = org.functions.flatMap(f => f.sports).map(s => ({value: s.externalId, label: s.name}));
            setOrgSports(sports);
        });
    }, [orgId, setOrganisation, setOrgSports]);

    const orgSearch = (input) => {
        setEnteredName(input);
        return AffiliationsService.findAffiliationCandidates(orgId, input).then(candidates =>
            Promise.resolve(candidates.map(c => ({
                value: c.org.id,
                label: c.org.name,
                org: {...c.org, existing: c.existingRelationships || []}
            })))
        );
    };

    const OrgOption = (props) => {
        return <div className="org-select-option">
            <div className="org-select-option__name">{props.data.org.name}</div>
            <InlineAddress address={props.data.org.primaryLocation}/>
            {props.data.org.existing.length > 0 &&
            <div>You already have {props.data.org.existing.length} connection(s) with this organisation</div>}
        </div>;
    };

    const NoMatchingOrg = () => {
        return <div className="no-matching-org">
            <div className="no-matching-org__header">Sorry, we couldn't find any matching organisations</div>
            <Protected orgId={orgId} action={Actions.AFFILIATED_ORG_CREATE}>
                <div className="no-matching-org__instructions">
                    Would you like to invite an organisation to ${Terms.PRODUCT_NAME}?&nbsp;
                    <Button as="tertiary"
                               route={{
                                   pathname: createAndInviteOrgRoute,
                                   state: {newOrgName: enteredName}
                               }}>
                        Send an invite now
                    </Button>
                </div>
            </Protected>
        </div>;
    };

    const orgSelected = useCallback((selectedOption) => {
        if (!selectedOption) {
            setSelectedOrg(undefined);
            setAvailableTypes([]);
            setAvailableSports([]);
            return;
        }

        setSelectedOrg(selectedOption.org);

        let sports = selectedOption.org.functions.flatMap(f => f.sports).map(s => ({
            value: s.externalId,
            label: s.name
        }));
        let sharedSports = intersectionBy(orgSports, sports, "value");

        setAvailableSports(sharedSports);

        let orgState = (organisation.primaryLocation || {}).state;
        let targetState = (selectedOption.org.primaryLocation || {}).state;
        let functions = selectedOption.org.functions.map(f => f.code);

        let types = [...fromTypes];
        if (functions.indexOf(SPORT_ADMIN_NATIONAL) === -1) {
            types = types.filter(t => t.value !== REPORTS_TO_NATIONAL);
        } else {
            types = types.filter(t => t.value !== MANAGES_NATIONAL && t.value !== REPORTS_TO_STATE && t.value !== MANAGES_STATE);
        }
        if (orgState === targetState) {
            if (functions.indexOf(SPORT_ADMIN_STATE) === -1) {
                types = types.filter(t => t.value !== REPORTS_TO_STATE);
            } else {
                types = types.filter(t => t.value !== MANAGES_STATE);
            }
        } else {
            types = types.filter(t => t.value !== REPORTS_TO_STATE && t.value !== MANAGES_STATE);
        }
        if (functions.indexOf(SPORT_ADMIN_REGIONAL) === -1) {
            types = types.filter(t => t.value !== REPORTS_TO_REGIONAL);
        } else {
            types = types.filter(t => t.value !== MANAGES_REGIONAL);
        }
        if (functions.indexOf(SOFTWARE_VENDOR) === -1) {
            types = types.filter(t => t.value !== USES_SOFTWARE_PROVIDED_BY);
        } else {
            types = types.filter(t => t.value !== PROVIDES_SOFTWARE_FOR);
        }

        types = sortBy(types, "label");

        setAvailableTypes(types);
    }, [fromTypes, orgSports, organisation.primaryLocation]);

    useEffect(() => {
        if (toOrg && toOrg.org) {
            orgSelected({value: toOrg.org.id, label: toOrg.org.name, org: {...toOrg.org, existing: []}});
        }
    }, [toOrg, orgSelected]);

    const typeSelected = (selectedType) => {
        if (selectedType && selectedType.sportSpecific) {
            setShowSport(true);
            setDisableSport(availableSports.length === 1);
        } else {
            setShowSport(false);
        }
    };

    const form = new FormBuilder()
        .field("fromOrgId", "", FieldTypes.HIDDEN)
        .value(affiliation.fromOrgId)

        .field("toOrgId", "Organisation name", FieldTypes.AUTOCOMPLETE).required()
        .autoCompleteSearch(orgSearch)
        .optionComponent(OrgOption)
        .noOptionsComponent(<NoMatchingOrg/>)
        .value(affiliation.toOrgId)
        .initialOptions(toOrg && toOrg.org ? [{
            value: toOrg.org.id,
            label: toOrg.org.name,
            org: {...toOrg.org, existing: []}
        }] : [])
        .onChange(orgSelected)
        .renderAfter(<CompactOrganisationSummary org={selectedOrg}
                                                 showSports={true}
                                                 showFunctions={false}/>)

        .field("type", "What is your connection to this organisation?", FieldTypes.SELECT)
        .required("Please indicate your connection to this organisation")
        .value(affiliation.type)
        .options(availableTypes)
        .onChange(typeSelected)

        .field("sportIds", "To which sport(s) does this connection apply", FieldTypes.SELECT)
        .required()
        .options(availableSports)
        .value(affiliation.sportIds)
        .multiple()
        .autoSelectSingleOption(true)
        .hidden(() => !showSport)
        .disabled(() => disableSport)
        .build();

    const submitHandler = (aff, actions) => {
        if (availableSports.length === 1
            && availableSports[0].value
            && availableTypes.find(t => t.value === aff.type).sportSpecific) {
            aff.sportIds = [availableSports[0].value];
        }

        let exists = selectedOrg.existing.filter(e =>
            (!e.sportExternalId || aff.sportIds.indexOf(e.sportExternalId) > -1)
            && e.status !== "WITHDRAWN"
            && e.type.value === aff.type).length > 0;

        if (exists) {
            actions.setStatus({
                status: "error",
                message: "You are already have this type of connection with " + selectedOrg.name
            });
            actions.setSubmitting(false);
        } else {
            AffiliationsService.request(orgId, aff)
                .then(() => router.push(backRoute))
                .catch(() => {
                    actions.setStatus({status: "error"});
                    actions.setSubmitting(false);
                });
        }
    };

    return <div
        className="row add-affiliation px-2 d-md-flex flex-row flex-wrap align-content-stretch justify-content-between align-items-stretch">
        <div className="col-sm-12">
            <SDSsubPageTitle byLine={"Find an existing organisation to connect with"} backButtonRoute={backRoute}>
                Connect with an organisation
            </SDSsubPageTitle>
        </div>

        <div className="col-sm-12 align-self-stretch mb-4">
            <SDSpanel title="Find an organisation to connect with">
                <SDSform config={form}
                         submitLabel="Send request"
                         progressIndicator={<span className="fa fa-spinner fa-spin ml-1 mr-1"/>}
                         submitHandler={submitHandler}
                         cancelRoute={backRoute}/>
            </SDSpanel>
        </div>
    </div>;
};

AddAffiliation.propTypes = {};

export default withAuth(AddAffiliation);