import React, {FC} from "react"
import "./spinner.scss"

/**
 * A simple CSS spinner icon that cycles through some of the standard ASC colours
 */
export const Spinner: FC<SpinnerProps> = ({
                                              inline = false,
                                              size = "sm",
                                              text = "Please wait...",
                                              hideText = false
                                          }: SpinnerProps) => {
    const rem: number = 1.5;
    let dim: number;
    switch (size) {
        case "xs":
            dim = 0.5 * rem;
            break;
        case "sm":
            dim = rem;
            break;
        case "md":
            dim = 2 * rem;
            break;
        case "lg":
            dim = 4 * rem;
            break;
        case "xl":
            dim = 8 * rem;
            break;
        default:
            dim = rem;
    }

    return <div className={`sds-spinner sds-spinner${inline ? "--inline" : "--block"}`}>
        <svg className={`sds-spinner__spinner sds-spinner__spinner${inline ? "--inline" : "--block"}`}
             width={dim + "rem"}
             height={dim + "rem"}
             viewBox="0 0 66 66"
             aria-hidden={true}>
            <circle className="path" fill="none" color="red" strokeWidth={6} strokeLinecap="round" cx="33" cy="33"
                    r="30"/>
        </svg>

        <span className={`sds-spinner__text ${hideText ? 'sr-only' : ''}`}>{text}</span>
    </div>;
};

export interface SpinnerProps {
    /**
     * True to render the spinner with display: inline-block. Default is false (renders as a block element)
     *
     * @default false
     */
    inline?: boolean,
    /**
     * The spinner size.
     *
     * 'sm' translates to 1rem.
     * 'xs' is half of sm.
     * 'md' is twice sm.
     * 'lg' is four times sm.
     * 'xl' is eight times sm.
     *
     * @default 'sm'
     */
    size?: "xs" | "sm" | "md" | "lg" | "xl",
    /**
     * Text to display beside (if inline = true) or below (if inline = false) the spinner
     *
     * @default Please wait...
     */
    text?: string,
    /**
     * True to hide the text and only show the icon
     *
     * @default false
     */
    hideText?: boolean
}

export default Spinner;