import React, {useContext} from "react"
import {AuthContext} from "./auth-context";
import PropTypes from 'prop-types';
import {useKeycloak} from "@react-keycloak/web";
import Button from "@sportaus-digital/buttons";

const SAClogin = () => {
    const {keycloak} = useKeycloak();
    const userProfile = useContext(AuthContext);

    const loginHandler = () => {
        if (keycloak) {
            // The keycloak js adapter uses window.location.replace to redirect to the login screen.
            // This means that the current page when the link is clicked is NOT recorded in the history.
            // See https://issues.redhat.com/browse/KEYCLOAK-5831 and https://github.com/keycloak/keycloak/pull/5485
            // This line explicitly records the current page in the history to avoid that pattern.
            window.history.pushState({}, window.location.href);
            keycloak.login({redirectUri: window.envConfig.baseUrl + "/home"}); // triggers a redirect
        }
    };

    if (!userProfile || !userProfile.authenticated) {
        return <Button onClick={loginHandler.bind(this)} class="btn btn-warning pl-5 pr-5">Login</Button>
    } else {
        return null;
    }
};

SAClogin.propTypes = {
    className: PropTypes.string
};

export default SAClogin;