import React, {useEffect, useState} from "react"
import {SDSsubPageTitle} from "../../../../../components/page-title/page-title";
import SDSpanel from "../../../../../components/panel/panel";
import {useHistory} from "react-router";
import {AffiliationsService, OrganisationService} from "../../../../../service/service";
import {useAuth, withAuth} from "../../../../../security/authorisation";
import useDuplicateOrgChecks from "../../../../../hooks/organisation/duplicate-org-check";
import useOrgFunctionsAndSports from "../../../../../hooks/organisation/org-functions-and-sports";
import {FormBuilder} from "../../../../../components/form/form-builder";
import SDSform, {FieldTypes} from "../../../../../components/form/form";
import {OrganisationType} from "../../../../../utils/enums";
import * as Ternms from "../../../../../constants/terms"

const CreateOrgAndInvite = ({parentOrgId, newOrgName}) => {
    const router = useHistory();
    const backRoute = "../connections/add";
    const forwardRoute = "../connections";
    const {updateUserProfile} = useAuth();
    const [organisation] = useState({parentOrgId: parentOrgId, name: newOrgName});
    const [fromTypes, setFromTypes] = useState([]);

    const {dupeOrgCheck, dupeOrgChecking, dupeOrgsExist, dupeFieldMessages, dupeOrgMessage} = useDuplicateOrgChecks();
    const {allSports, allFunctions, loadingSports, sportValidationSchema, functionChangeHandler} = useOrgFunctionsAndSports();

    useEffect(() => {
        AffiliationsService.getAvailableTypes(parentOrgId).then(d => setFromTypes(d));
    }, [parentOrgId, setFromTypes]);

    const config = new FormBuilder()
        .startFieldSet("Organisation details")
        .dynamicField("sports")// the sports fields will be derived at run time from the selected functions

        .field("parentOrgId", "", FieldTypes.HIDDEN).value(organisation.parentOrgId)

        .field("name", "Organisation name").required()
        .value(organisation.name)
        .onBlur(dupeOrgCheck)
        .warning(dupeFieldMessages.warnings.name)
        .error(dupeFieldMessages.errors.name)

        .field("orgType", "Organisation type", FieldTypes.SELECT).required()
        .options(OrganisationType).emptyOption()

        .field("functions", "Organisation function(s)", FieldTypes.SELECT)
        .options(allFunctions)
        .multiple().required()
        .onChange(functionChangeHandler)
        .onBlur(dupeOrgCheck)
        .warning(dupeFieldMessages.warnings.functions)
        .error(dupeFieldMessages.errors.functions)

        .field("sports", "Sport function", FieldTypes.SELECT)
        .options(allSports)
        .multiple().withValidation(sportValidationSchema)
        .onBlur(dupeOrgCheck)

        .field("connectionType", "What is your connection to this organisation?", FieldTypes.SELECT)
        .required("Please indicate your connection to this organisation")
        .options(fromTypes)

        .field("invitationEmail", "Contact email (Manager)", FieldTypes.EMAIL)
        .required("Please enter a Contact email")
        .email("Please enter a valid Contact email")

        .endFieldSet()

        .build();

    const submitHandler = (form, actions) => {
        let functions = form.functions.map(f => ({code: f}));
        let sportIds = [];
        form.sports.forEach(s => {
            let fn = functions.find(f => f.code === s.function);
            fn.sports = (s.sports || []).map(s2 => ({externalId: s2}));
            sportIds.concat(s.sports);
        });

        const request = {
            organisation: {
                name: form.name,
                type: form.orgType,
                functions: functions
            },
            affiliation: {
                fromId: parentOrgId,
                type: form.connectionType,
                sportIds: sportIds
            },
            invitations: [{
                email: form.invitationEmail
            }]
        };

        OrganisationService.createOrgAndInvite(parentOrgId, request).then(() => {
            actions.setSubmitting(false);
            updateUserProfile();
            router.push(forwardRoute);
        }).catch(() => {
            actions.setStatus({status: "error"});
            actions.setSubmitting(false);
        });
    };

    return <div className="row px-2 d-md-flex flex-row flex-wrap align-content-stretch justify-content-between align-items-stretch">
            <div className="col-sm-12">
                <SDSsubPageTitle byLine={`Invite an organisation you think we're missing from ${Ternms.PRODUCT_NAME}`} 
                                 backButtonRoute={backRoute}>
                    Connect with an organisation
                </SDSsubPageTitle>
            </div>
            <div className="col-sm-12 align-self-stretch mb-4">
                <SDSpanel>
                    {dupeOrgMessage}
                    <SDSform config={config}
                             progressIndicator={<span className="fa fa-spinner fa-spin ml-1 mr-1"/>}
                             disableSubmit={dupeOrgChecking || dupeOrgsExist || loadingSports}
                             submitHandler={submitHandler}
                             cancelRoute={backRoute}/>
                </SDSpanel>
            </div>
        </div>;
};

CreateOrgAndInvite.propTypes = {
};

export default withAuth(CreateOrgAndInvite);
