import React from "react";
import { Field, ErrorMessage } from "formik";

const DocumentSubmitMessage = (props) => {
  const {name, errors} = props;

  /* Please ensure the value is defaulted in initialValues, otherwise the validation will not work */

  const validate = (value) => {
    let errorMessage;

    if (!value) {
      errorMessage = 'You must consent to having your document checked';
    }

    return errorMessage;
  };

  return (
    <div className="form-group">
      <div className="form-row">
        <div className="col-xs-12">
          <label htmlFor={name} className="au-control-input__text">
            <Field
              name={name}
              id={name}
              type="checkbox"
              as="input"
              validate={validate}
              className={
                "au-control-input__input" + ( errors[name] ? " is-invalid": "" )
              }
            />
          
            I confirm that I am authorised to provide the personal details presented and I consent to my 
            information being checked with the document issuer or official record holder via third party systems for the 
            purpose of confirming my identity.
            <ErrorMessage
              name={name}
              component="div"
              className="invalid-feedback"
            />
          </label>
        </div>
      </div>
    </div>
  );
};

export default DocumentSubmitMessage;
