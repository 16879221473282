import {useEffect, useState} from "react";
import {isFunction} from "lodash";

export const OrganisationType = [
    {value: "COMPANY", label: "Company", acn: true, abn: true},
    {value: "PARTNERSHIP", label: "Partnership", acn: false, abn: true},
    {value: "SOLE_TRADER", label: "Sole trader", acn: false, abn: true},
    {value: "TRUST", label: "Trust", acn: false, abn: true},
    {value: "GOVERNMENT_ENTITY", label: "Government entity", acn: false, abn: false},
    {value: "UNINCORPORATED", label: "Unincorporated association", acn: false, abn: false},
    {value: "INCORPORATED", label: "Incorporated association", acn: false, abn: false}
];

export const IdentityStatus = [
    {value: "PENDING", label: "Pending your decision"},
    {value: "LINKED", label: "Linked to your account"},
    {value: "DECLINED", label: "Unlinked from your account"},
    {value: "REVOKED", label: "Unlinked from your account"}
];

export const ConsentResponse = [
    {value: "ACCEPTED", label: "Consented"},
    {value: "EXPIRED", label: "Expired"},
    {value: "DECLINED", label: "Declined"},
    {value: "WITHDRAWN", label: "Withdrawn"}
];

export const useEnum = (enumeration) => {
    const [items, setItems] = useState([]);
    useEffect(() => {
        if (isFunction(enumeration)) {
            enumeration().then(s => setItems(s));
        } else {
            setItems(enumeration);
        }
    }, [enumeration, setItems]);

    return (key, labelAttr, keyAttr) => (items.find(e => e[keyAttr || "value"] === key) || {})[labelAttr || "label"] || key;
};