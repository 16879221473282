import React, {FC, Fragment, ReactElement, useState} from "react"
import Button from "@sportaus-digital/buttons";
import Modal from "@sportaus-digital/modal";

/**
 * A button that displays a confirmation modal when the onClick event is triggered. The provided onClick handler
 * is deferred until the Continue/OK button of the confirmation modal is clicked.
 *
 * This component uses the Button component as the button control, and props will be spread onto that component.
 *
 * @see Button
 *
 * @example
 * <ConfirmBtn onClick={myHandler}>Delete</ConfirmBtn>
 */
export const ConfirmBtn: FC<ConfirmBtnProps> = ({
                                                    message = "Are you sure you wish to proceed with this action?",
                                                    okLabel = "Continue",
                                                    cancelLabel = "Cancel",
                                                    onClick,
                                                    onCancel,
                                                    children,
                                                    title,
                                                    size = "md",
                                                    ...rest
                                                }: ConfirmBtnProps) => {
    const [open, setOpen] = useState(false);

    const showConfirmation = () => {
        setOpen(true);
    };

    const doAction = () => {
        setOpen(false);
        onClick();
    };

    const cancelAction = () => {
        setOpen(false);
        if (onCancel) {
            onCancel();
        }
    };

    return <Fragment>
        <Modal open={open}
               title={title}
               size={size}
               cancelAction={cancelAction}
               okAction={doAction}
               cancelLabel={cancelLabel}
               okLabel={okLabel}>
            <div className="sds-confirm-btn__message">{message}</div>
        </Modal>

        <Button className="sds-confirm-btn__main-btn" {...rest} onClick={showConfirmation}>{children}</Button>
    </Fragment>;
};

export interface ConfirmBtnProps {
    /**
     * The confirmation message to display in the modal
     *
     * @default "Are you sure you wish to proceed with this action?"
     */
    message?: string | ReactElement,
    /**
     * Optional title for the confirmation modal
     */
    title?: string,
    /**
     * The size of the confirmation modal
     */
    size?: "sm" | "md" | "lg",
    /**
     * The label of the ok/continue button of the modal
     *
     * @default Continue
     */
    okLabel?: string,
    /**
     * The label of the cancel button
     *
     * @default Cancel
     */
    cancelLabel?: string,
    /**
     * The action to perform when the confirmation is accepted
     */
    onClick: () => void,
    /**
     * An optional action to perform when the confirmation is cancelled
     */
    onCancel?: () => void,
    /**
     * The content of the confirm button (NOT the modal)
     */
    children: any
}

export default ConfirmBtn;