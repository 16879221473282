import React, {ReactChildren} from "react"
import createCache, {EmotionCache} from "@emotion/cache";
import {CacheProvider} from "@emotion/core";

const CspSafeEmotion: React.FC<CspSafeEmotionProps> = ({
                                                           nonceMetaProperty = "csp-style-nonce",
                                                           cacheKey = "emotn-css",
                                                           otherCacheProps = {},
                                                           children
                                                       }: CspSafeEmotionProps) => {

    let styleNonce: string = "no nonce!";
    const metas = document.getElementsByTagName('meta');
    if (metas) {
        for (let i = 0; i < metas.length; i++) {
            if (metas[i].getAttribute("property") === nonceMetaProperty
                || metas[i].getAttribute("name") === nonceMetaProperty) {
                styleNonce = metas[i].getAttribute("content")!;
            }
        }
    }

    const cache: EmotionCache = createCache({
        key: cacheKey,
        nonce: styleNonce,
        ...otherCacheProps
    });

    return <CacheProvider value={cache}>{children}</CacheProvider>;
};

export interface CspSafeEmotionProps {
    /**
     * The property or name attribute of the meta tag containing the csp nonce.
     *
     * Default: "csp-style-nonce"
     */
    nonceMetaProperty?: string,
    /**
     * EmotionJS's CacheProvider key attribute.
     *
     * Default: emotn-css.
     */
    cacheKey?: string,
    /**
     * Any other props to be passed to the Emotion CacheProvider (will be spread into the cache config).
     */
    otherCacheProps?: object,
    /**
     * The component(s) containing Emotion CSS in JS.
     */
    children: ReactChildren
}

export default CspSafeEmotion;