import React from "react";
import SDSfieldList, { SDSfieldListItem } from "../../../../components/field-list/field-list";
import SDSpanel from "../../../../components/panel/panel";
import { useAuth } from "../../../../security/authorisation";

const UserDetails = () => {
    const { userProfile } = useAuth();

    return <div>
        <h2>Your personal user details</h2>
        <SDSpanel title="User Details">
            <SDSfieldList>
                <SDSfieldListItem label="Username" value={userProfile.sportAusConnectId} />
                <SDSfieldListItem label="Email" value={userProfile.email} />
                <SDSfieldListItem label="First name" value={userProfile.firstName} />
                <SDSfieldListItem label="Last name" value={userProfile.lastName} />
            </SDSfieldList>
        </SDSpanel>
    </div>;
};

export default UserDetails;