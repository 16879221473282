import React, {useEffect, useState} from "react"
import {withAuth} from "../../../../../security/authorisation";
import SDSpanel from "../../../../../components/panel/panel";
import {useHistory} from "react-router";
import {FormBuilder} from "../../../../../components/form/form-builder";
import useErrorHandler from "../../../../../hooks/error-handler";
import SDSform, {FieldTypes} from "../../../../../components/form/form";
import {SDShint, Types} from "../../../../../components/form/field";
import {SDSsubPageTitle} from "../../../../../components/page-title/page-title";
import {useEnum} from "../../../../../utils/enums";
import {
    BASIC_AUTH_API_KEY,
    BEARER_API_KEY,
    WebhookSecurityOption
} from "../../../../../constants/webhook-security-option";
import {WebhookService} from "../../../../../service/service";
import * as Terms from "../../../../../constants/terms"

const RegisterWebhook = ({orgId, appId, events}) => {
    const [request] = useState({});
    const [allEvents, setAllEvents] = useState((events || []).map((e) => ({label: e.description, value: e.identifier})));
    const {errorHandler} = useErrorHandler();
    const router = useHistory();
    const backRoute = `../../${appId}`;
    const successRoute = `../../${appId}/webhook/`;
    const getType = useEnum(WebhookSecurityOption);
    const [securityDescription, setSecurityDescription] = useState("");

    useEffect(() => {
        if (!events || events.length === 0) {
            WebhookService.getEvents(orgId)
                .then((eventList) => setAllEvents(eventList.map((e) => ({label: e.description, value: e.identifier}))));
        }
    }, [events, setAllEvents, orgId]);

    const config = new FormBuilder()
        .field("orgId", "", FieldTypes.HIDDEN).value(orgId)
        .field("appId", "", FieldTypes.HIDDEN).value(appId)

        .field("url", "URL", Types.TEXT)
        .required()
        .url()
        .value(request.url)
        .max(1000)

        .field("securityOption", "What type of security is on the webhook endpoint?", Types.RADIO_GROUP)
        .value(request.securityOption)
        .options([
            {
                label: getType(BASIC_AUTH_API_KEY) + " " + getType(BASIC_AUTH_API_KEY, "shortDesc"),
                value: BASIC_AUTH_API_KEY,
                checked: false
            },
            {
                label: getType(BEARER_API_KEY) + " " + getType(BEARER_API_KEY, "shortDesc"),
                value: BEARER_API_KEY,
                checked: false
            }
        ])
        .required("Please indicate what type of security is on the webhook endpoint")
        .onChange((e) => setSecurityDescription(getType(e.currentTarget.value, "longDesc")))
        .renderAfter(<SDShint hint={securityDescription} className="mb-4"/>)
        .wrapperClasses("mb-0")

        .field("apiKey")
        .value(request.apiKey)
        .max(1000)
        .required()
        .hint(`You must provide us with an API Key to authorize ${Terms.PRODUCT_NAME} to invoke your webhook endpoint. This key will be securely stored, and will not be displayed again after you provide it.`)

        .field("events", "Notify on events", FieldTypes.SELECT)
        .value(request.events)
        .options(allEvents)
        .required("Please select at least 1 notification event for this webhook")
        .multiple()

        .build();

    const submitHandler = (formData, actions) => {
        WebhookService.createOrUpdateWebhook(orgId, appId, formData)
            .then((wh) => router.push(successRoute + wh.identifier))
            .catch((e) => {
                errorHandler(e, "registering your webhook");
                actions.setSubmitting(false);
            });
    };

    return <div
        className="row request-oauth-client px-2 d-md-flex flex-row flex-wrap align-content-stretch justify-content-between align-items-stretch">
        <div className="col-sm-12">
            <SDSsubPageTitle byLine={"Register a new webhook for your application"}
                             backButtonRoute={backRoute}>
                Add an OAuth resource
            </SDSsubPageTitle>
        </div>
        <div className="col-sm-12 align-self-stretch mb-4">
            <SDSpanel title="Provide the following details to create a new resource">
                <SDSform config={config}
                         progressIndicator={<span className="fa fa-spinner fa-spin ml-1 mr-1"/>}
                         submitHandler={submitHandler}
                         cancelRoute={backRoute}/>
            </SDSpanel>
        </div>
    </div>;
};

RegisterWebhook.propTypes = {};

export default withAuth(RegisterWebhook);