import React from "react"
import PropTypes from "prop-types"
import SDSfieldList, {SDSfieldListItem} from "../../components/field-list/field-list";
import {formatUTCISODateTime} from "../../utils/date-utils";
import {useEnum} from "../../utils/enums";
import {WebhookSecurityOption} from "../../constants/webhook-security-option";

const WebhookDetails = ({webhook, eventLabelMapper}) => {
    const getType = useEnum(WebhookSecurityOption);

    return <SDSfieldList>
        <SDSfieldListItem label="URL"
                          value={webhook.url}
                          labelWidth={3}
                          valueWidth={9}/>
        <SDSfieldListItem label="Events"
                          labelWidth={3}
                          valueWidth={9}>
            <ul className="undecorated-list">
                {webhook.registeredEventCodes.map(c => <li key={c}>{eventLabelMapper(c)}</li>)}
            </ul>
        </SDSfieldListItem>
        <SDSfieldListItem label="Security"
                          value={getType(webhook.securityOption)}
                          labelWidth={3}
                          valueWidth={9}/>
        <SDSfieldListItem label="Created"
                          value={formatUTCISODateTime(webhook.createdDate)}
                          labelWidth={3}
                          valueWidth={9}/>
        <SDSfieldListItem label="Updated"
                          value={formatUTCISODateTime(webhook.lastUpdatedDate)}
                          labelWidth={3}
                          valueWidth={9}/>
    </SDSfieldList>;
};

WebhookDetails.propTypes = {
    webhook: PropTypes.object,
    eventLabelMapper: PropTypes.func
};

export default WebhookDetails;