import React, {useEffect, useState} from "react"
import {Actions, Protected, ProtectedButton, withAuth} from "../../../../../security/authorisation";
import SDSpanel from "../../../../../components/panel/panel";
import {useHistory} from "react-router";
import {FormBuilder} from "../../../../../components/form/form-builder";
import useErrorHandler from "../../../../../hooks/error-handler";
import {WebhookService} from "../../../../../service/service";
import SDSform, {FieldTypes} from "../../../../../components/form/form";
import {SDSsubPageTitle} from "../../../../../components/page-title/page-title";
import ConfirmBtn from "@sportaus-digital/confirm-btn";
import {SDShint, Types} from "../../../../../components/form/field";
import {
    BASIC_AUTH_API_KEY,
    BEARER_API_KEY,
    WebhookSecurityOption
} from "../../../../../constants/webhook-security-option";
import {useEnum} from "../../../../../utils/enums";
import WebhookDetails from "../../../../../containers/app-management/webhook-details";
import * as Terms from "../../../../../constants/terms";

const UpdateWebhook = ({orgId, appId, identifier, webhook, events}) => {
    const [mode, setMode] = useState("view");
    const [hook, setHook] = useState(webhook);
    const [allEvents, setAllEvents] = useState((events || []).map((e) => ({
        label: e.description,
        value: e.identifier
    })));
    const [loading, setLoading] = useState(true);
    const {errorHandler} = useErrorHandler();
    const router = useHistory();
    const backRoute = `../../${appId}`;
    const getType = useEnum(WebhookSecurityOption);
    const [securityDescription, setSecurityDescription] = useState("");

    useEffect(() => {
        if (webhook) {
            setHook(webhook);
            setLoading(false);
        } else {
            WebhookService.getWebhooksForApplication(orgId, appId)
                .then(data => {
                    setHook(data.webhooks.find(h => h.identifier === identifier));
                    setAllEvents(data.events.map((e) => ({
                        label: e.description,
                        value: e.identifier
                    })));
                    setLoading(false);
                })
                .catch((e) => errorHandler(e, "retrieving the webhook"));
        }
    }, [orgId, appId, identifier, webhook, setHook, errorHandler, setLoading]);

    if (!hook) {
        return null;
    }

    const config = new FormBuilder()
        .field("orgId", "", FieldTypes.HIDDEN).value(orgId)
        .field("appId", "", FieldTypes.HIDDEN).value(appId)
        .field("identifier", "", FieldTypes.HIDDEN).value(identifier)

        .field("url", "URL", Types.TEXT)
        .required()
        .url()
        .value(hook.url)
        .max(1000)

        .field("securityOption", "What type of security is on the webhook endpoint?", Types.RADIO_GROUP)
        .value(hook.securityOption)
        .options([
            {
                label: getType(BASIC_AUTH_API_KEY) + " " + getType(BASIC_AUTH_API_KEY, "shortDesc"),
                value: BASIC_AUTH_API_KEY,
                checked: hook.securityOption === BASIC_AUTH_API_KEY
            },
            {
                label: getType(BEARER_API_KEY) + " " + getType(BEARER_API_KEY, "shortDesc"),
                value: BEARER_API_KEY,
                checked: hook.securityOption === BEARER_API_KEY
            }
        ])
        .required("Please indicate what type of security is on the webhook endpoint")
        .onChange((e) => setSecurityDescription(getType(e.currentTarget.value, "longDesc")))
        .renderAfter(<SDShint hint={securityDescription} className="mb-4"/>)
        .wrapperClasses("mb-0")

        .field("apiKey")
        .value(hook.apiKey)
        .max(1000)
        .hint(`Leave this field blank to continue using your current API Key. You must provide us with an API Key to authorize ${Terms.PRODUCT_NAME} to invoke your webhook endpoint. This key will be securely stored, and will not be displayed again after you provide it.`)

        .field("events", "Notify on events", FieldTypes.SELECT)
        .value(hook.registeredEventCodes)
        .options(allEvents)
        .required("Please select at least 1 notification event for this webhook")
        .multiple()

        .build();

    const submitHandler = (formData, actions) => {
        WebhookService.createOrUpdateWebhook(orgId, appId, formData)
            .then((result) => {
                setHook(result);
                actions.setSubmitting(false);
                setMode("view");
            })
            .catch((e) => {
                actions.setSubmitting(false);
                errorHandler(e, "updating your webhook");
            });
    };

    const deleteHandler = () => {
        WebhookService.deleteWebhook(orgId, appId, identifier)
            .then(() => router.push(backRoute))
            .catch((e) => errorHandler(e, "deleting your webhook"));
    };

    const changeMode = () => {
        setMode(mode === "view" ? "edit" : "view")
    };

    const removeAction = <>
        <Protected action={Actions.ORG_APPLICATIONS_DELETE_WEBHOOK} orgId={orgId}>
            <ConfirmBtn as={"secondary"}
                           disabled={loading}
                           onClick={() => deleteHandler()}>
                Remove webhook
            </ConfirmBtn>
        </Protected>
    </>;

    const editAction = mode === "view" ? <div className="text-right m-4">
        <ProtectedButton action={Actions.ORG_APPLICATIONS_UPDATE_WEBHOOK}
                         orgId={orgId}
                         as={"secondary"}
                         disabled={loading}
                         onClick={changeMode}>
            Update webhook
        </ProtectedButton>
    </div> : null;

    return <div className="row">
        <div className="col-sm-12">
            <SDSsubPageTitle backButtonRoute={backRoute}>
                Update your webhook
            </SDSsubPageTitle>
        </div>
        <div className="col-sm-12">
            <SDSpanel title="Webhook details" action={editAction}>
                {
                    mode === "view"
                    && !loading
                    && <WebhookDetails webhook={hook}
                                       eventLabelMapper={(eventCode) => (allEvents.find(e => e.value === eventCode) || {}).label}/>
                }
                {
                    mode === "edit"
                    && !loading
                    && <SDSform config={config}
                                progressIndicator={<span className="fa fa-spinner fa-spin ml-1 mr-1"/>}
                                disableSubmit={loading}
                                submitHandler={submitHandler}
                                cancelHandler={() => setMode("view")}
                                nonFormActions={removeAction}/>
                }
            </SDSpanel>
        </div>
    </div>;
};

UpdateWebhook.propTypes = {};

export default withAuth(UpdateWebhook);