import React, {useCallback, useContext, useState} from "react"
import {PageAlertActionsContext} from "../../../utils/page-alerts";

const useErrorHandler = () => {
    const setPageAlert = useContext(PageAlertActionsContext);
    const [hasError, setHasError] = useState(false);

    const clearError = () => {
        setHasError(false);
        setPageAlert({});
    };

    const errorHandler = useCallback((error, document) => {
        setHasError(true);
        let severity = "error";
        let title = "An error occurred while validating " + document;
        let message = '';

        if (error?.response?.status === 401 || error?.response?.status === 403) {
            message = "You do not have permission to perform this action";
        } else if (error?.response?.status === 404) {
            message = "Page not found";
        } else if (error?.response?.data?.errors) {
            message = <ul>
                {error.response.data.errors.map(error => <li key={error.code}>{error.title}</li>)}
                </ul>
        } else {
            message = "An unexpected error has occurred. Please try again later.";
        }

        setPageAlert({
            severity: severity,
            title: title,
            message: message,
            scrollTo: true
        });
    }, [setHasError, setPageAlert]);

    return {
        errorHandler: errorHandler,
        clearError: clearError,
        hasError: hasError
    };
};

export default useErrorHandler;