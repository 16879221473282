import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import {OrganisationService} from "../../../../service/service";
import SDSpanel from "../../../../components/panel/panel";
import OrganisationDetails from "./organisation-details";
import OrganisationPersons from "./persons/organisation-persons";
import {SDSsubPageTitle} from "../../../../components/page-title/page-title";
import {useRouteMatch} from "react-router";
import {Actions, Protected, ProtectedButton} from "../../../../security/authorisation";
import SDSicon from "../../../../components/icons/icons";
import Spinner from "@sportaus-digital/spinner";
import "./organisation.scss"
import OrgAffiliationsSummary from "./affiliations/affiliations-summary";
import useErrorHandler from "../../../../hooks/error-handler";
import OrgApplications from "./app-management/org-applications";

const Organisation = ({orgId}) => {
    const [organisation, setOrganisation] = useState({});
    const {errorHandler} = useErrorHandler();
    const [loading, setLoading] = useState(true);
    const byLineText = "Organisation details, members, roles and management functions";
    const {url} = useRouteMatch();

    useEffect(() => {
        OrganisationService.getOrganisation(orgId).then(org => setOrganisation(org))
            .catch((e) => {
                errorHandler(e, "retrieving the organisation");
            })
            .finally(() => setLoading(false));
    }, [orgId, setOrganisation, errorHandler]);


    const personOrInvitationRemovedHandler = (id) => {
        let people = [...organisation.peopleAndInvites];
        let update = {...organisation};
        update.peopleAndInvites = people.filter(p => p.id !== id);
        setOrganisation(update);
    };

    return (loading
            ? <Spinner/>
            : <div
                className="row organisation-details px-2 d-md-flex flex-row flex-wrap align-content-stretch justify-content-between align-items-stretch">
                <div className="col-sm-12">
                    <SDSsubPageTitle byLine={byLineText}
                                     backButtonRoute={'../organisations'}>
                        Organisation details</SDSsubPageTitle>
                </div>
                <div className="col-sm-12 align-self-stretch mb-4">
                    <SDSpanel title={organisation.name}
                              titleLevel={1}
                              action={<ProtectedButton
                                  orgId={organisation.id}
                                  action={Actions.ORG_UPDATE}
                                  className="sds-btn--block"
                                  route={`${url}/edit`}
                                  iconPosition="right"
                                  icon={<SDSicon group="fa" name="chevron-right"/>}>
                                  Update organisation details
                              </ProtectedButton>}>
                        {organisation.id && <OrganisationDetails organisation={organisation}/>}
                    </SDSpanel>
                </div>
                <div className="col-sm-8 align-self-stretch mb-4">
                    <SDSpanel title="People" titleLevel={2}
                              className="people-and-invitations"
                              action={<ProtectedButton action={Actions.ORG_INVITE_USER}
                                                       orgId={organisation.id}
                                                       className="sds-btn--block"
                                                       route={`${url}/invite`}
                                                       icon={<SDSicon group="fa" name="plus"/>}>
                                  Invite a person</ProtectedButton>}>
                        {organisation.id && <OrganisationPersons
                            orgId={organisation.id}
                            removedHandler={personOrInvitationRemovedHandler}
                            peopleAndInvitations={organisation.peopleAndInvites}/>}
                    </SDSpanel>
                </div>
                <div className="col-sm-4 align-self-stretch mb-4">
                    <OrgAffiliationsSummary organisation={organisation}/>
                </div>
                <Protected orgId={orgId} action={[Actions.ORG_APPLICATIONS_LIST_APP, Actions.GROUP_MANAGEMENT_ORG, Actions.GROUP_MANAGEMENT_APP]}>
                    <div className="col-sm-12 align-self-stretch mb-4">
                        <OrgApplications organisation={organisation}/>
                    </div>
                </Protected>
            </div>
    )
};

Organisation.propTypes = {
    orgId: PropTypes.number.isRequired
};

export default Organisation;