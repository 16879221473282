import React, {useEffect, useState} from "react"
import {UserService} from "../../service/service";
import {orderBy, replace} from "lodash"
import SACconsent from "../../containers/sac-consent/sac-consent";
import Spinner from "@sportaus-digital/spinner";
import {Actions, withAuth} from "../../security/authorisation";
import useErrorHandler from "../../hooks/error-handler";
import {SDSlandingPageTitle} from "../../components/page-title/page-title";
import SDSicon from "../../components/icons/icons";

const UserDataSharing = () => {
    const [consents, setConsents] = useState({});
    const [clusterDescriptions, setClusterDescriptions] = useState({});
    const [purposeDescriptions, setPurposeDescriptions] = useState({});
    const [loading, setLoading] = useState(true);
    const {errorHandler} = useErrorHandler();

    useEffect(() => {
        UserService.getDataSharingConsents()
            .then(data => {
                let groupedConsents = {};

                data.consents.forEach(consent => {
                    let key = consent.audienceCode + replace(consent.purpose, /(.*)_v\d+/, "$1");

                    if (!groupedConsents[key]) {
                        groupedConsents[key] = {
                            audienceCode: consent.audienceCode,
                            audienceText: consent.audienceText,
                            purpose: consent.purpose,
                            history: []
                        }
                    }

                    groupedConsents[key].history.push({
                        version: consent.version,
                        data: consent.data,
                        purpose: consent.purpose,
                        expiry: consent.expiry,
                        response: consent.response,
                        timestamp: consent.timestamp,
                        status: consent.status
                    });
                });

                Object.keys(groupedConsents).forEach(group => {
                    groupedConsents[group].history = orderBy(groupedConsents[group].history, ["status", "timestamp"], ["asc", "desc"])
                });

                setConsents(groupedConsents);
                setClusterDescriptions(data.clusterDescriptions);
                setPurposeDescriptions(data.purposeDescriptions);
                setLoading(false);
            })
            .catch((e) => {
                errorHandler(e, "retrieving your data sharing consents");
                setLoading(false);
            });
    }, [errorHandler, setConsents, setLoading]);

    return <div className="row">
        <div className="col-sm-12">
            <SDSlandingPageTitle icon={<SDSicon group="fa" name="fa-eye-slash fa-4x"/>}
                                 byLine='Manage your privacy and data sharing settings'>
                Privacy
            </SDSlandingPageTitle>
        </div>
        <div className="col-sm-12">
            {loading
                ? <Spinner inline={true}/>
                : consents && Object.keys(consents).length > 0
                    ? Object.keys(consents).map(consent =>
                        <SACconsent key={consent}
                                    consent={consents[consent]}
                                    clusterDescriptions={clusterDescriptions}
                                    purposeDescriptions={purposeDescriptions}/>)
                    : <div>You have not shared data with anyone</div>}
        </div>
    </div>;
};

export default withAuth(UserDataSharing, Actions.USER_PROFILE_READ_CONSENTS);