import React, {useState} from 'react';

export const AuthContext = React.createContext({});
export const AuthActionsContext = React.createContext({});

const AuthContextProvider = ({children}) => {
    const [userProfile, setUserProfile] = useState({
        authenticated: false,
        kcRoles: [],
        appRoles: [],
        username: undefined,
        roles: []
    });

    return <AuthContext.Provider value={userProfile}>
        <AuthActionsContext.Provider value={setUserProfile}>
            {children}
        </AuthActionsContext.Provider>
    </AuthContext.Provider>
};

export default AuthContextProvider;