import {AUcheckbox} from "@gov.au/control-input";
import React, {FC} from "react";
import {StandardProps, classes, isDisabled} from "./_common";
import "./input-fields.scss";

export const Checkbox: FC<CheckboxProps> = ({
                                                id,
                                                name,
                                                label,
                                                defaultChecked,
                                                inputClasses,
                                                onChange,
                                                onBlur,
                                                disabled,
                                                ...rest
                                            }: CheckboxProps) => {
    return <AUcheckbox {...rest}
                       id={id || name}
                       name={name || id}
                       label={label}
                       className={classes("sds-checkbox", inputClasses)}
                       defaultChecked={defaultChecked}
                       onChange={onChange}
                       onBlur={onBlur}
                       disabled={isDisabled(disabled)}
                       type="checkbox"/>;
};

export type CheckboxProps = {
    label: string,
    defaultChecked?: boolean
} & StandardProps;