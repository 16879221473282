import React, {Fragment} from "react"
import {FieldArray, Form, Formik} from "formik"
import SDSfield, {Types} from "./field"
import {FormBuilder} from "./form-builder";
import _ from "lodash"
import * as yup from "yup"
import PropTypes from "prop-types"
import "./form.scss"
import AUpageAlert from "@gov.au/page-alerts";
import SDSfieldSet from "./field-set";
import Button from "@sportaus-digital/buttons";

/**
 * A generic wrapper component for any form that encapsulates data binding and validation.
 *
 * NOTE: This component depends on Formik for form management (data binding etc),
 * Yup for validation, and SDSfield (which in turn depends on the Australian Government
 * Digital Transformation Agency (DTA) Design System) for the individual fields.
 *
 * If you are not using Formik or Yup, then this component is not suitable.
 *
 * The configuration object should be constructed using the FormBuilder exported by this file.
 *
 * Note: to avoid the error "Can't perform a React state update on an unmounted component",
 * ensure that you use actions.setSubmitting(false) BEFORE clearing the form data.
 *
 * To display an error when submitting the form fails, use the 'actions' object's setStatus
 * to {status: 'error', message: 'optional message text'}. This will cause the form to display
 * an error above the submit button.
 *
 * @see https://jaredpalmer.com/formik/docs/overview
 * @see https://github.com/jquense/yup
 * @see SDSfield
 *
 * @requires "formik"
 * @requires "yup"
 * @requires "lodash"
 *
 * @example
 * const conf = new FormBuilder().field(...)...build()
 * <SDSform config={conf} submitHandler={submitFunction}/>
 */
const SDSform = props => {
    const FORM_SUBMIT_ERROR = "An unexpected error occurred while attempting to save your data.";

    const {config, submitHandler, submitLabel, cancelHandler, cancelLabel, cancelRoute, disableSubmit, nonFormActions} = props;

    let initialValues = {};
    _.forOwn(config, (field, name) => {
        if (field.isFieldset) {
            _.forOwn(field.fields, (field2, name2) => initialValues[name2] = field2.value);
        } else {
            initialValues[name] = field.value;
        }
    });

    let validationSchema = {};
    _.forOwn(config, (field, name) => {
        if (field.isFieldset) {
            _.forOwn(field.fields, (field2, name2) => validationSchema[name2] = field2.validation);
            validationSchema[name] = field.validation;
        } else {
            validationSchema[name] = field.validation;
        }
    });
    validationSchema = yup.object(validationSchema);


    return <Formik initialValues={initialValues}
                   validationSchema={validationSchema}
                   enableReinitialize={true}
                   onSubmit={submitHandler}>
        {formik => {
            const cancelAction = () => {
                formik.resetForm();
                if (cancelHandler) {
                    cancelHandler();
                }
            };

            let hasError = formik.status === "SUBMIT_ERROR" || (_.isObject(formik.status) && _.toLower(formik.status.status) === "error");
            return <Fragment>
                {hasError && <AUpageAlert as="error">{formik.status.message || FORM_SUBMIT_ERROR}</AUpageAlert>}

                <Form className="sds-form">
                    {_.keys(config).map(field => {
                        let f;
                        if (config[field].isFieldset) {
                            let fsConfig = config[field];
                            f = <SDSfieldSet title={fsConfig.fsTitle}
                                             key={fsConfig.fsTitle}
                                             titleLevel={fsConfig.fsTitleLevel}
                                             description={fsConfig.fsDescription}>
                                {
                                    fsConfig.dynamicField
                                        ? <FieldArray name={fsConfig.dynamicField} render={arrayHelpers => (
                                            _.keys(fsConfig.fields).map(field2 => {
                                                if (field2 === fsConfig.dynamicField) {
                                                    return formik.values[field2]
                                                        .filter((array) => !_.isEmpty(array))
                                                        .map((fieldObj, fieldIndex) => {
                                                            let arrayFieldConf = fsConfig.fields[field2];
                                                            arrayFieldConf.label = fieldObj.label || arrayFieldConf.label;
                                                            return sdsField(`${field2}[${fieldIndex}].${field2}`,
                                                                field2 + fieldIndex,
                                                                fsConfig.fields[field2],
                                                                formik,
                                                                validationSchema,
                                                                props.progressIndicator,
                                                                arrayHelpers)
                                                        });
                                                } else {
                                                    return sdsField(field2, field2, fsConfig.fields[field2], formik, validationSchema,props.progressIndicator, arrayHelpers);
                                                }
                                            }))}/>

                                        : _.keys(fsConfig.fields).map(field2 => sdsField(field2, field2, fsConfig.fields[field2], formik, validationSchema,props.progressIndicator))
                                }
                            </SDSfieldSet>
                        } else {
                            f = sdsField(field, field, config[field], formik, validationSchema,props.progressIndicator);
                        }
                        return f;
                    })}
                    <div className="sds-form__actions">
                        {nonFormActions &&
                        <div className="sds-form__extraActions">
                            {nonFormActions}
                        </div>
                        }
                        <div className="sds-form__standardActions">
                            <Button as="tertiary"
                                       type="button"
                                       route={cancelRoute}
                                       onClick={cancelRoute ? undefined : cancelAction}
                                       className={`sds-form__cancel${cancelRoute ? '--route' : ''}`}>
                                {cancelLabel || "Cancel"}
                            </Button>
                            <Button type="submit"
                                       className="sds-form__submit"
                                       disabled={disableSubmit || formik.isSubmitting}>
                                {formik.isSubmitting && props.progressIndicator}
                                {submitLabel || "Save"}
                            </Button>
                        </div>
                    </div>
                </Form>
            </Fragment>
        }}
    </Formik>;
};

const sdsField = (name, key, config, formik, validationSchema, progressIndicator, arrayHelpers) => {
    return <SDSfield {...config}
                     name={name}
                     key={key}
                     arrayHelpers={arrayHelpers}
                     formData={formik.values}
                     parentForm={formik}
                     validationSchema={validationSchema}
                     progressIndicator={progressIndicator}/>;
};

SDSform.propTypes = {
    /**
     * Configuration object describing the form. Should be created using the FormBuilder api.
     */
    config: PropTypes.object.isRequired,
    /**
     * Callback function to be invoked when the form is submitted. This will receive 2 parameters:
     * 1) The form data object
     * 2) A Formik 'actions' object that allows you to clear the 'submitting' flag (actions.setSubmitting(false)).
     */
    submitHandler: PropTypes.func.isRequired,
    /**
     * An optional callback function to be invoked when the form's reset/cancel button is clicked.
     * This allows additional action to be executed after the form's default behaviour of resetting
     * the form fields to their initial state.
     */
    cancelHandler: PropTypes.func,
    /**
     * An alternative to the cancelHandler, when the cancel button simply needs to route to a
     * different page. If both cancelHandler and cancelRoute are defined, cancelHandler will
     * be ignored.
     */
    cancelRoute: PropTypes.string,
    /**
     * Optional override for the Submit button's label.
     */
    submitLabel: PropTypes.string,
    /**
     * Optional override for the Cancel button's label.
     */
    cancelLabel: PropTypes.string,
    /**
     * An optional component to be displayed after the label of fields and beside the submit button to
     * indicate an action is in progress (such as invoking an options callback on a field or submitting
     * the form).
     */
    progressIndicator: PropTypes.element,
    /**
     * Explicitly disable the submit button.
     */
    disableSubmit: PropTypes.bool,
    /**
     * An optional component to be displayed in line with the forms cancel and submit actions.
     */
    nonFormActions: PropTypes.element
};

SDSform.defaultProps = {
    config: {},
    submitLabel: "Save",
    cancelLabel: "Cancel"
};

export default SDSform;

export {Types as FieldTypes, FormBuilder};