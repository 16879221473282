import React from "react";
import { useField, ErrorMessage, Field } from "formik";
import { australianStates } from "../../../constants/australianStates";

const SelectState = (props) => {
  const {name, className, onStateChange} = props;
  const helpers = useField(props)[2];

  const handleChange = (event) => {
    const { value } = event.target;
    helpers.setValue(value);
    if (onStateChange) {
      onStateChange(value);
    }
  };

  return (
    <>
      <Field
        as="select"
        name={name}
        id={name}
        className={className}
        onChange={handleChange}
      >
        <option value="" key="">Select State of Issue</option>
        {australianStates.map((state) => (
          <option  key={state.abbreviation} value={state.abbreviation}>{state.name}</option>
        ))}
      </Field>
      <ErrorMessage
        name={name}
        component="div"
        className="invalid-feedback"
      />
    </>
  );
};

export default SelectState;
