import React, {createContext, useCallback, useState, useRef, useEffect} from "react";
import Alert from "@sportaus-digital/alert";

export const PageAlertContext = createContext({});
export const PageAlertActionsContext = createContext({});

const PageAlertContextProvider = ({children}) => {
    const [pageAlert, setPageAlert] = useState({
        title: undefined,
        severity: undefined, // 'info', 'success', 'warning', 'error'
        message: undefined,
        scrollTo: false
    });
    const ref = useRef();

    useEffect(() => {
        if (ref.current && pageAlert.scrollTo) {
            ref.current.scrollIntoView({ behavior: "smooth", block: "end" });
        }
    });

    const dismiss = useCallback(() => {
        setPageAlert({});
    }, [setPageAlert]);

    let timeout = pageAlert.severity === "error" ? 30000 : pageAlert.severity === "warning" ? 20000 : 10000;

    return <PageAlertContext.Provider value={pageAlert}>
        <PageAlertActionsContext.Provider value={setPageAlert}>
            {
                pageAlert && pageAlert.message &&
                <div ref={ref}>
                    <Alert severity={pageAlert.severity}
                            title={pageAlert.title}
                            message={pageAlert.message}
                            action={pageAlert.action}
                            timeout={timeout}
                            dismiss={dismiss}
                            className="mt-3"/>
                </div>
            }
            {children}
        </PageAlertActionsContext.Provider>
    </PageAlertContext.Provider>
};

export default PageAlertContextProvider;