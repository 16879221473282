import React, { useEffect, useState } from "react";
import { withAuth } from "../../../security/authorisation";
import useErrorHandler from "../../../hooks/error-handler";
import { SDSlandingPageTitle } from "../../../components/page-title/page-title";
import SDSpanel from "../../../components/panel/panel";
import { IdentityService } from "../../../service/service";
import Button from "@sportaus-digital/buttons";

const documentTypes = [
  { type: "MD", documentName: "Medicare Card" },
  { type: "BC", documentName: "Birth Certificate" },
  { type: "DL", documentName: "Drivers Licence" },
];

const Documents = () => {
  const [documents, setDocuments] = useState([]);
  const [identityStrength, setIdentityStrength] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { errorHandler } = useErrorHandler();

  const [intervalId, setIntervalId] = useState(null);
  const pollingInterval = 5000; // milliseconds

  function getDocuments() {
    setIsLoading(true);
    IdentityService.getDocuments()
      .then((data) => {
        setDocuments(data.verifiedDocumentList);
      })
      .then(() => getIdentity())
      .then(() => setIsLoading(false))
      .catch((e) => {
        return errorHandler(e, "retrieving your documents");
      });
  }

  function getIdentity() {
    IdentityService.getIdentity()
      .then((data) => {
        setIdentityStrength(getIdentityStrength(data.acr));
      })
      .catch((e) => {
        setIdentityStrength(getIdentityStrength("IP1"));
        if (e.response.status !== 404) {
          console.log(e);
        }
      });
  }

  function getIdentityStrength(acr) {
    switch (acr) {
      case "IP1":
        return "";
      case "IP1PLUS":
        return "Basic";
      case "IP2":
        return "Standard";
      default:
        return "";
    }
  }

  function getStatusString(status, responseCode) {
    switch (status) {
      case "PENDING":
        return "Pending";
      case "VALIDATED":
        return "Verified";
      case "INVALIDATED":
        return "Invalid";
      case "EXPIRED":
        return "Expired";
      case "FAILED":
        if (responseCode === "D")
          return "Data error for document. Please contact issuer.";
        return "System error at document provider. Please try again later.";
      default:
        return "";
    }
  }

  useEffect(() => {
    getDocuments();
    const intervalId = setInterval(getDocuments, pollingInterval);
    setIntervalId(intervalId);
    return () => clearInterval(intervalId);
  }, []);

  const displayDocumentType = (document) => {
    const doc = documents.find((d) => d.type === document.type);
    if (typeof doc === "undefined") {
      const docAction = (
        <Button
          link={`/home/account/documents/${document.type}/add`}
          className="sds-btn--block"
          as="tertiary"
        >
          Add {document.documentName}
        </Button>
      );
      return (
        <div className="col-xs-12 col-sm-3">
          <SDSpanel
            title={document.documentName}
            titleLevel={3}
            action={docAction}
          />
        </div>
      );
    } else if (doc.status.match(/^PENDING$|^VALIDATED$/)) {
      return (
        <div className="col-xs-12 col-sm-3">
          <SDSpanel title={document.documentName} titleLevel={3}>
            Status: {getStatusString(doc.status, doc.responseCode)}
          </SDSpanel>
        </div>
      );
    } else if (doc.status.match(/^EXPIRED$|^FAILED$|^INVALIDATED$/)) {
      const docAction = (
        <Button
          link={`/home/account/documents/${document.type}/add`}
          className="sds-btn--block"
          as="tertiary"
        >
          Re-Add {document.documentName}
        </Button>
      );
      return (
        <div className="col-xs-12 col-sm-3">
          <SDSpanel
            title={document.documentName}
            titleLevel={3}
            action={docAction}
          >
            Status: {getStatusString(doc.status, doc.responseCode)}
          </SDSpanel>
        </div>
      );
    }
  };

  return (
    !isLoading && (
      <>
        {!documents.some((d) => d.status === "PENDING") &&
          clearInterval(intervalId)}

        <div className="row">
          <div className="col-sm-12">
            <SDSlandingPageTitle
              icon={<span className="fas fa-user-circle fa-4x" />}
              byLine={
                <p>
                  Verify your identity and reduce the need to share sensitive
                  identity documents to prove who you are when interacting with
                  sport. We use the Australia Government's{" "}
                  <a
                    href="https://www.idmatch.gov.au/our-services#section-0"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    Document Verification Service (DVS)
                  </a>
                  , to validate the identity documents that you use to prove who
                  you are without storing the related document identifiers.
                </p>
              }
            >
              Identity Verification
            </SDSlandingPageTitle>
          </div>
        </div>
        { identityStrength &&
        <div className="row">
          <div className="col-sm-12">
            <p><strong>Identity Strength:</strong> { identityStrength }</p>
          </div>
        </div>
        }
        <div className="row panel-container">
          {documentTypes.map((document) => displayDocumentType(document))}
        </div>
      </>
    )
  );
};

export default withAuth(Documents);
