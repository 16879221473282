import AUtextInput from "@gov.au/text-inputs";
import React, {FC} from "react";
import {StandardProps, classes, isDisabled} from "./_common";
import "./input-fields.scss";

export const TextField: FC<TextFieldProps> = ({
                                                  id,
                                                  name,
                                                  disabled,
                                                  onChange,
                                                  onBlur,
                                                  inline,
                                                  inputClasses,
                                                  ...rest
                                              }: TextFieldProps) => {
    return <AUtextInput {...rest}
                        id={id || name}
                        name={name || id}
                        disabled={isDisabled(disabled)}
                        className={classes("sds-text-field", inputClasses)}
                        onChange={onChange}
                        onBlur={onBlur}
                        block={!inline}/>;
};

export type TextFieldProps = {} & StandardProps;

export default TextField;