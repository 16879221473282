import React, {useCallback, useState} from "react";
import {debounce, countBy, keys} from "lodash";
import {OrganisationService} from "../../service/service";
import Alert from "@sportaus-digital/alert";
import OrganisationSummary from "../../containers/organisation-summary/organisation-summary";
import * as Terms from "../../constants/terms"

const useDuplicateOrgChecks = () => {
    const [checking, setChecking] = useState(false);
    const [potentialDuplicates, setPotentialDuplicates] = useState([]);
    const [hasErrors, setHasErrors] = useState(false);

    const dupeCheck = useCallback(debounce((e, org) => {
        setChecking(true);
        if (org.name || org.functions) {
            OrganisationService.checkForDuplicates(org).then(dupes => {
                dupes = org.id ? dupes.filter(d => d.duplicate.id !== org.id) : dupes;
                setPotentialDuplicates(dupes);
                setHasErrors(dupes.filter(d => !d.warnOnly).length > 0);
            }).finally(() => setChecking(false));
        } else {
            setPotentialDuplicates([]);
            setHasErrors(false);
            setChecking(false);
        }
    }), [setHasErrors, setPotentialDuplicates, setChecking]);

    return {
        dupeOrgCheck: dupeCheck,
        dupeOrgChecking: checking,
        dupeOrgsExist: potentialDuplicates.length > 0,
        dupeWarningOnly: !hasErrors,
        dupeFieldMessages: getFieldMessages(potentialDuplicates),
        dupeOrgMessage: (potentialDuplicates.length > 0 &&
            <Alert severity={hasErrors ? "error" : "warning"}>
                {getMessage(hasErrors, potentialDuplicates)}
            </Alert>)
    };
};

const getFieldMessages = potentialDuplicates => {
    const messages = {
        errors: [],
        warnings: []
    };

    const add = (message, field, warning) => {
        if (warning) {
            if (!messages.warnings[field]) {
                messages.warnings[field] = [];
            }
            if (messages.warnings[field].indexOf(message) === -1) {
                messages.warnings[field].push(message);
            }
        } else {
            if (!messages.errors[field]) {
                messages.errors[field] = [];
            }
            if (messages.errors[field].indexOf(message) === -1) {
                messages.errors[field].push(message);
            }
        }
    };

    potentialDuplicates.forEach(d => {
        switch (d.type) {
            case "NAME":
                add("An organisation with the same Name already exists", "name", d.warnOnly);
                break;
            case "NAME_ABN":
                add("An organisation with the same Name and ABN already exists", "name", d.warnOnly);
                add("An organisation with the same Name and ABN already exists", "abn", d.warnOnly);
                break;
            case "NAME_ACN":
                add("An organisation with the same Name and ACN already exists", "name", d.warnOnly);
                add("An organisation with the same Name and ACN already exists", "acn", d.warnOnly);
                break;
            case "NSO":
                add("A National Sporting Organisation (NSO) already exists for this sport", "functions", d.warnOnly);
                break;
            case "SSO":
                add("A State Sporting Organisation (SSO) already exists for this sport and state", "functions", d.warnOnly);
                add("A State Sporting Organisation (SSO) already exists for this sport and state", "state", d.warnOnly);
                break;
            default:
        }
    });

    return messages;
};


const getMessageText = potentialDuplicates => {
    let message;

    let types = countBy(potentialDuplicates, "type");
    if (keys(types).length === 1) {
        let type = keys(types)[0];
        let multi = types[type] > 1;
        switch (type) {
            case "NAME":
                message = <>{multi ? "" : "an"} existing organisation{multi ? "s" : ""} with the same Name</>;
                break;
            case "NAME_ABN":
                message = <>{multi ? "" : "an"} existing <strong>organisation{multi ? "s" : ""} with the same Name and ABN</strong></>;
                break;
            case "NAME_ACN":
                message = <>{multi ? "" : "an"} existing <strong>organisation{multi ? "s" : ""} with the same Name and ACN</strong></>;
                break;
            case "NSO":
                message = <>{multi ? "" : "an"} existing <strong>National Sporting Organisation (NSO) for this sport</strong></>;
                break;
            case "SSO":
                message = <>{multi ? "" : "an"} existing <strong>State Sporting Organisation (SSO) for this sport and state</strong></>;
                break;
            default:
        }
    } else {
        message = " several existing organisations with the same details";
    }

    return message;
};

const getMessage = (isError, potentialDuplicates) => {
    const multi = potentialDuplicates.length > 1;

    return <>
        <p>Uh oh, it looks like we have {getMessageText(potentialDuplicates)}</p>
        <div className="duplicate-list">
            {potentialDuplicates.map(d => <OrganisationSummary org={d.duplicate} key={d.duplicate.id}
                                                               className="duplicate-list-item"/>)}
        </div>
        {isError && <p>You can't recreate this organisation as it already exists.</p>}
        <p>If you belong to {multi ? "one of these organisations" : potentialDuplicates[0].duplicate.name},
            please contact the manager {multi ? "" : " at " + potentialDuplicates[0].duplicate.name} and request to
            be added to the organisation in {Terms.PRODUCT_NAME}.</p>
        {!isError && <p>Alternatively, you can proceed if you are sure your organisation
            is <em>not</em> {multi ? "one of these" : potentialDuplicates[0].duplicate.name}.</p>}
    </>
};

export default useDuplicateOrgChecks;