import React, {useEffect, useState} from "react"
import {SDSsubPageTitle} from "../../../../../components/page-title/page-title";
import SDSpanel from "../../../../../components/panel/panel";
import {AffiliationsService, OrganisationService} from "../../../../../service/service";
import DataTable from "@sportaus-digital/data-table";
import {useRouteMatch} from "react-router";
import "../organisation.scss";
import {Protected, ProtectedButton, useAuth, withAuth} from "../../../../../security/authorisation";
import SDSicon from "../../../../../components/icons/icons";
import ConfirmBtn from "@sportaus-digital/confirm-btn";
import Button from "@sportaus-digital/buttons";
import useErrorHandler from "../../../../../hooks/error-handler";
import Spinner from "@sportaus-digital/spinner";

const OrganisationAffiliations = ({orgId}) => {
    const {Actions} = useAuth();
    const byLineText = "Manage connections with other organisations";
    const {url} = useRouteMatch();
    const backRoute = `../${orgId}`;
    const newConnectionRoute = `${url}/add`;
    const [loading, setLoading] = useState(true);
    const [processing, setProcessing] = useState(false);
    const [refreshTrigger, setRefreshTrigger] = useState(0);
    const [organisation, setOrganisation] = useState({});
    const {errorHandler} = useErrorHandler();

    useEffect(() => {
        if (orgId) {
            OrganisationService.getOrganisation(orgId)
                .then((org) => setOrganisation(org))
                .catch((e) => {
                    errorHandler(e, "retrieving the organisation");
                }).finally(() => setLoading(false));
        }
    }, [orgId, setLoading, errorHandler, setOrganisation]);

    const acceptConnection = (conn) => {
        setProcessing(true);
        AffiliationsService.accept(orgId, conn.id)
            .then(() => setRefreshTrigger(refreshTrigger + 1))
            .catch((e) => errorHandler(e, "accepting the connection."))
            .finally(() => setProcessing(false));
    };
    const declineConnection = (conn) => {
        setProcessing(true);
        AffiliationsService.decline(orgId, conn.id)
            .then(() => setRefreshTrigger(refreshTrigger + 1))
            .catch((e) => errorHandler(e, "declining the connection."))
            .finally(() => setProcessing(false));
    };

    const headers = [
        {
            title: "Connection", key: "typeLabel", width: "20", filterable: true, sortable: true, sortKey: "type",
            render: (colData, rel) => <Button as="tertiary" route={`${url}/${rel.id}`}>{colData}</Button>
        },
        {title: "Organisation", key: "toName", width: "30", filterable: true, sortable: true, sortKey: "to.name"},
        {
            title: "Sport",
            key: "sport",
            width: "20",
            filterable: true,
            sortable: true,
            sortKey: "sport.name",
            render: (sport) => sport ? sport.name : ""
        },
        {title: "Status", key: "statusLabel", width: "20", filterable: true, sortable: true, sortKey: "status"},
        {
            title: "Action", width: "10", render: (colData, conn) => {
                return conn.status === "AWAITING_RESPONSE"
                    ? <Protected action={[Actions.ORG_AFFILIATIONS_ACCEPT, Actions.ORG_AFFILIATIONS_DECLINE]}
                                 orgId={orgId}>
                        <ConfirmBtn onClick={() => declineConnection(conn)}
                                    disabled={processing}
                                    as="tertiary">
                            Decline
                        </ConfirmBtn>
                        <Button onClick={() => acceptConnection(conn)}
                                disabled={processing}
                                as="tertiary">
                            Accept
                        </Button>
                    </Protected>
                    : null;
            }
        }
    ];

    const getAffiliations = (...args) => {
        return AffiliationsService.getAffiliations(orgId, ...args);
    };

    const emptyMessage = <div className="noAffiliationsMessage">
        <p>On no, you haven't connected with any organisations yet.</p>
        <ProtectedButton
            action={[Actions.ORG_AFFILIATIONS_REQUEST, Actions.AFFILIATED_ORG_CREATE]}
            orgId={organisation.id}
            as="tertiary"
            route={newConnectionRoute}>Click here to get started</ProtectedButton>
    </div>;

    return <div
        className="row organisation-affiliations px-2 d-md-flex flex-row flex-wrap align-content-stretch justify-content-between align-items-stretch">
        <div className="col-sm-12">
            <SDSsubPageTitle byLine={byLineText} backButtonRoute={backRoute}>
                Connections
            </SDSsubPageTitle>
        </div>
        <div className="col-sm-12 align-self-stretch mb-4">
            <SDSpanel title={organisation.name}
                      loading={loading}
                      action={<ProtectedButton
                          action={[Actions.ORG_AFFILIATIONS_REQUEST, Actions.AFFILIATED_ORG_CREATE]}
                          orgId={organisation.id}
                          className="sds-btn--block"
                          route={newConnectionRoute}
                          icon={<SDSicon group="fa" name="plus"/>}>
                          Create a new connection
                      </ProtectedButton>}>
                <p>View and manage {organisation.name}'s connections with other organisations</p>
                {
                    organisation
                    && <DataTable headers={headers}
                                  data={getAffiliations}
                                  spinner={<Spinner hideText={true} inline={true}/>}
                                  emptyMessage={emptyMessage}
                                  defaultSort="type"
                                  refreshTrigger={refreshTrigger}/>
                }
            </SDSpanel>
        </div>
    </div>;
};

OrganisationAffiliations.propTypes = {};

export default withAuth(OrganisationAffiliations);