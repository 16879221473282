import React from "react"
import Alert from "@sportaus-digital/alert";
import Button from "@sportaus-digital/buttons";

const PageNotFound = () => {
    return <div className="container-fluid sds-container__self-service">
        <div className="row">
            <div className="container-content">
                <Alert message="Page not found"/>
                <div className="text-center mt-5">
                    <Button as="tertiary"
                               route="/home">
                        Take me home
                    </Button>
                </div>
            </div>
        </div>
    </div>;
};

export default PageNotFound;