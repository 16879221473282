export const SportFunctionLabelMapper = (functionCode, functionName) => {
    let label;

    switch (functionCode) {
        case "CLUB":
        case "SPORT_ORGANISATION":
            label = "What sport(s) does this club offer?";
            break;
        default:
            label = "Which sport(s) does this " + functionName + " represent?";
            break;
    }

    return label;
};