import React, {FC, ReactElement} from "react"
import "./card.scss";

/**
 * A simple Card content container, with a default border and optional action controls.
 */
export const Card: FC<CardProps> = ({
                                        title,
                                        noBorder,
                                        titleLevel = 2,
                                        actions,
                                        children, className,
                                        titleClassName,
                                        buttonRowClassName
                                    }: CardProps) => {
    const Header: any = "h" + (titleLevel);

    let controls = null;
    if (actions) {
        if (actions instanceof Array && actions.length > 0) {
            if (actions.length === 1) {
                controls = <div className={`sds-card__genericButtonRow ${buttonRowClassName || ''}`}>
                    {actions[0]}
                </div>
            } else if (actions.length === 2) {
                controls = <div className={`sds-card__doubleButtonRow ${buttonRowClassName || ''}`}>
                    <div className="sds-card__leftContainer">
                        {actions[0]}
                    </div>
                    <div className="sds-card__rightContainer">
                        {actions[1]}
                    </div>
                </div>
            } else {
                controls = <div className={`sds-card__genericButtonRow ${buttonRowClassName || ''}`}>
                    {actions}
                </div>
            }
        } else {
            controls = <div className={`sds-card__genericButtonRow ${buttonRowClassName || ''}`}>
                {actions}
            </div>
        }
    }

    return <div className={`sds-card ${noBorder ? "sds-card--no-border" : ""} ${className || ''}`}>
        {title && <Header className={`sds-card__title ${titleClassName}`}>{title}</Header>}

        {children}

        {controls}
    </div>;
};

export interface CardProps {
    /**
     * The title of the card. This will add a heading element (the header level is defined by the titleLevel prop).
     */
    title?: string,
    /**
     * The heading level of the card title, if present.
     *
     * @default 2
     */
    titleLevel?: number,
    /**
     * True to hide the border of the card.
     *
     * @default false
     */
    noBorder?: boolean,
    /**
     * Action controls to add to the bottom of the card.
     *
     * If there is only 1 control, it will be right aligned;
     * If there are 2 controls, one will be left align and the other right aligned, with padding;
     * If there are > 2 controls, they will be displayed in a right aligned row.
     */
    actions?: ReactElement | Array<ReactElement>,
    /**
     * Additional CSS classnames for the card's outer container
     */
    className?: string | Array<string>,
    /**
     * Additional CSS classnames for the card's title
     */
    titleClassName?: string | Array<string>,
    /**
     * Additional CSS classnames for the card's action control container
     */
    buttonRowClassName?: string | Array<string>,
    /**
     * Child components/content
     */
    children: any
}

export default Card;