import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import {ApplicationGroupService, ApplicationService, GroupInvitationService} from "../../../../../service/service";
import SDSform, {FieldTypes, FormBuilder} from "../../../../../components/form/form";
import {SDSsubPageTitle} from "../../../../../components/page-title/page-title";
import SDSpanel from "../../../../../components/panel/panel";
import {withAuth} from "../../../../../security/authorisation";
import useErrorHandler from "../../../../../hooks/error-handler";
import AUpageAlert from "@gov.au/page-alerts"

const GroupsInvitation = ({orgId, appId, application}) => {
    const [app, setApp] = useState({});
    const [saved, setSaved] = useState();
    const {errorHandler} = useErrorHandler();
    const [loading, setLoading] = useState(false);
    const backRoute = `../../${appId}`;

    useEffect(() => {
        if (application) {
            setApp(application);
            setLoading(false);
        } else {
            ApplicationService.getApplication(orgId, appId)
              .then(a => {
                  setApp(a);
                  setLoading(false);
              })
              .catch((e) => errorHandler(e, "retrieving the application details"));
        }
    }, [orgId, appId, application, setApp, errorHandler, setLoading]);

    const formBuilder = new FormBuilder();

    const config = formBuilder
        .field("email", "Contact email", FieldTypes.EMAIL).placeholder('Enter an email address').required().email()
        .wrapperClasses("col-sm-9")
        .field("group", "Group", FieldTypes.SELECT)
        .required()
        .options(async () => await ApplicationGroupService.getGroupsForApplications(orgId, appId), (g) => ({
            label: g.name,
            value: g.id
        }))
        .wrapperClasses("col-sm-3")
        // TODO: Management link
        // .renderAfter(<div className="manage-roles-link col-sm-12 text-right mb-5">
        //     <ProtectedButton action={Actions.ORG_LIST_ALL_ROLES}
        //                      orgId={orgId}
        //                      className="mb-3"
        //                      route={'./roles'}>Manage roles</ProtectedButton>
        // </div>)

        .build();

    const submitHandler = (formData, actions) => {
        let postBody = {
            email: formData.email,
            groupId: formData.group,
            appId: appId,
            orgId: orgId
        };

        GroupInvitationService.sendInvitation(postBody, orgId, postBody.groupId, appId).then(() => {
            actions.setSubmitting(false);
            actions.resetForm();
            setSaved(postBody.email);
        }).catch((e) => {
            let message = (e && e.response &&  e.response.data) ? e.response.data.reason : undefined;
            actions.setStatus({status: "error", message: message});
            actions.setSubmitting(false);
        });
    };

    return (
        <div className="organisation-invitation-add row px-2 d-md-flex flex-row flex-wrap align-content-stretch justify-content-between align-items-stretch">
            <div className="col-sm-12">
                <SDSsubPageTitle
                    backButtonRoute={backRoute}
                    byLine={`Invite a person to ${app.name}`}>
                    Invite a person
                </SDSsubPageTitle>
            </div>
            <div className="col-sm-12 align-self-stretch mb-4">
                <SDSpanel>
                    {saved && 
                        <div className="row">
                            <div className="col-sm-12">
                                <AUpageAlert as="success">
                                    <p>An invitation email has been sent to {saved}.</p>
                                </AUpageAlert>
                            </div>
                        </div>
                    }
                    <div className="row">
                        <SDSform config={config}
                                progressIndicator={<span className="fa fa-spinner fa-spin ml-1 mr-1"/>}
                                submitHandler={submitHandler}
                                submitLabel="Send invite"
                                disableSubmit={loading}
                                cancelRoute={backRoute}/>
                    </div>
                </SDSpanel>
            </div>
        </div>
    )
};

GroupsInvitation.propTypes = {
    orgId: PropTypes.number.isRequired
};

export default withAuth(GroupsInvitation);