import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React, {useContext} from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import GlobalAlertContextProvider, {GlobalAlertActionsContext} from "./utils/global-alerts";
import AuthContextProvider, {AuthActionsContext, AuthContext} from "./security/auth-context";
import {KeycloakProvider} from "@react-keycloak/web";
import keycloak from "./security/keycloak";
import SDSfullPageLoader from "./components/full-page-loader/full-page-loader"
import "./assets/scss/global.scss";
import logo from "./assets/img/sportconnect_mono.svg";
import {UserService} from "./service/service"
import CspSafeEmotion from "@sportaus-digital/csp-safe-emotion";
import * as Terms from "./constants/terms"


const Root = () => {
    const setGlobalAlert = useContext(GlobalAlertActionsContext);
    const setUserProfile = useContext(AuthActionsContext);
    const userProfile = useContext(AuthContext);

    const timeoutHandler = () => {
        if (!keycloak.initialized) {
            setGlobalAlert({message: "Unable to contact the authentication server. Some application features may not be available."});
            setUserProfile({keycloakFailed: true});
        }
    };

    const authEventHandler = (event) => {
        if (event === "onAuthSuccess") {
            const profile = {
                authenticated: true,
                emailVerified: keycloak.tokenParsed.email_verified,
                username: keycloak.tokenParsed.preferred_username,
                groups: keycloak.realmAccess ? keycloak.realmAccess.roles || [] : [],
                permissions: [],
                fullyInitialised: false
            };
            setUserProfile(profile);

            if (!profile.emailVerified) {
                setGlobalAlert({
                    severity: "warning",
                    message: "You have not verified your email address. Some features will not be available without a verified email address.",
                    action: {
                        text: "Re-send the verification email",
                        onClick: UserService.resendEmailVerification
                    }
                })
            }

            UserService.getUserProfile()
                .then(profData => setUserProfile({
                    ...profile,
                    ...profData,
                    fullyInitialised: true
                }))
                .catch(() => {
                    setGlobalAlert({
                        message: "Failed to retrieve user profile information. Some application features may not be available."
                    });
                });
        } else if (event === "onAuthRefreshError") {
            keycloak.logout({redirectUri: window.location.origin}).then(() => {
                setUserProfile({});
            });
        }
    };

    const loader = <SDSfullPageLoader timeoutHandler={timeoutHandler}
                                      logo={logo}
                                      logoAlt={`${Terms.PRODUCT_NAME} logo`}/>;

    return userProfile.keycloakFailed ?
        <App/> :
        <KeycloakProvider keycloak={keycloak}
                          onEvent={authEventHandler}
                          LoadingComponent={loader}>
            <App/>
        </KeycloakProvider>;
};


ReactDOM.render(<CspSafeEmotion>
    <GlobalAlertContextProvider>
        <AuthContextProvider>
            <Root/>
        </AuthContextProvider>
    </GlobalAlertContextProvider>
</CspSafeEmotion>, document.getElementById('root'));