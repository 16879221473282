import React, { useState } from "react";
import Button from "@sportaus-digital/buttons";
import { Form, Field, Formik, ErrorMessage } from "formik";
import { SDSlandingPageTitle } from "../../../components/page-title/page-title";
import * as Yup from "yup";
import { useHistory } from "react-router";
import "../../../components/form/form.scss";
import SDSpanel from "../../../components/panel/panel";
import {useAuth} from "../../../security/authorisation";
import { IdentityService } from "../../../service/service";
import useErrorHandler from "./error-handler";
import SelectState from "./selectState";
import DateOfBirth from "./dateOfBirth";
import DocumentSubmitMessage from "./documentSubmitMessage";
import DocumentSubmitButtons from "./documentSubmitButtons";

const BirthCertificate = () => {
  const router = useHistory();
  const {userProfile} = useAuth();
  const backRoute = "../";
  const documentType = "birthcertificate";
  const {errorHandler, clearError} = useErrorHandler();
  const [australianState, setAustralianState] = useState("");
  const [showBack, setShowBack] = useState(false);

  const initialValues = {
    australianState: australianState,
    dobDD: "",
    dobMM: "",
    dobYYYY: "",
    givenNames: userProfile.firstName,
    familyName: userProfile.lastName,
    registrationNumber: "",
    certificateNumber: "",
    regDateDD: "",
    regDateMM: "",
    regDateYYYY: "",
    consent: false,
  };

  const validationSchema = Yup.object().shape(
    {
      australianState: Yup.string().required("Please select a State"),
      dobDD: Yup.number().label('Day')
        .min(1)
        .max(31)
        .when("australianState", {
          is: (australianState) => !['NT'].includes(australianState),
          then: Yup.number().required("Please enter a valid day (DD)")
        }),
      dobMM: Yup.number().label('Month')
        .min(1)
        .max(12)
        .when("australianState", {
          is: (australianState) => !['NT'].includes(australianState),
          then: Yup.number().required("Please enter a valid month (MM)")
        }),
      dobYYYY: Yup.number().label('Year')
        .min(1753)
        .max(3000)
        .required("Please enter a valid year (YYYY)"),
      givenNames: Yup.string().label("Given Name").when("australianState", {
        is: (australianState) => ['ACT', 'NT', 'QLD', 'SA', 'TAS'].includes(australianState),
        then: Yup.string().required(),
      }).max(80),
      familyName: Yup.string().label("Family Name").when("australianState", {
        is: (australianState) => ['ACT', 'NT', 'QLD', 'SA', 'TAS'].includes(australianState),
        then: Yup.string().required(),
      }).max(80),
      regDateDD: Yup.number().label('Day')
        .min(1)
        .max(31)
        .when("australianState", {
          is: (australianState) => ['QLD', 'TAS'].includes(australianState),
          then: Yup.number().when("certificateNumber", {
            is: (certificateNumber) => !certificateNumber || certificateNumber.length === 0,
            then: Yup.number().required("Registration Date or Certificate Number is mandatory"),
          })
        }),
      regDateMM: Yup.number().label('Month')
        .min(1)
        .max(12)
        .when("australianState", {
          is: (australianState) => ['QLD', 'TAS'].includes(australianState),
          then: Yup.number().when("certificateNumber", {
            is: (certificateNumber) => !certificateNumber || certificateNumber.length === 0,
            then: Yup.number().required("Registration Date or Certificate Number is mandatory"),
          })
        }),
      regDateYYYY: Yup.number().label('Year')
        .min(1900)
        .max(new Date().getFullYear())
        .when("australianState", {
          is: (australianState) => ['QLD', 'TAS'].includes(australianState),
          then: Yup.number().when("certificateNumber", {
            is: (certificateNumber) => !certificateNumber || certificateNumber.length === 0,
            then: Yup.number().required("Registration Date or Certificate Number is mandatory"),
          })
        }),
      registrationNumber: Yup.string().label("Registration Number").when("australianState", {
        is: (australianState) => !['QLD', 'TAS'].includes(australianState),
        then: Yup.string().when("certificateNumber", {
          is: (certificateNumber) => !certificateNumber || certificateNumber.length === 0,
          then: Yup.string().required("Registration or Certificate Number is mandatory"),
        }),
      }).max(10),
      certificateNumber: Yup.string().label("Certificate Number").when("australianState", {
        is: (australianState) => ['QLD', 'TAS'].includes(australianState),
        then: Yup.string().when("regDateDD", {
          is: (regDateDD) => !regDateDD || regDateDD.length === 0,
          then: Yup.string().required("Registration Date or Certificate Number is mandatory")
        }),
      }).when("australianState", {
        is: (australianState) => !['QLD', 'TAS'].includes(australianState),
        then: Yup.string().when("registrationNumber", {
          is: (registrationNumber) => !registrationNumber || registrationNumber.length === 0,
          then: Yup.string().required("Registration or Certificate Number is mandatory"),
        }),
      }).max(12),
    },
    [["registrationNumber", "certificateNumber", "australianState", "regDateDD", "regDateMM", "regDateYYYY"]]
  );

  function handleAustralianState(value) {
    setAustralianState(value);
  }

  function handleRotateSample() {
    setShowBack(!showBack);
  }

  function getSampleImage(australianState, showBack) {
    if (!australianState) {
      return "";
    } else if (australianState === "WA") {
      return (
        <img className="identity-doc-sample" src={`${process.env.PUBLIC_URL}/images/identity/bc_${australianState.toLowerCase()}_front.jpg`} alt="" />
      );
    }
    return (
      <>
        <div className="sds-form__standardActions">
          <div className="float-right">
            <Button type="button" onClick={handleRotateSample} as="tertiary"><i className="fas fa-sync"></i></Button>
          </div>
        </div>
        {
          showBack ? 
          <img className="identity-doc-sample" src={`${process.env.PUBLIC_URL}/images/identity/bc_${australianState.toLowerCase()}_back.jpg`} alt="" />
          :
          <img className="identity-doc-sample" src={`${process.env.PUBLIC_URL}/images/identity/bc_${australianState.toLowerCase()}_front.jpg`} alt="" />
        }
      </>
    );
}

  function onSubmit(fields, { setStatus, setSubmitting, resetForm }) {
    setStatus();
    clearError();

    const document = {
      consentToDocumentCheck: fields.consent,
      birthDateDD: fields.dobDD,
      birthDateMM: fields.dobMM,
      birthDateYYYY: fields.dobYYYY,
      givenName: fields.givenNames,
      familyName: fields.familyName,
      registrationNumber: fields.registrationNumber,
      certificateNumber: fields.certificateNumber,
      state: fields.australianState,
      registrationDateDD: fields.regDateDD,
      registrationDateMM: fields.regDateMM,
      registrationDateYYYY: fields.regDateYYYY
    };
    IdentityService.validateDocument(documentType, document)
      .then((result) => {
        setSubmitting(false);
        resetForm();
        router.push(backRoute);
      })
      .catch((err) => {
        errorHandler(err, 'Birth Certificate');
        setStatus({ status: "errror" });
        setSubmitting(false);
      });
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ errors, touched, isSubmitting }) => {
        return (
          <Form className="sds-form">
            <div className="row">
              <div className="col-sm-12">
                <SDSlandingPageTitle
                  icon={<span className="fas fa-user-circle fa-4x" />}
                  byLine="Please provide Birth Certificate details"
                >
                  Birth Certificate Details
                </SDSlandingPageTitle>
              </div>
            </div>

            <SDSpanel>
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="australianState">
                      <strong>State of Issue</strong>
                    </label>
                    <div className="form-row">
                      <div className="col-xs-12">
                        <SelectState
                          name="australianState"
                          onStateChange={handleAustralianState}
                          className={
                            "form-control" +
                            (errors.australianState && touched.australianState
                              ? " is-invalid"
                              : "")
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <label htmlFor="familyName">
                      <strong>Family Name {['ACT', 'NT', 'QLD', 'SA', 'TAS'].includes(australianState) ? '*' : ''}</strong>
                    </label>
                    <div className="form-row">
                      <div className="col-xs-12">
                        <Field
                          name="familyName"
                          type="text"
                          as="input"
                          placeholder="Family Name"
                          className={
                            "form-control" +
                            (errors.familyName && touched.familyName
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="familyName"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <label htmlFor="givenNames">
                      <strong>Given Name/s  {['ACT', 'NT', 'QLD', 'SA', 'TAS'].includes(australianState) ? '*' : ''}</strong>
                    </label>
                    <div className="form-row">
                      <div className="col-xs-12">
                        <Field
                          name="givenNames"
                          type="text"
                          as="input"
                          placeholder="Given Name/s"
                          className={
                            "form-control" +
                            (errors.givenNames && touched.givenNames
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="givenNames"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                  </div>

                  <DateOfBirth
                      errors={errors}
                      touched={touched}
                      required="true"
                    />
                  {!['QLD', 'TAS'].includes(australianState) && (
                  <div className="form-group">
                    <label htmlFor="registrationNumber">
                      <strong>Registration Number *</strong>
                    </label>
                    <div className="form-row">
                      <div className="col-xs-12">
                        <Field
                          name="registrationNumber"
                          type="text"
                          as="input"
                          maxLength="20"
                          placeholder="Enter Registration Number"
                          className={
                            "form-control" +
                            (errors.registrationNumber && touched.registrationNumber
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="registrationNumber"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                  </div>
                  )}

                  {['QLD', 'TAS'].includes(australianState) && (
                  <div className="form-group">
                    <label>
                      <strong>Registration Date *</strong>
                    </label>
                    <div className="form-row justify-content-start">
                        <div className="col-xs-4">
                          <Field
                            name="regDateDD"
                            type="text"
                            as="input"
                            placeholder="DD"
                            maxLength="2"
                            className={
                              "form-control" +
                              (errors.regDateDD && touched.regDateDD
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name="regDateDD"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      <div className="col-xs-4">
                        <Field
                          name="regDateMM"
                          type="text"
                          as="input"
                          placeholder="MM"
                          maxLength="2"
                          className={
                            "form-control" +
                            (errors.regDateMM && touched.regDateMM
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="regDateMM"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="col-xs-4">
                        <Field
                          name="regDateYYYY"
                          type="text"
                          as="input"
                          placeholder="YYYY"
                          maxLength="4"
                          className={
                            "form-control" +
                            (errors.regDateYYYY && touched.regDateYYYY
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="regDateYYYY"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                  </div>
                  )}

                  <p>
                    <strong>OR</strong>
                  </p>

                  <div className="form-group">
                    <label htmlFor="certificateNumber">
                      <strong>Certificate Number *</strong>
                    </label>
                    <div className="form-row">
                      <div className="col-xs-12">
                        <Field
                          name="certificateNumber"
                          type="text"
                          as="input"
                          maxLength="20"
                          placeholder="Enter Certificate Number"
                          className={
                            "form-control" +
                            (errors.certificateNumber && touched.certificateNumber
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="certificateNumber"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                  </div>

                  <DocumentSubmitMessage name="consent" errors={errors} />
                </div>

                <div className="col-lg-6">
                  { getSampleImage(australianState, showBack) }
                </div>
              </div>

              <DocumentSubmitButtons backRoute={backRoute} isSubmitting={isSubmitting} />
              
            </SDSpanel>
          </Form>
        );
      }}
    </Formik>
  );
};

export default BirthCertificate;
