import React, { useState } from "react";

import { Form, Field, Formik, ErrorMessage } from "formik";
import { SDSlandingPageTitle } from "../../../components/page-title/page-title";
import * as Yup from "yup";
import { useHistory } from "react-router";
import "../../../components/form/form.scss";
import SDSpanel from "../../../components/panel/panel";
import {useAuth} from "../../../security/authorisation";
import { IdentityService } from "../../../service/service";
import useErrorHandler from "./error-handler";
import DocumentSubmitMessage from "./documentSubmitMessage";
import DocumentSubmitButtons from "./documentSubmitButtons";
import DateOfBirth from "./dateOfBirth";
import MedicareCardColour from "./medicareCardColour";

const MedicareCard = () => {
  const router = useHistory();
  const {userProfile} = useAuth();
  const backRoute = "../";
  const documentType = "medicare";
  const [cardColour, setCardColour] = useState("G");
  const [nameMultiLines, setNameMultiLines] = useState("No");
  const {errorHandler, clearError} = useErrorHandler();

  let thisYear = new Date().getFullYear();

  const initialValues = {
    consent: false,
    dobDD: "",
    dobMM: "",
    dobYYYY: "",
    medicareNumberPart1: "",
    medicareNumberPart2: "",
    medicareNumberPart3: "",
    medicareNumberRef: "",
    nameMultiLines: "No",
    medicareName1: userProfile.firstName + " " + userProfile.lastName,
    medicareName2: "",
    medicareName3: "",
    medicareName4: "",
    expiryDD: "",
    expiryMM: "",
    expiryYYYY: "",
    cardColour: "G",
  };

  const validationSchema = Yup.object().shape({
    dobDD: Yup.number().label('Day')
      .min(1)
      .max(31),
    dobMM: Yup.number().label('Month')
      .min(1)
      .max(12),
    dobYYYY: Yup.number().label('Year')
      .min(1900)
      .max(thisYear),
    medicareNumberPart1: Yup.string()
      .required("Please enter a 4 digit number")
      .length(4, 'Must be exactly 4 digits')
      .matches(/^\d+$/gi, 'Must be exactly 4 digits'),
    medicareNumberPart2: Yup.string()
      .required("Please enter a 5 digit number")
      .length(5, 'Must be exactly 5 digits')
      .matches(/^\d+$/gi, 'Must be exactly 5 digits'),
    medicareNumberPart3: Yup.string()
      .required("Please enter a number (1-9)")
      .length(1, 'Must be exactly 1 digit')
      .matches(/^\d+$/gi, 'Must be exactly 1 digit'),
    medicareName1: Yup.string().max(27).required("Please provide a name").label("Name"),
    medicareName2: Yup.string().max(25).label("Name Line 2"),
    medicareName3: Yup.string().max(23).label("Name Line 3"),
    medicareName4: Yup.string().max(21).label("Name Line 4"),
    medicareNumberRef: Yup.number().label('Reference Number')
      .min(1)
      .max(9)
      .required("Please enter a valid reference number"),
    cardColour: Yup.string(),
    expiryDD: Yup.number().label('Day').when("cardColour", {
      is: (cardColour) => cardColour === "B" || cardColour === "Y",
      then: Yup.number()
        .min(1)
        .max(31)
        .required("Please enter a valid expiry day (DD)"),
    }),
    expiryMM: Yup.number().label('Month')
      .min(1)
      .max(12)
      .required("Please enter a valid expiry month (MM)"),
    expiryYYYY: Yup.number().label('Year')
      .min(2000)
      .max(thisYear + 20)
      .required("Please enter a valid expiry year (YYYY)"),
  });

  function handleCardColour(value) {
    setCardColour(value);
  }

  function handleNameMultiLines(event) {
    setNameMultiLines(event.target.value);
  }

  function onSubmit(fields, { setStatus, setSubmitting, resetForm }) {
    setStatus();
    clearError();

    const document = {
      consentToDocumentCheck: fields.consent,
      birthDateDD: fields.dobDD,
      birthDateMM: fields.dobMM,
      birthDateYYYY: fields.dobYYYY,
      cardExpiry: fields.cardColour === "G" ? `${fields.expiryYYYY}-${fields.expiryMM}` : `${fields.expiryYYYY}-${fields.expiryMM}-${fields.expiryDD}`,
      cardNumber: `${fields.medicareNumberPart1}${fields.medicareNumberPart2}${fields.medicareNumberPart3}`,
      cardType: fields.cardColour,
      fullName1: `${fields.medicareName1}`,
      fullName2: `${fields.medicareName2}`,
      fullName3: `${fields.medicareName3}`,
      fullName4: `${fields.medicareName4}`,
      individualReferenceNumber: `${fields.medicareNumberRef}`,
    };
    IdentityService.validateDocument(documentType, document)
      .then((result) => {
        console.log(result);
        setSubmitting(false);
        resetForm();
        router.push(backRoute);
      })
      .catch((err) => {
        errorHandler(err, 'Medicare Card');
        setStatus({ status: "error" });
        setSubmitting(false);
      });
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ errors, touched, isSubmitting, setFieldValue }) => {
        return (
          <Form className="sds-form">
            <div className="row">
              <div className="col-sm-12">
                <SDSlandingPageTitle
                  icon={<span className="fas fa-user-circle fa-4x" />}
                  byLine="Please provide Medicare Card details"
                >
                  Medicare Card Details
                </SDSlandingPageTitle>
              </div>
            </div>
            <SDSpanel>
              <div className="row">
                <div className="col-lg-6">
                
                  <MedicareCardColour
                    name="cardColour"
                    onStateChange={handleCardColour}
                    />

                  <DateOfBirth
                      errors={errors}
                      touched={touched}
                    />

                  <div className="form-group">
                    <label>
                      <strong>Medicare Card Number *</strong>
                    </label>
                    <div className="form-row">
                      <div className="col-xs-4">
                        <Field
                          name="medicareNumberPart1"
                          type="text"
                          as="input"
                          maxLength="4"
                          className={
                            "form-control" +
                            (errors.medicareNumberPart1 &&
                            touched.medicareNumberPart1
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="medicareNumberPart1"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="col-xs-6">
                        <Field
                          name="medicareNumberPart2"
                          type="text"
                          as="input"
                          maxLength="5"
                          className={
                            "form-control" +
                            (errors.medicareNumberPart2 &&
                            touched.medicareNumberPart2
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="medicareNumberPart2"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="col-xs-2">
                        <Field
                          name="medicareNumberPart3"
                          type="text"
                          as="input"
                          maxLength="1"
                          className={
                            "form-control" +
                            (errors.medicareNumberPart3 &&
                            touched.medicareNumberPart3
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="medicareNumberPart3"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <label>
                      <strong>Individual Reference Number *</strong>
                    </label>
                    <div className="form-row">
                      <div className="col-xs-4">
                        <Field
                          name="medicareNumberRef"
                          type="text"
                          as="input"
                          maxLength="1"
                          className={
                            "form-control" +
                            (errors.medicareNumberRef && touched.medicareNumberRef
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="medicareNumberRef"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <strong>
                      Does name appear across more than one line of the card?
                    </strong>
                    <div className="form-check">
                      <div className="form-row">
                        <div className="col">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="nameMultiLines"
                            id="nameMultiLinesNo"
                            value="No"
                            onChange={handleNameMultiLines}
                            defaultChecked
                          />
                          <label
                            className="form-check-label"
                            htmlFor="nameMultiLinesNo"
                          >
                            No
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="nameMultiLines"
                            id="nameMultiLinesYes"
                            onChange={handleNameMultiLines}
                            value="Yes"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="nameMultiLinesYes"
                          >
                            Yes
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <label>
                      <strong>Name {nameMultiLines === "Yes" && ('Line 1')} * </strong>
                    </label>
                    <div className="form-row">
                      <div className="col-xs-12">
                        <Field
                          name="medicareName1"
                          type="text"
                          as="input"
                          className={
                            "form-control" +
                            (errors.medicareName1 && touched.medicareName1
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="medicareName1"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                  </div>
                  {nameMultiLines === "Yes" && (
                  <div>
                    <div className="form-group">
                      <label>
                        <strong>Name Line 2 </strong>
                      </label>
                      <div className="form-row">
                        <div className="col-xs-12">
                          <Field
                            name="medicareName2"
                            type="text"
                            as="input"
                            className={
                              "form-control" +
                              (errors.medicareName2 && touched.medicareName2
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name="medicareName2"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label>
                        <strong>Name Line 3 </strong>
                      </label>
                      <div className="form-row">
                        <div className="col-xs-12">
                          <Field
                            name="medicareName3"
                            type="text"
                            as="input"
                            className={
                              "form-control" +
                              (errors.medicareName3 && touched.medicareName3
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name="medicareName3"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label>
                        <strong>Name Line 4 </strong>
                      </label>
                      <div className="form-row">
                        <div className="col-xs-12">
                          <Field
                            name="medicareName4"
                            type="text"
                            as="input"
                            className={
                              "form-control" +
                              (errors.medicareName4 && touched.medicareName4
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name="medicareName4"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  )}

                  <div className="form-group">
                    <label>
                      <strong>Expiry Date *</strong>
                    </label>
                    <div className="form-row justify-content-start">
                      {cardColour !== "G" && (
                        <div className="col-xs-4">
                          <Field
                            name="expiryDD"
                            type="text"
                            as="input"
                            placeholder="DD"
                            maxLength="2"
                            className={
                              "form-control" +
                              (errors.expiryDD && touched.expiryDD
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name="expiryDD"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      )}
                      <div className="col-xs-4">
                        <Field
                          name="expiryMM"
                          type="text"
                          as="input"
                          placeholder="MM"
                          maxLength="2"
                          className={
                            "form-control" +
                            (errors.expiryMM && touched.expiryMM
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="expiryMM"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="col-xs-4">
                        <Field
                          name="expiryYYYY"
                          type="text"
                          as="input"
                          placeholder="YYYY"
                          maxLength="4"
                          className={
                            "form-control" +
                            (errors.expiryYYYY && touched.expiryYYYY
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="expiryYYYY"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                  </div>

                  <DocumentSubmitMessage name="consent" errors={errors} />
                </div>
                <div className="col-lg-6">
                  {
                    cardColour &&
                    <>
                      {
                        nameMultiLines === "Yes" ?
                        <img className="identity-doc-sample" src={`${process.env.PUBLIC_URL}/images/identity/medicare_${cardColour.toLowerCase()}_multi.jpg`} alt="" />
                        :
                        <img className="identity-doc-sample" src={`${process.env.PUBLIC_URL}/images/identity/medicare_${cardColour.toLowerCase()}.jpg`} alt="" />
                      }
                    </>
                  }
                </div>
              </div>

              <DocumentSubmitButtons backRoute={backRoute} isSubmitting={isSubmitting} />
            </SDSpanel>
          </Form>
        );
      }}
    </Formik>
  );
};

export default MedicareCard;
