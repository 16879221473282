import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import DataTable from "@sportaus-digital/data-table";
import Spinner from "@sportaus-digital/spinner";
import {ApplicationService, OrganisationService} from "../../../../../service/service";
import SDSpanel from "../../../../../components/panel/panel";
import {SDSsubPageTitle} from "../../../../../components/page-title/page-title";
import {Actions, ProtectedButton, withAuth} from "../../../../../security/authorisation";
import SDSicon from "../../../../../components/icons/icons";
import useErrorHandler from "../../../../../hooks/error-handler";
import * as Terms from "../../../../../constants/terms"

const OrganisationRoles = ({orgId}) => {
    const [apps, setApps] = useState([]);
    const [organisation, setOrganisation] = useState({});
    const {errorHandler} = useErrorHandler();

    useEffect(() => {
        OrganisationService.getOrganisation(orgId)
            .then(org => setOrganisation(org))
            .catch((e) => errorHandler(e, "retrieving the organisation"));
        ApplicationService.getApplicationsForOrg(orgId)
          .then(a => setApps(a))
          .catch((e) => errorHandler(e, "retrieving your applications"));
    }, [orgId, setOrganisation, setApps, errorHandler]);

    const headers = [
        {
            title: "Role",
            key: "role",
            sortable: true,
            render: (colData, role) => {
                return <ProtectedButton action={Actions.ORG_UPDATE_ROLES}
                                        orgId={orgId}
                                        disabled={role.systemRole}
                                        route={`./roles/${role.id}`}>
                    {role.role} {role.systemRole ? '(Default role)' : '(Custom role)'}
                </ProtectedButton>
            }
        },
        {
            title: "Capabilities",
            key: "capabilities",
            render: (colData, role) => {
                const hasAppSpecificScope = role.capabilities.some(c => c.appSpecific === true);
                const appSpecificCode = role.applications.filter(a => a !== undefined).map(a => {
                    let app = apps.find(a2 => a2.id === a);
                    if (app !== undefined) return <li key={app.id}>{app.name}</li>;
                    return "";
                });
                return <div><ul>
                        {role.capabilities.map(c => <li key={c.code}>{c.name}</li>)}
                        {hasAppSpecificScope ? <li>Applications:<ul>{appSpecificCode}</ul></li> : ""}
                    </ul></div>;
            }
        }
    ];

    let byLineText;
    if (organisation) {
        byLineText = <>View and create custom roles for people who can act on behalf of <em>{organisation.name}</em></>;
    }

    let rolesTable;
    if (organisation.allRoles) {
        rolesTable = <SDSpanel title="Roles" titleLevel={2}
                               action={<ProtectedButton action={Actions.ORG_CREATE_ROLES}
                                                        className="sds-btn--block"
                                                        route={'./roles/add'}
                                                        icon={<SDSicon group="fa" name="plus"/>}>
                                   Add roles</ProtectedButton>}>
            These are the different roles that people in <em>{organisation.name}</em> can have. The capabilities of each
            role provide different levels of access and functions in {Terms.PRODUCT_NAME}.
            <DataTable headers={headers}
                          spinner={<Spinner hideText={true} inline={true}/>}
                          data={organisation.allRoles}
                          defaultSort="role"/>
        </SDSpanel>;
    } else {
        rolesTable = null;
    }

    return <div className="row">
        <div className="col-sm-12">
            <SDSsubPageTitle byLine={byLineText} backButtonRoute={`../${orgId}`}>Organisation roles</SDSsubPageTitle>
        </div>
        <div className="col-sm-12">
            {rolesTable}
        </div>
    </div>;
};

OrganisationRoles.propTypes = {
    orgId: PropTypes.number.isRequired
};

export default withAuth(OrganisationRoles);