import "./role-form.scss";
import React from "react"
import PropTypes from "prop-types"
import {FieldTypes} from "../../components/form/field-builder";
import {FormBuilder} from "../../components/form/form-builder";
import {OrganisationService} from "../../service/service";
import SDSform from "../../components/form/form";
import {Actions, Protected} from "../../security/authorisation";
import ConfirmBtn from "@sportaus-digital/confirm-btn";
import * as yup from "yup";

const RoleForm = props => {
    const {orgId, currentRole, orgApps, submitHandler, cancelHandler, cancelRoute, deleteHandler} = props;

    const orgCapabilities = currentRole.capabilities ? currentRole.capabilities  : [];
    const capabilityApps = currentRole.applications ? currentRole.applications  : [];

    const applicationValidationSchema = yup.array()
      .of(
        yup.object().shape({
            capability: yup.string(),
            applications: yup.array().of(yup.string()).required((a, b, c) => {
                return "One or more selected capabilities require an application to be selected as well"
            }),
            label: yup.string()
        }).nullable()
      ).nullable();

    const capabilityChangeHandler = (selectedCapabilities, arrayHelpers) => {
        let hasAppSpecificCapability = false;
        selectedCapabilities = selectedCapabilities || [];
        selectedCapabilities.forEach(c => {
            // Ensure we will display the GUI
            if (c.appSpecific) {
                hasAppSpecificCapability = true;
            }
            // And ready data
            let alreadyHasData = arrayHelpers.form.values.applications.find(s => s.capability === c.code);
            if (c.appSpecific && !alreadyHasData) {
                arrayHelpers.push({
                    capability: c.code,
                    applications: [],
                    label: "Which application(s) does this role apply to?"
                });
            }
        });

        // After the loop, we know if we found anything app specific. Cleanup removed data
        if (!hasAppSpecificCapability && arrayHelpers.form.values.applications) {
            arrayHelpers.form.values.applications.forEach(a => {arrayHelpers.remove(a)});
        }
    };

    // Prepping for appSpecific capabilities. We only need to find 1 code... it doesn't matter if there are multiple
    // as it gets flattened on submit. This is NOT like the relationship functions and sports have, which uses similar
    // components.
    let appSpecificCode = undefined;
    orgCapabilities.forEach(c => {
        appSpecificCode = (c.appSpecific === true) ? c.code : appSpecificCode;
    });
    let appMapped = [];
    if (appSpecificCode !== undefined) {
        appMapped = [{
            capability: appSpecificCode,
            applications: capabilityApps,
            label: "Which application(s) does this role apply to?"
        }];
    }
    // We don't use a mapper. Instead we flattened the data before we hand it to the component
    const emptyMapper = () => {};
    // This is just to improve readability below
    const appOptions = orgApps.map(a => ({
        label: a.name,
        value: a.id
    }));

    const form = new FormBuilder()
        .field("id", "", FieldTypes.HIDDEN).value(currentRole.id)
        .field("role", "Role name", FieldTypes.TEXT).required().value(currentRole.role)

        .startFieldSet("Capabilities")
        .dynamicField("applications")
        .field("capabilities", "Role capabilities", FieldTypes.SELECT).value(orgCapabilities.map(c => c.code))
        .options(async () => await OrganisationService.getAvailableUserCapabilities(orgId), (cap) => ({
            label: cap.name,
            value: cap.code,
            appSpecific: cap.appSpecific
        })).required().multiple()
        .onChange(capabilityChangeHandler)

        .field("applications", "Application Group Management", FieldTypes.SELECT)
        .value(appMapped)
        .options(appOptions, emptyMapper).multiple().withValidation(applicationValidationSchema)

        .endFieldSet()
        .build();

    let removeRole;
    if (deleteHandler) {
        removeRole = <Protected action={Actions.ORG_DELETE_ROLES}>
            <ConfirmBtn className="role-form__delete" as={"secondary"}
                           disabled={currentRole.systemRole}
                           onClick={() => deleteHandler(currentRole)}>
                Remove this role
            </ConfirmBtn>
        </Protected>;
    }

    return <SDSform config={form}
                    progressIndicator={<span className="fa fa-spinner fa-spin ml-1 mr-1"/>}
                    submitHandler={submitHandler}
                    cancelHandler={cancelHandler}
                    cancelRoute={cancelRoute}
                    nonFormActions={removeRole} />;
};

RoleForm.propTypes = {
    orgId: PropTypes.number.isRequired,
    currentRole: PropTypes.object,
    submitHandler: PropTypes.func.isRequired,
    cancelHandler: PropTypes.func,
    cancelRoute: PropTypes.string,
    deleteHandler: PropTypes.func
};

RoleForm.defaultProps = {
    currentRole: {}
};

export default RoleForm;