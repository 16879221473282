import React, {FC, ReactElement} from "react";
import {default as ReactSelect, components} from "react-select";
import makeAnimated from "react-select/animated";
import {Option, StandardProps, classes, isDisabled} from "./_common";
import "./input-fields.scss";

export const Select: FC<SelectProps> = ({
                                            id,
                                            name,
                                            options,
                                            disabled,
                                            multiple,
                                            value,
                                            autoSelectSingleOption,
                                            onChange,
                                            onBlur,
                                            inline,
                                            inputClasses,
                                            OptionComponent,
                                            ...rest
                                        }: SelectProps) => {
    const animatedComponents = makeAnimated();

    const Option = (props: any): ReactElement => {
        return <components.Option {...props}>
            {OptionComponent ? <OptionComponent {...props}/> : props.children}
        </components.Option>
    };

    if (autoSelectSingleOption && options && options.length === 1) {
        value = options[0];
    }

    return <ReactSelect {...rest}
                        id={id || name}
                        options={options}
                        components={{...animatedComponents, Option}}
                        isDisabled={isDisabled(disabled)}
                        name={name}
                        isClearable={true}
                        isMulti={multiple}
                        closeMenuOnSelect={!multiple}
                        classNamePrefix="sds-select"
                        className={classes("sds-select", inputClasses)}
                        value={value}
                        onBlur={onBlur}
                        onChange={onChange}/>
};

export type SelectProps = {
    /**
     * True to allow multiple selections.
     */
    multiple?: boolean,
    /**
     * The current selected value
     */
    value?: Option | Array<Option>,
    /**
     * Available options for the input field. This can be either an array of objects,
     * or a function that returns a Promise which, when resolved, returns an array of objects.
     *
     * The objects must have the structure {label: "", value: ?}.
     */
    options: Array<Option>,
    /**
     * Automatically select the option if there is only 1 available option
     */
    autoSelectSingleOption?: boolean,
    /**
     * Optional custom component used to render each option in the dropdown.
     */
    OptionComponent?: FC
} & StandardProps;